import { AngularFireStorage } from '@angular/fire/storage';
import { UserService } from '../../../services/user.service';
import { Component, OnInit} from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { AuthenticationService } from '../../../services/authentication.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-foto',
  templateUrl: './foto.component.html',
  styleUrls: ['./foto.component.css']
})
export class FotoComponent implements OnInit {
  changeFoto: boolean = false;
  user: any;
  uid_in_userdatabase: any;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  picture: any;
  currentuid: string;
  saveresponse: string;
  mensaje:boolean=false;
  popDelete:boolean=false;


  constructor(private userService: UserService,
              private authenticationService: AuthenticationService,
              private firebaseStorage: AngularFireStorage,
              private translate: TranslateService) {

    const Userdata: any = this.userService.getUserinfodata();

    if(Userdata === undefined) {
      this.authenticationService.getStatus().subscribe((status) => {
        this.currentuid = status.uid;
        this.userService.getUserById(status.uid).subscribe((data) => {
          this.user = data[0].payload.doc.data();
          this.uid_in_userdatabase = data[0].payload.doc.id;
          this.picture=data[0].payload.doc.data()["avatar"];
          
        });
      });
    } else {
      this.picture=Userdata.payload.doc.data().avatar;
      this.currentuid = Userdata.payload.doc.data().uid;
      this.user = Userdata.payload.doc.data();
      this.uid_in_userdatabase = Userdata.payload.doc.id;
      
    }

  }

  ngOnInit(): void {

  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.changeFoto = true;
    
  }
  imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
  }
  imageLoaded() {
      // show cropper
  }
  cropperReady() {
      // cropper ready
  }
  loadImageFailed() {
      // show message
  }

  saveavatar(event) {
    event.stopPropagation();
    if(this.croppedImage) {

      if(this.user.avatar) {
        
        this.firebaseStorage.storage.refFromURL(this.user.avatar).delete().then(filedel => { 
          this.changeFoto=false;
          this.mensaje=true;
          this.translate.get('dashboard.perfil.cambiarfoto.response.avatar').subscribe((data) => {
            this.saveresponse = data
          })

          const currenPictureId = Date.now();
          const pictures = this.firebaseStorage.ref('avatars/' + currenPictureId + '.jpg').putString(this.croppedImage, 'data_url');
          pictures.then(() => {
            this.picture = this.firebaseStorage.ref('avatars/' + currenPictureId + '.jpg').getDownloadURL();
            this.picture.subscribe((p) => {
              this.userService.editUser({avatar: p }, this.uid_in_userdatabase).then(upav => {
                
                this.mensaje=true;
                
                setTimeout(() => {this.saveresponse = '';this.imageChangedEvent=null;this.mensaje=false}, 2000);
               
              });
            });
          }).catch((error) => {
          });

        });
      } else {
        this.changeFoto=false;
        this.mensaje=true;

        this.translate.get('dashboard.perfil.cambiarfoto.response.avatar').subscribe((data) => {
          this.saveresponse = data
        })

        const currenPictureId = Date.now();
        const pictures = this.firebaseStorage.ref('avatars/' + currenPictureId + '.jpg').putString(this.croppedImage, 'data_url');
        pictures.then(() => {
          this.picture = this.firebaseStorage.ref('avatars/' + currenPictureId + '.jpg').getDownloadURL();
          this.picture.subscribe((p) => {
            this.userService.editUser({avatar: p }, this.uid_in_userdatabase).then(upav => {
              
              this.mensaje=true;
             
             
              setTimeout(() => {this.saveresponse = ''; this.imageChangedEvent=null;this.mensaje=false}, 2000);
              
             
            });
          });
        }).catch((error) => {
          console.log(error);
        });

      }

    }
  }

  deleteAvatar(event){
    if(this.user.avatar){
      this.firebaseStorage.storage.refFromURL(this.user.avatar).delete().then(
        () =>{
          this.translate.get('dashboard.perfil.cambiarfoto.response.borrada').subscribe((data) => {
            this.saveresponse = data
          })
   
        this.user.avatar="";
        this.popDelete=false;
        this.userService.editUser(this.user, this.uid_in_userdatabase);
      }).catch((error) => {
        console.log(error);
      });;
    }
  }
  changedelete(){
    this.popDelete==false?this.popDelete=true:this.popDelete=false;
  }
  
  
}
