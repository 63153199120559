import { NotificacionesService } from './../../services/notificaciones.service';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-notificaciones',
  templateUrl: './notificaciones.component.html',
  styleUrls: ['./notificaciones.component.css'],
})
export class NotificacionesComponent implements OnInit {
  // lasnotificaciones: any;
  listaMensajes: any[];
  notificacionesMostrar: any[];
  // itemsperpage = 10;
  // totalpages;
  // p = 1;

  constructor(
    private notificacionesService: NotificacionesService,
    private userService: UserService,
    private authenticationService: AuthenticationService
  ) {
    const Userdata: any = this.userService.getUserinfodata();

    if (Userdata === undefined) {
      this.authenticationService.isAuth().subscribe((auth) => {
        if (auth) {
          this.notificacionesService
            .getNotificaciones(auth.uid)
            .subscribe((notificaciones) => {
              // this.lasnotificaciones = notificaciones;
              // this.totalpages = Math.round(this.lasnotificaciones.length / this.itemsperpage);
              // if(this.totalpages === 0) {
              //   this.totalpages = 1;
              // }

              this.listaMensajes = [];
      
              this.notificacionesMostrar = [];
              notificaciones.forEach((notificacion) => {
                var noti: any = notificacion.payload.doc.data();
                if (noti.mensaje) {
                  let encontrado = false;
                  for (let i = 0; i < this.listaMensajes.length; i++) {
                    if (this.listaMensajes[i].emisor == noti.emisor) {
                      this.listaMensajes[i].mensajes.push(notificacion);
                      encontrado = true;
                      break;
                    }
                  }
                  if (!encontrado) {
                    let grupomensaje = {
                      emisor: noti.emisor,
                      mensajes: [notificacion],
                    };
                    let grupomensajetest = {
                      emisor: noti.emisor,
                      mensajes: [noti],
                    };
                    this.listaMensajes.push(grupomensaje);
          
                  }
                } else {
                  let notim = { notificacion: notificacion, numero: 1 };
                  this.notificacionesMostrar.push(notim);
                }
              });

         
                this.mostrar();
         
            });
        }
      });
    } else {
      this.notificacionesService
        .getNotificaciones(Userdata.payload.doc.data().uid)
        .subscribe((notificaciones) => {
          // this.lasnotificaciones = notificaciones;
          this.listaMensajes = [];

          this.notificacionesMostrar = [];
          notificaciones.forEach((notificacion) => {
            var noti: any = notificacion.payload.doc.data();
            if (noti.mensaje) {
              let encontrado = false;
              for (let i = 0; i < this.listaMensajes.length; i++) {
                if (this.listaMensajes[i].emisor == noti.emisor) {
                  this.listaMensajes[i].mensajes.push(notificacion);
        
                  encontrado = true;
                  break;
                }
              }
              if (!encontrado) {
                let grupomensaje = {
                  emisor: noti.emisor,
                  mensajes: [notificacion],
                };
                let grupomensajetest = {
                  emisor: noti.emisor,
                  mensajes: [noti],
                };
                this.listaMensajes.push(grupomensaje);

              }
            } else {
              let notim = { notificacion: notificacion, numero: 1 };
              this.notificacionesMostrar.push(notim);
            }
          });

        
            this.mostrar();


          // this.totalpages = Math.round(this.lasnotificaciones.length / this.itemsperpage);
          // if(this.totalpages === 0) {
          //   this.totalpages = 1;
          // }
        });
    }
  }

  ngOnInit(): void {}

  mostrar() {
   
    this.listaMensajes.forEach((noti) => {
        let notim = {
          notificacion: noti.mensajes[noti.mensajes.length-1],
          numero: noti.mensajes.length,
        };
        this.notificacionesMostrar.push(notim);

    });
  }

  deletenotificacion(uid) {
    this.notificacionesService.removeNotificacion(uid).then((data) => {});
  }

  vernotificacion(uid) {
    const update = {
      verlo: '',
    };
    this.notificacionesService
      .updateNotificacion(uid, update)
      .then((data) => {});
  }

  // nextpage() {
  //   if (this.p < this.totalpages) {
  //     this.p++;
  //   }
  // }

  // prevpage() {
  //   if (this.p > 1) {
  //     this.p--;
  //   }
  // }
}
