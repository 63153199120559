<div class="loginwrap">
  <div class="loginbox forget">
      <header>
          <div class="logo">
              <img src="/assets/img/logocolor.png" alt="">
          </div>
          <div class="title">
              <h1 translate>olvidar.restablecer</h1>
          </div>
      </header>
      <article>

         <p translate>
            olvidar.texto
         </p>


         <div class="loginform">
             <label for="" translate>
                 olvidar.email
             </label>
             <input class="user" type="text" placeholder="{{'olvidar.email'|translate}}" [(ngModel)]="usuario">
             <p class="response">{{response}}</p>

             <div class="submitbutton">

                 <input class="acceso" type="submit" value="{{'olvidar.enviar'|translate}}" (click)="emailRestablecer()">
             </div>

         </div>

      </article>
      <footer>

      </footer>
  </div>
</div>
