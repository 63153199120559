import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-eliminarusuarios',
  templateUrl: './eliminarusuarios.component.html',
  styleUrls: ['./eliminarusuarios.component.css'],
})
export class EliminarusuariosComponent implements OnInit {
  usuarios = [];
  seleccion = [];
  popUp = false;

  constructor(private _userService: UserService) {}

  ngOnInit(): void {
    this.seleccion = new Array();
    this.usuarios = new Array();
    this._userService.getUsersNow().subscribe((users) => {
      users.forEach((user) => {
        this.usuarios.push(user);
      });
      this.usuarios.sort(function (a, b) {
   
        if (a.data().nombres.toLowerCase() > b.data().nombres.toLowerCase()) {
          return 1;
        }
        if (a.data().nombres.toLowerCase() < b.data().nombres.toLowerCase()) {
          return -1;
        }

        if (a.data().apellidos.toLowerCase() > b.data().apellidos.toLowerCase()) {
          return 1;
        }
        if (a.data().apellidos.toLowerCase() < b.data().apellidos.toLowerCase()) {
          return -1;
        }
                
        // a must be equal to b
        return 0;
      });
    });
   
  }
  getUserId(event, uid) {
    if (event.target.checked) {
      //console.log(uid + " checked")
      this.seleccion.push(uid);
    } else {
      //console.log(uid + " UNchecked");
      this.seleccion = this.seleccion.filter((m) => m != uid);
    }
  }
  popBorrar() {
    this.popUp ? (this.popUp = false) : (this.popUp = true);
  }
  borrarUsuarios() {
    this.seleccion.forEach((usuarioId) => {
      this.popUp = false;
      //this._userService.deleteUser(usuarioId);
      //Habria que borrar tambien en el authentication al usuario
    });
  }
}
