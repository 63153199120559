
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';

import { AlertasService } from 'src/app/services/alertas.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-alertabox',
  templateUrl: './alertabox.component.html',
  styleUrls: ['./alertabox.component.css'],
})
export class AlertaboxComponent implements OnDestroy {
  @Input() alertaEntera: any;
  alerta:any;
  nombreAlumno: any;
  imagenAlumno: any;
  @Output() valueResponse: EventEmitter<any> = new EventEmitter();
  @Output() infoUser: EventEmitter<any> = new EventEmitter();

  constructor(private _userService: UserService,
              private _alertService:AlertasService,
    ) {}
  ngOnDestroy(): void {
    this.valueResponse.emit(this.alerta);
  }

  ngOnInit(): void {
    this.alerta = this.alertaEntera.payload.doc.data();
    this.getInfoUser();

  }
  
  getInfoUser() {
    this._userService.getUsers().subscribe((users) => {
      users.forEach((user) => {
        const User = user.payload.doc.data();
        if (User['uid'] == this.alerta.idUsuario) {
          this.nombreAlumno = this.alerta.nombre;
          if (User['avatar']) {
            this.alerta.avatarAlumno = User['avatar'];
          } else {
            this.alerta.avatarAlumno = 'assets/img/defaultavatar.jpg';
          }
        }
      });
    });
  }
  deleteAlert() {
    this.alerta.mostrarSupervisor = false;
    var idOriginal = this.alertaEntera.payload.doc.id;
    this._alertService.editAlerta(idOriginal, this.alerta);
    this.ngOnDestroy();
  }
  showInfoUser() {
    this.infoUser.emit(this.alerta.idUsuario);
  }
}

