import { AfterViewInit, Component, ElementRef, OnInit,   Renderer2,   ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-live',
  templateUrl: './live.component.html',
  styleUrls: ['./live.component.css']
})
export class LiveComponent implements OnInit {
  iframe: string;
  @ViewChild('scriptContainer') scriptContainer: ElementRef;
  idSala: string ="";


  constructor(private activatedRoute: ActivatedRoute,     private renderer: Renderer2,) {

    /* toma el parametro uid que viene en la url */
    this.activatedRoute.params.subscribe(params => {
      /* uid del evento actual */
      // let nurl = params['url'].replaceAll("+", "/");
      this.idSala = params['url'];
      console.log("ID", this.idSala)
      // this.cargarScript()
      // this.iframe = 'https://' + nurl;

    });

   }

   ngAfterViewInit() {
    this.cargarScript();
  }

   cargarScript() {
    try {
      // const divContainer = this.scriptContainer.nativeElement;
      // const newDiv = this.renderer.createElement('div');
      // this.renderer.setAttribute(newDiv, 'id', 'rec-live-' + this.idSala);
      // this.renderer.appendChild(divContainer, newDiv);
      console.log("sala",this.idSala)

      const script = this.renderer.createElement('script');
      this.renderer.setAttribute(
        script,
        'src',
        'https://www.recordingandstreaming.com/live/js/' + this.idSala + '.js'
      );
      this.renderer.appendChild(this.scriptContainer.nativeElement, script);
    } catch (error) {
      console.log('ERRRROR', error);
    }
  }

  ngOnInit(): void {
  }
}