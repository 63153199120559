import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AlertasService } from 'src/app/services/alertas.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-alertabox',
  templateUrl: './alertabox.component.html',
  styleUrls: ['./alertabox.component.css'],
})
export class AlertaboxComponent implements OnDestroy {
  @Input() alerta: any;
  alertaBuena:any;
  nombreAlumno: any;
  imagenAlumno: any;
  avatarColegio: any;
  @Output() valueResponse: EventEmitter<any> = new EventEmitter();

  constructor(
    private _userService: UserService,
    private _alertService: AlertasService
  ) {}

  ngOnInit(): void {
    this.alertaBuena = this.alerta.payload.doc.data();
    this.getInfoUser();
  }
  
  getInfoUser() {
    this._userService.getUsers().subscribe((users) => {
      users.forEach((user) => {
        const User = user.payload.doc.data();

        if (User['uid'] == this.alertaBuena.idUsuario) {
          this.nombreAlumno = this.alertaBuena.nombre;
          if (User['avatar']) {
            this.alertaBuena.avatarAlumno = User['avatar'];
          }
          else{
            this.alertaBuena.avatarAlumno = 'assets/img/defaultavatar.jpg'
          }
        }
      });
    });
  }
  deleteAlert() {
    var idOriginal = this.alerta.payload.doc.id;
    this._alertService.deleteAlert(idOriginal);
    this.ngOnDestroy();
  }

  ngOnDestroy(): void {
    this.valueResponse.emit(this.alertaBuena);
  }
}
