import { AngularFireStorageModule } from '@angular/fire/storage';
import { NgModule, LOCALE_ID} from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { MisalaRoutingModule } from './misala-routing.module';
import { FormsModule } from '@angular/forms';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgbModalModule} from '@ng-bootstrap/ng-bootstrap';


import {NgxPaginationModule} from 'ngx-pagination';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { ReactiveFormsModule } from "@angular/forms";


import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { DatetimePopupModule } from 'ngx-bootstrap-datetime-popup';

import { DispositivosComponent } from './dispositivos/dispositivos.component';
import { LayoutComponent } from './layout/layout.component';

import { PinComponent } from './pin/pin.component';
import { EstadisticasComponent } from './estadisticas/estadisticas.component';
import { EstadisticasBoxComponent } from './estadisticas/estadisticas-box/estadisticas-box.component';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    DispositivosComponent,
    LayoutComponent,
    PinComponent,
    EstadisticasComponent,
    EstadisticasBoxComponent
    
    ],
  imports: [
    TimepickerModule,
    DatetimePopupModule,
    CommonModule,
    MisalaRoutingModule,
    FormsModule,
    ImageCropperModule,
    AngularFireStorageModule,
    NgbModalModule,
    NgxPaginationModule,
    NgxMaterialTimepickerModule,
    ReactiveFormsModule,
    TranslateModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-ES' }, DatePipe
  ]
})
export class MisalaModule { }
