<div>
  <ng-container *ngIf="clase">
    <section class="basicbox clase">
      <div class="claseheader">
        <!-- clase box -->
        <div class="subbasicbox chat headinguser">
          <div class="boximage">
            <img
              *ngIf="clase.imagen; else defaultclassavatar"
              src="{{ clase.imagen }}"
              alt="{{ clase.nombre | titlecase }}"
            />
            <ng-template #defaultclassavatar
              ><img
                src="assets/img/clase_default.jpg"
                alt="{{ clase.nombre | titlecase }}"
            /></ng-template>
          </div>
          <div class="boxtitles">
            <h4>{{ clase.nombre | titlecase }}</h4>
            <p class="admins">{{ clase.descripcion }}</p>
            <p class="profes" translate>dashboard.clase.profesores</p>
          </div>
          <div class="rigthbuttons">
            <a routerLink="/dashboard/archivos/{{ claseuid }}">
              {{ "dashboard.clase.verArchivos" | translate }}
              <span>
                <img src="/assets/img/folder.png" alt="" />
              </span>
            </a>
            <ng-container
              *ngIf="
                clase.administradores.includes(userdata.uid) ||
                userdata.rol == 'director'
              "
            >
              <a routerLink="/dashboard/editarclase/{{ claseuid }}">
                {{ "dashboard.clase.editar" | translate }}
              </a>
            </ng-container>
          </div>

          <div class="boxdata">
            <div class="adminsgrid" *ngIf="this.administradores.length > 0">
              <div>
                <ul>
                  <h4 id="rol" translate>dashboard.clase.titular</h4>
                  <li>
                    <a
                      routerLink="/dashboard/profile/{{
                        administradores[0].uid
                      }}"
                    >
                      <img
                        *ngIf="
                          administradores[0].avatar;
                          else defaultheadavatar
                        "
                        src="{{ administradores[0].avatar }}"
                        alt="{{administradores[0].nombres | titlecase }}"
                      />
                      <ng-template #defaultheadavatar
                        ><img src="assets/img/defaultavatar.jpg" alt=""
                      /></ng-template>
                    </a>
                    <p>{{ administradores[0].nombres | titlecase }}</p>
                  </li>

                  <h4 id="rol2" translate>dashboard.clase.otros</h4>
                  <div class="suplente">
                    <ng-container
                      *ngFor="
                        let admin of this.administradores | slice : 0 : 6;
                        let i = index
                      "
                    >
                      <li *ngIf="i != 0">
                        <a routerLink="/dashboard/profile/{{ admin.uid }}">
                          <img
                            *ngIf="admin.avatar; else defaultheadavatar"
                            src="{{ admin.avatar }}"
                            alt="{{admin.nombres | titlecase }}"
                          />
                          <ng-template #defaultheadavatar
                            ><img src="assets/img/defaultavatar.jpg" alt=""
                          /></ng-template>
                        </a>
                        <p>{{ admin.nombres | titlecase }}</p>
                      </li>
                    </ng-container>
                  </div>
                </ul>
              </div>
              <div></div>
            </div>
          </div>
        </div>
        <!--/clase box -->
      </div>

      <div *ngIf="!ocultarClase">
        <section class="basicbox chatusercol padtop">
          <header>
            <div class="icon">
              <img src="/assets/img/grupos-b.svg" alt="" />
            </div>
            <h3 translate>dashboard.clase.miembros</h3>
          </header>

          <!--  Meeting Your Future -->
          <article>
            <div class="search">
              <img src="/assets/img/search.svg" alt="" />
              <input
                type="text"
                placeholder="{{ 'dashboard.clase.buscarusuarios' | translate }}"
                [(ngModel)]="searchValue"
              />
            </div>

            <div
              class="basicbox users results"
              *ngIf="!ocultarClase"
            >
              <app-userbox-clase
                *ngFor="
                  let alumno of this.estudiantes | search : searchValue;
                  trackBy: trackByArtNo
                "
                [mainuid]="currentuserid"
                [alumno]="alumno"
                [nombres]="nombres"
                [apellidos]="apellidos"
              ></app-userbox-clase>
            </div>
          </article>
        </section>
      </div>
      <div *ngIf="!ocultarClase" class="userchat">
        <section class="basicbox padtop">
          <header>
            <div class="icon">
              <img src="/assets/img/chatbox-b.svg" alt="" />
            </div>
            <h3 translate>dashboard.clase.chat</h3>
          </header>
          <!--  Meeting Your Future -->
          <section
            class="basicbox chatmessages"
            *ngIf="!ocultarClase"
          >
            <!--<div class="messages" id="themessagesbox" [scrollTop]="scrolltobottom()">

                <ng-container *ngFor="let messages of this.chatmessages; trackBy: trackFbObjects;">


                  <div class="message">
                      <div class="avatar">
                          <img *ngIf="messages.usuariodata.avatar else defaultheadavatar" src="{{messages.usuariodata.avatar}}" alt="{alumno.nombres}}">
                          <ng-template #defaultheadavatar><img src="assets/img/defaultavatar.jpg" alt=""></ng-template>
                          <div class="status"></div>
                      </div>
                      <div class="messagedata">
                          <div class="name">
                            {{messages.usuariodata.nombres}} {{messages.usuariodata.apellidos}}
                          </div>
                          <div class="date">
                            {{ messages.fecha | date: 'MM/dd/y - HH:mm' }}h
                          </div>
                          <div class="themessage">
                              <p>{{messages.message}}</p>
                          </div>
                      </div>
                  </div>

                </ng-container>

              </div>-->

            <div
              class="messages"
              id="themessagesbox"
              [scrollTop]="scrolltobottom()"
            >
              <ng-container *ngIf="this.chatorimessa[0]">
                <ng-container
                  *ngIf="
                    chatusers[this.chatorimessa[0].payload.doc.data().sender]
                  "
                >
                  <ng-container *ngFor="let messages of this.chatorimessa">
                    <div class="message">
                      <div class="avatar">
                        <ng-container
                          *ngIf="
                            chatusers[messages.payload.doc.data().sender] !=
                            undefined
                          "
                        >
                          <img
                            *ngIf="
                              chatusers[messages.payload.doc.data().sender]
                                .avatar;
                              else defaultheadavatar
                            "
                            src="{{
                              chatusers[messages.payload.doc.data().sender]
                                .avatar
                            }}"
                            alt="{{chatusers[messages.payload.doc.data().sender].nombres | titlecase }}"
                          />
                          <ng-template ng-template #defaultheadavatar
                            ><img src="assets/img/defaultavatar.jpg"
                          /></ng-template>
                          <!--<div class="status"></div>-->
                        </ng-container>
                      </div>
                      <div class="messagedata">
                        <div class="name">
                          <ng-container
                            *ngIf="
                              chatusers[messages.payload.doc.data().sender] !=
                              undefined
                            "
                          >
                            {{
                              chatusers[messages.payload.doc.data().sender]
                                .nombres | titlecase
                            }}
                            {{
                              chatusers[messages.payload.doc.data().sender]
                                .apellidos | titlecase
                            }}
                          </ng-container>
                        </div>
                        <div class="date">
                          {{
                            messages.payload.doc.data().fecha.toDate()
                              | date : "dd/MM/y - HH:mm"
                          }}h
                        </div>
                        <div class="themessage">
                          <p>{{ messages.payload.doc.data().message }}</p>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>

            <div class="sendbox">
              <input
                class="messagebox"
                type="text"
                placeholder ="{{'dashboard.grupos.escribirmensaje' | translate}}"
                [(ngModel)]="messagechatclase"
                (keyup.enter)="sendmessageclase()"
              />
              <input
                class="sendmessage"
                type="submit"
                value="{{'dashboard.grupos.enviarmensaje' | translate }}"
                (click)="sendmessageclase()"
              />
            </div>
          </section>
        </section>
      </div>
    </section>
  </ng-container>
</div>
