import { Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import { UserService } from '../../../services/user.service';
import { ChatService } from '../../../services/chat.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-userbox-clase',
  templateUrl: './userbox-clase.component.html',
  styleUrls: ['./userbox-clase.component.css']
})
export class UserboxClaseComponent implements OnChanges {
  @Input() mainuid;
  @Input() alumno;
  @Input() nombres;
  @Input() apellidos;

  presencia: any;
  tiempoDesconexion: any;


  constructor(private userService: UserService,
              private chatService: ChatService,
              private translate : TranslateService) { }

  ngOnChanges(changes: SimpleChanges) {
    const mainuid = changes.mainuid.currentValue;
    const alumno = changes.alumno.currentValue;
    this.getuserpresence(alumno.uid);
  }

  get currentuid() {
    return this.mainuid;
  }

  get alumnodata() {
    return this.alumno;
  }

  get tnombres() {
    return this.nombres;
  }

  get tapellidos() {
    return this.apellidos;
  }


  getuserpresence(uid) {
    if (uid) {
      this.userService.getPresence(uid).subscribe(presencia => {
        if (presencia != null) {
          this.presencia = presencia;
          this.tiempoDesconexion = this.timeDifference(Math.floor(new Date().getTime() / 1000.0), this.presencia.timestamp );
          this.presencia = this.presencia.status;
        }
      });
    }
  }

  timeDifference(date1,date2) {
    if(date1 > Math.floor(date2/1000.0)) {
      var difference =  date1 - Math.floor(date2/1000.0);
    } else  {
      var difference =  Math.floor(date2/1000.0) - date1;
    }
    var seconds = Number(difference);
    var d = Math.floor(seconds / (3600*24));
    var h = Math.floor(seconds % (3600*24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);
    let len = this.translate.getDefaultLang();
    var dDisplay = "";
    var hDisplay = "";
    var mDisplay = "";
    var sDisplay = "";

    switch (len) {
      case "en":
        dDisplay = d > 0 ? d + (d == 1 ? " day " : " days ") : "";
        hDisplay = h > 0 ? h + (h == 1 ? " hour " : " hours ") : "";
        mDisplay = m > 0 ? m + (m == 1 ? " minute " : " minutes ") : "";
        sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
        break;
      case "es":
          var dDisplay = d > 0 ? d + (d == 1 ? " día " : " días ") : "";
          var hDisplay = h > 0 ? h + (h == 1 ? " hora " : " horas ") : "";
          var mDisplay = m > 0 ? m + (m == 1 ? " minuto " : " minutos ") : "";
          var sDisplay = s > 0 ? s + (s == 1 ? " segundo" : " segundos") : "";
        break;
        case "ar":
          var dDisplay = d > 0 ? d + (d == 1 ? "  يوم " : " أيام ") : "";
          var hDisplay = h > 0 ? h + (h == 1 ? " ساعة " : "  ساعات ") : "";
          var mDisplay = m > 0 ? m + (m == 1 ? " دقيقة " : " دقائق ") : "";
          var sDisplay = s > 0 ? s + (s == 1 ? "  ثانية" : " ثواني") : "";
        break;
      default:
        break;
    }
  

    // var dDisplay = d > 0 ? d + (d == 1 ? " día " : " días ") : "";
    // var hDisplay = h > 0 ? h + (h == 1 ? " hora " : " horas ") : "";
    // var mDisplay = m > 0 ? m + (m == 1 ? " minuto " : " minutos ") : "";
    // var sDisplay = s > 0 ? s + (s == 1 ? " segundo" : " segundos") : "";

    var content = '';

    if (s > 0) {
      content = sDisplay;
    }
    if (m > 0) {
      content = mDisplay;
    }
    if (h > 0) {
       content = hDisplay;
    }
    if (d > 0) {
      content = dDisplay;
    }

    return content;
  }

}
