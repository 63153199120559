import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ColegiosService {

  constructor(private firestore: AngularFirestore) { }

  getColegios() {
    return this.firestore.collection('colegios').snapshotChanges();
  }
  getColegiosNow() {
    return this.firestore.collection('colegios').get();
  }

  getColegioById(uid) {
    return this.firestore.collection('colegios').doc(uid).snapshotChanges();
  }

  getColegioByIdNow(uid) {
    return this.firestore.collection('colegios').doc(uid).get();
  }
  getColegioByCursoId(uid) {
    return this.firestore.collection('colegios', ref => ref.where('cursos', 'array-contains' , uid)).get();
  }

  createdColegio(colegio) {
    return this.firestore.collection('colegios').add(colegio);
  }

  editColegio(colegio, id) {
    return this.firestore.collection('colegios').doc(id).set(colegio);
  }
  deleteColegio(uid){
    return this.firestore.collection('colegios').doc(uid).delete();
  }

}
