import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-passwordreset',
  templateUrl: './passwordreset.component.html',
  styleUrls: ['./passwordreset.component.css']
})
export class PasswordresetComponent implements OnInit {
  mode: any;
  actionCode: any;
  continueUrl: any;
  lang: any;
  newPassword: string;
  vars: any;
  confirmed = null;
  changed = null;
  response: string;
  newEmail: string;

  constructor(private auth: AngularFireAuth,
              private activatedRoute: ActivatedRoute) {
                this.vars = this.activatedRoute.queryParams.subscribe(params => {
                  this.mode = params['mode'];
                  this.actionCode = params['oobCode'];
                  this.lang = params['lang'];
                  console.log(this.mode); // Print the parameter to the console.
                  console.log(this.actionCode);
                  console.log(this.lang);
              });
                /*console.log(this.vars);
                console.log(this.actionCode);
                console.log(this.lang);*/


                // Handle the user management action.
                switch (this.mode) {
                  case 'resetPassword':
                    // Display reset password handler and UI.
                    this.handleResetPassword(this.actionCode);
                    break;
                  case 'recoverEmail':
                    // Display email recovery handler and UI.
                    this.handleRecoverEmail(this.actionCode);
                    break;
                  case 'verifyEmail':
                    // Display email verification handler and UI.
                    this.handleVerifyEmail(this.actionCode);
                    break;
                  default:
                    // Error: invalid mode.
                }
              }

  ngOnInit(): void {
  }

  handleResetPassword(actionCode) {
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    let accountEmail;
    // Verify the password reset code is valid.
    this.auth.verifyPasswordResetCode(actionCode).then((email) => {
      let accountEmail = email;
      console.log(accountEmail);
      // TODO: Show the reset screen with the user's email and ask the user for
      // the new password.

      if(accountEmail) {
        this.confirmed = true;
      }

    }).catch((error) => {
      console.log(error);
      this.confirmed = false;
      this.changed = false;
      this.response = error.message;
      // Invalid or expired action code. Ask user to try to reset the password
      // again.
    });
  }

  changepass() {
    // Save the new password.
    this.auth.confirmPasswordReset(this.actionCode, this.newPassword).then((resp) => {
      // Password reset has been confirmed and new password updated.
      console.log(resp);
      this.changed = true;
      this.confirmed = false;
      // TODO: Display a link back to the app, or sign-in the user directly
      // if the page belongs to the same domain as the app:
      // auth.signInWithEmailAndPassword(accountEmail, newPassword);

      // TODO: If a continue URL is available, display a button which on
      // click redirects the user back to the app via continueUrl with
      // additional state determined from that URL's parameters.
    }).catch((error) => {
      console.log(error);
      this.changed = false;
      this.response = error.message;
      // Error occurred during confirmation. The code might have expired or the
      // password is too weak.
    });
  }


  handleRecoverEmail(actionCode) {
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    let restoredEmail = null;
    // Confirm the action code is valid.
    this.auth.checkActionCode(actionCode).then((info) => {
      // Get the restored email address.
      console.log(info);
      restoredEmail = info['data']['email'];


      // Revert to the old email.
      return this.auth.applyActionCode(actionCode);
      this.confirmed = true;
    }).then(() => {

      // Account email reverted to restoredEmail

      // TODO: Display a confirmation message to the user.

      // You might also want to give the user the option to reset their password
      // in case the account was compromised:
      this.confirmed = true;

    }).catch((error) => {
      this.changed = false;
      console.log(error);
      // Invalid code.
    })

  }


  handleVerifyEmail(actionCode) {
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    // Try to apply the email verification code.
    this.auth.applyActionCode(actionCode).then((resp) => {
      // Email address has been verified.
      console.log(resp);
      this.changed = true;
      // TODO: Display a confirmation message to the user.
      // You could also provide the user with a link back to the app.

      // TODO: If a continue URL is available, display a button which on
      // click redirects the user back to the app via continueUrl with
      // additional state determined from that URL's parameters.
    }).catch((error) => {
      console.log(error);
      this.changed = false;
      this.response = error.message;
      // Code is invalid or expired. Ask the user to verify their email address
      // again.
    });
  }

}
