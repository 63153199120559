import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ChatService {

	constructor(private firestore: AngularFirestore) {}

	getChats() {
		return this.firestore.collection('chats').valueChanges();
	}
	getChatsNow() {
		return this.firestore.collection('chats').get();
	}

	getMessages(chatid) {
		return this.firestore.collection('chats').doc(chatid).collection('messages').snapshotChanges([ 'added' ]);
	}
	getMessagesNow(chatid) {
		return this.firestore.collection('chats').doc(chatid).collection('messages').get();
	}
}
