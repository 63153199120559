<div class="admin">
  <section class="basicbox ">
    <header>
      <h3>Mis mensajes</h3>
      <div  class="icon" routerLink="/admin/mensajes/">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
            <g
              id="Grupo_9488"
              data-name="Grupo 9488"
              transform="translate(-2 -2)"
            >
              <path
                id="Trazado_9284"
                data-name="Trazado 9284"
                d="M2.831,41.87a1.431,1.431,0,0,0,1.527-.214l6.79-5.82a10.012,10.012,0,0,1,6.507-2.407h18.63A5.72,5.72,0,0,0,42,27.714v-20A5.72,5.72,0,0,0,36.286,2H7.714A5.72,5.72,0,0,0,2,7.714V40.571A1.432,1.432,0,0,0,2.831,41.87ZM4.857,7.714A2.86,2.86,0,0,1,7.714,4.857H36.286a2.86,2.86,0,0,1,2.857,2.857v20a2.86,2.86,0,0,1-2.857,2.857H17.656a12.867,12.867,0,0,0-8.367,3.1l-4.431,3.8Z"
                transform="translate(0 0)"
                fill="#23273b"
              />
              <rect
                id="Rectángulo_3102"
                data-name="Rectángulo 3102"
                width="14"
                height="4"
                transform="translate(15 16)"
                fill="#23273b"
              />
            </g>
          </svg>
        </div>
      </div>
    </header>

    <footer>
      <a id="verMsg" routerLink="/admin/mensajes/" class="button medium center">
        Ver todos
      </a>
    </footer>
    <article class="users">
      <div id="mensajesList">
        <app-userbox
          id="mensajeslist"
          *ngFor="let chat of chats"
          [mainuid]="userUid"
          [members]="chat.members"
          [chatusers]="chatusers"
          [grupodechats]="grupodechats"
          [nombre]="nombres"
          [apellidos]="apellidos"
        ></app-userbox>
      </div>
    </article>

    
  </section>
  <section class="basicbox horojo wplus medium fullheight misalertas">
    <header>
      <h3>Mis alertas</h3>
      <div id="alertB" class="icon">
        <a
        title="Alertas"
        routerLink="/admin/alertas"
        (click)="
        UpdateBreadcrumb(
          'dashboard.home.video',
          '/admin/appsint/in2.thecampus.education'
          )
          "
          >
          <div>
               <img src="/assets/img/alertIcon.svg" alt="">
        </div>
      </a>
    </div>
  </header>
  
  <article class="users2">
   
    <!-- <app-alertas id="alertas"></app-alertas> -->
    <footer>
      <a id=verTodas routerLink="/admin/alertas/" class="button medium center">
        Ver todos
      </a>
    </footer>
  </article>

  </section>
  
</div>
