<div id="data">
  
  <img (click) = "deleteAlert()" id="close" src="assets/img/close.svg"/>
  <img (click) = "showInfoUser()" id="avatar" src="{{alerta.avatarAlumno}}"/>
  <h3 (click) = "showInfoUser()">{{ nombreAlumno | titlecase }}</h3>
  <h3>{{alerta.fechaAlerta.toDate() | date:"short"}}</h3>
  <h3>{{ alerta.idAlerta }}</h3>
  <a class="state" *ngIf="alerta.estado == 'aceptada'">
    <button class="aceptada" routerLink="/dashboard/alertas/alerta/{{alerta.idAlerta}}">{{"dashboard.alertas.aceptada"|translate}}</button>
    <!-- <img src="assets/img/aceptado.svg" routerLink="/dashboard/alertas/alerta/{{alerta.idAlerta}}"/> -->
  </a>
  <a class="state" *ngIf="alerta.estado == 'rechazada'">
    <button class="rechazada" routerLink="/dashboard/alertas/alerta/{{alerta.idAlerta}}">{{"dashboard.alertas.rechazada"|translate}}</button>
    <!-- <img src="assets/img/denegado.svg" routerLink="/dashboard/alertas/alerta/{{alerta.idAlerta}}"/> -->
  </a>
  <a class="state" *ngIf="alerta.estado == 'pendiente'">
    <button class="pendiente" routerLink="/dashboard/alertas/alerta/{{alerta.idAlerta}}">{{"dashboard.alertas.gestionar"|translate}}</button>
   

    <!-- <img src="assets/img/gestionar.svg"routerLink="/dashboard/alertas/alerta/{{alerta.idAlerta}}"/> -->
  </a>
</div>
<hr>
  