import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserService } from 'src/app/services/user.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-eliminarusuarios',
  templateUrl: './eliminarusuarios.component.html',
  styleUrls: ['./eliminarusuarios.component.css'],
})
export class EliminarusuariosComponent implements OnInit {
  usuarios = [];
  seleccion = [];
  popUp = false;
  userUid: string;
  userdata: any;
  rol: any = '';
  mensaje: string = '----------';

  constructor(
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private translate: TranslateService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.seleccion = new Array();
    this.usuarios = new Array();
    this.translate
      .get('dashboard.eliminar.esperemensaje')
      .subscribe((data2) => {
        this.mensaje = data2;
      });

    const Userdata: any = this.userService.getUserinfodata();

    if (Userdata === undefined) {
      var obs = this.authenticationService.isAuth().subscribe((auth) => {
        if (auth) {
          this.userUid = auth.uid; /* id del usuario */

          var obs2 = this.authenticationService
            .isUserAdmin(this.userUid)
            .subscribe((userdata) => {
              if (userdata[0]) {
                this.userdata = userdata[0];
                this.rol = this.userdata.rol;
                this.buscarUsuarios();
              }
            });
        }
      });
    } else {
      this.userUid = Userdata.payload.doc.data().uid; /* id del usuario */
      this.userdata = Userdata.payload.doc.data();
      this.rol = this.userdata.rol;
      this.buscarUsuarios();
    }
    // setTimeout(() => {

    //   obs.unsubscribe()
    // }, 2000);
  }

  buscarUsuarios() {
    var obs = this.userService
      .getUsersbyColegio(this.userdata.colegios[0])
      .subscribe((usuarios) => {
        this.translate
          .get('dashboard.eliminar.esperemensaje')
          .subscribe((data2) => {
            this.mensaje = data2;
          });

        var todosUsuarios = [];
        todosUsuarios = usuarios;
        this.usuarios = [];

        this.insertarUsuarios(todosUsuarios).then(() => {
          this.ordenar().then(() => {
            this.mensaje = '';
          });
        });
      });
  }

  async ordenar() {
    await this.usuarios.sort(function (a, b) {
      if (a.payload.doc.data().codigo > b.payload.doc.data().codigo) {
        return 1;
      }
      if (a.payload.doc.data().codigo < b.payload.doc.data().codigo) {
        return -1;
      }

      // if (a.payload.doc.data().apellidos.toLowerCase() > b.payload.doc.data().apellidos.toLowerCase()) {
      //   return 1;
      // }
      // if (a.payload.doc.data().apellidos.toLowerCase() < b.payload.doc.data().apellidos.toLowerCase()) {
      //   return -1;
      // }

      // a must be equal to b
      return 0;
    });
  }

  async insertarUsuarios(usuarios) {
    await usuarios.forEach((usuario) => {
      this.usuarios.push(usuario);
    });
  }

  getUser(event, usuario) {
    if (event.target.checked) {
      //console.log(uid + " checked")
      this.seleccion.push(usuario);
    } else {
      //console.log(uid + " UNchecked");
      this.seleccion = this.seleccion.filter(
        (m) => m.payload.doc.data().uid != usuario.payload.doc.data().uid
      );
    }
  }
  popBorrar() {
    this.popUp ? (this.popUp = false) : (this.popUp = true);
  }
  borrarUsuarios() {
    var contador = 0;
    var total = this.seleccion.length;
    this.seleccion.forEach((usuario) => {
      this.popUp = false;
      this.eliminarUsuario(usuario).then(() => {
        contador++;
        if (contador == total) {
          this.seleccion = [];
        }
      });
    });
  }

  async eliminarUsuario(usuario) {
    return await this.http
      .get<any>(
        'https://us-central1-the-campus-prod.cloudfunctions.net/delUser',
        { params: new HttpParams().set('uid', usuario.payload.doc.data().uid) }
      )
      .subscribe((element) => {
        console.log('me devuelven esto', element);
        // var tamano = element.size;
        // console.log(tamano)
        // for (let index = 0; index < tamano; index++) {
        //   const element2 = element[index];
        //   console.log(element2)

        // }

        // console.log("me devuelven esto", element)

        //this._userService.deleteUser(usuarioId);
        //Habria que borrar tambien en el authentication al usuario
      });
  }
}
