<div class="ai">
  <section class="basicbox martop">
    <div>
      <div class="formgroup" style="float:right;width:calc(20% - 20px);padding:10px;display:inline-block;">
        <button class="button" (click)="checkBullyingAll()">Comprobar</button>
      </div>
    </div>

    
   <div class="table">
      <div class="theader">
        <div class="table_header">Frase</div>
        <div class="table_header" style="width:10%">Bullying</div>
      </div>
      <ng-container *ngFor="let message of this.messages; let i = index">
        <div class="table_row">
          <div class="table_small">
            <div class="table_cell">Frase</div>
            <div class="table_cell">{{ message.msg }}</div>
          </div>
          <div class="table_small">
            <div class="table_cell">Bullying</div>
            <div class="table_cell">
              <span *ngIf="message.result == 'bullying.yes'">Sí</span>
              <span *ngIf="message.result == 'bullying.no'">No</span>
              &nbsp;
              <span *ngIf="message.result == 'bullying.yes' || message.result == 'bullying.no'">- ({{ message.score }}%)</span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

  </section>
</div>
