<div class="loginwrap">
    <div class="idiomasC">
      <div class="idiomasCF">
        <h2 translate (click)="activeLanguage()" (click)="changeColor()">
          login.cambiaridioma
        </h2>
        <div id="circle" routerLink="/home/meet">
          <img
            id="imgB"
            (click)="activeLanguage()"
            src="/assets/img/language.svg"
            alt="meet"
          />
        </div>
      </div>
      <div *ngIf="idioma == true">
        <div class="idiomas">
          <div *ngIf="es == true">
              <div class="selected">
                <h2>Español (ES)</h2>
                <hr />
              </div>
              <div>
                <h2 (click)="ingles()" [ngClass]="{ grey: click }">
                  English (EN)
                </h2>
              </div>
             
            </div>
            <div *ngIf="en == true">
              <div>
                <h2 (click)="espanol()" [ngClass]="{ grey: click }">
                  Español (ES)
                </h2>
              </div>
              <div class="selected">
                <h2>English (EN)</h2>
                <hr />
              </div>
              
            </div>
            <div *ngIf="ar == true">
              <div>
                <h2 (click)="espanol()" [ngClass]="{ grey: click }">
                  Español (ES)
                </h2>
              </div>
              <div>
                <h2 (click)="ingles()" [ngClass]="{ grey: click }">
                  English (EN)
                </h2>
              </div>
              <div class="selected">
                <h2>عربي</h2>
                <hr />
              </div>
            </div>
        </div>
      </div>
    </div>
    <div class="loginbox">
      <header>
        <div class="logo">
          <img id="colegia" src="assets/img/colegia.png" alt="" />
          <img src="assets/img/logocolor.png" alt="" />
        </div>
        <div class="title">
          <h1 translate>login.titulo</h1>
        </div>
      </header>
 
        <!-- <div>
          <button (click)="loginInSAMLcharterschoolit()" > Login SAML </button>
        </div> -->
        <a (click)="loginInSAMLcharterschoolit()"  class="saml">
          {{ 'login.acceso' | translate }}
        </a>

        <footer>
          <a routerLink="/necesito-ayuda" class="help">
            {{ "login.help" | translate }}
          </a>
        </footer>
    </div>
  </div>
  
  <div class="sm-video">
    <video
      id="video_background"
      loop
      muted
      autoplay
      oncanplay="this.play()"
      onloadedmetadata="this.muted = true"
    >
      <source src="assets/video/thecampus2.mp4" type="video/mp4" />
    </video>
  </div>
  