import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class AlertasService {

  constructor(private firestore: AngularFirestore) {}

  getAlerts() {
    return this.firestore.collection('alertas').snapshotChanges();
  }
  
  getAlertaById(idAlerta) {
    return this.firestore.collection('alertas').doc(idAlerta).snapshotChanges();
  }
  getSupervisorAlert(uid) {
    return this.firestore.collection('alertas', ref => ref.where('idSupervisor', '==' , uid)).snapshotChanges();
  }

  createdAlerta(alerta) {
    return this.firestore.collection('alertas').add(alerta);
  }

  editAlerta(idAlerta, alerta) {
    return this.firestore.collection('alertas').doc(idAlerta).update(alerta);
  }

  getAlertaByIdSupervisor(idSupervisor) {
    return this.firestore.collection('alertas', ref => ref.where('idSupervisor', '==' , idSupervisor)).snapshotChanges();
  }

  deleteAlert(idAlerta){
    return this.firestore.collection('alertas').doc(idAlerta).delete();
  }
  getAlertaByMensajeAndName(mensaje, idUsuario,fecha) {
    return this.firestore.collection('alertas', ref => ref.where('mensaje', '==' , mensaje).where('idUsuario', '==' , idUsuario).where('fechaAlerta','==',fecha)).get();
  }

}
