<!-- <iframe [src]="iframe|safe: 'resourceUrl'" frameborder="0"></iframe> -->

<div class="contenedor">


    <div #scriptContainer class="video">
        <!-- <div id="web-player-0"></div> -->
        <div [(id)]="'rec-live-' + idSala"></div>
      </div>
      

</div>
