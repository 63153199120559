<div class="reset">
  <div id="title">
    <img src="/assets/img/power-cycle_1.svg" />
    <h1>Resetear clases</h1>
  </div>
  <div class="basicbox estu">
    <div>
      <div class="resetTotal">
        <div *ngIf="popEliminarClases == 'home'">
          <h1>Resetear todas las clases</h1>
          <button class="button" (click)="cambiarEstado('borrarTodas')">Resetear</button>
        </div>
      </div>
  
      <div *ngIf="popEliminarClases == 'home'">
        <h1>Resetear las clases de un colegio</h1>
  
        <div class="formgroup">
          <select id="idcurso" class="form-control"  [(ngModel)]="colegio">
            <ng-container *ngFor="let elcole of colegios">
              <option value="{{ elcole.payload.doc.id }}">
                {{ elcole.payload.doc.data().nombre }}
              </option>
            </ng-container>
          </select>
        </div>
  
        <button class="button" (click)="cambiarEstado('borrarColegio')">Resetear</button>
      </div>
    </div>
    <div *ngIf="popEliminarClases == 'borrarTodas'" class="confirm">
      <h1>Este es el pop up de resetear todas las clases</h1>
      <div class="buttons">
         <button class="button" (click)="cambiarEstado('home')">Cancelar</button>
      <button class="button" (click)="resetearClase()">Aceptar</button>
      </div>
    </div>
    <div *ngIf="popEliminarClases == 'borrarColegio'" class="confirm">
      <h1>Este es el pop up de resetear las clases de este colegio</h1>
      <div class="buttons">
        <button class="button" (click)="cambiarEstado('home')">Cancelar</button>
      <button class="button" (click)="resetearClaseColegio(colegio)">Aceptar</button>
      </div>
    </div>
  </div>

</div>
