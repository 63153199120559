<!-- <ul>
  <li *ngFor="let colegio of colegios">
    {{ colegio.data().nombre | titlecase }}
  </li>
</ul> -->

<section class="basicbox fondo evento">
  <header class="headerperfil padtop">
    <div class="icon2">
      <div class="wrapicon">
        <img src="/assets/img/graduation.png" />
      </div>
    </div>
    <h3>{{'dashboard.cursos.crear'|translate}}</h3>
  </header>

  <div>
    <div class="formgroup">
      <label for="idnomb">{{'dashboard.cursos.crearcursos.nombre'|translate}} <span class="mensaje">{{mensajeNombre}}</span> </label>
      
      <input
        id="idnomb"
        class="form-control"
        type="text"
        placeholder="{{'dashboard.cursos.crearcursos.escribir'|translate}}"
        [(ngModel)]="nombre"
      />
    </div>
 
    <div class="formgroup">
      <label for="idcolegio">{{'dashboard.cursos.crearcursos.colegio'|translate}}</label>
      <select
        id="idcolegio"
        class="form-control"
        [(ngModel)]="colegio"
        (change)="selectCole($event.target.value)"
      >
        <ng-container *ngFor="let cole of colegios">
          <option value={{cole?.id}}>
            {{ cole?.data().nombre | titlecase }}
          </option>
        </ng-container>
      </select>
    </div>
    <button
    class="button"
    [disabled]="clicked"
    (click)="crearCurso();"
  >
  {{'dashboard.cursos.crearcursos.crear'|translate}}
  </button>
  <h2 class="mensaje">{{mensaje}}</h2>
  <h2 class="mensajeSuccess">{{mensajeSuccess}}</h2>
  </div>
</section>
