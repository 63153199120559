<div class="cuerpo" id="video" *ngIf="autenticado">
  <div *ngIf="!mostrar" class="pinsession">
    <div class="titulo">
      <h3>Introduce la clave</h3>
      <p>Esta clave nos asegura que eres una persona autorizada para acceder</p>
    </div>
    <input
      [(ngModel)]="pin"
      class="pass"
      type="password"
      placeholder="{{ 'Clave' | translate }}"
      (keyup.enter)="comprobarPin()"
    />
    <button class="botonacceso" (click)="comprobarPin()">ACCEDER</button>
    <div class="rembox">
      <div class="rememberme">
        <div
          [ngClass]="{ checked: remember }"
          class="checkbox"
          (click)="rememberme()"
        ></div>
        <p (click)="rememberme()" translate>Recordar clave</p>
      </div>
    </div>
    <p>{{ mensajeError }}</p>
  </div>
  <div *ngIf="mostrar" class="cuerpo">
    <div class="titulo2">
      <h3>Pulse en cada archivo para descargar</h3>
      <!-- <button class="botonolvidar" (click)="descargarTodos()">Descargar todos los archivos</button> -->
      <button
        *ngIf="remember"
        class="botonolvidar"
        (click)="olvidarcredenciales()"
      >
        Olvidar clave
      </button>
    </div>
    <div class="archivos">
      <div class="fichero" *ngFor="let file of files">
        -
        <a
          [href]="file.url"
          target="_blank"
          download="{{ file.name }}"
          title="{{ file.name }}"
          >{{ file.name }}</a
        >
      </div>
    </div>
  </div>
</div>

<div *ngIf="!autenticado" class="autenticar">
  <div class="loginbox">
    <header>
      <div class="logo">
        <img src="assets/img/logocolor.png" alt="" />
      </div>
      <div class="title">
        <h1 translate>login.titulo</h1>
      </div>
    </header>
    <article>
      <div class="loginform">
        <label for="" translate> login.usuario </label>
        <input
          [(ngModel)]="email"
          class="user"
          type="text"
          placeholder="{{ 'login.usuario' | translate }}"
        />
        <label for="" translate> login.contraseña </label>
        <div class="password-container">
          <input
            [(ngModel)]="password"
            class="pass"
            [type]="hide ? 'password' : 'text'"
            placeholder="{{ 'login.contraseña' | translate }}"
            (keyup.enter)="login()"
          />
          <img
            src="{{ hide ? 'assets/img/eyeclose2.png' : 'assets/img/eyeopen2.png' }}"
            (click)="hide = !hide"
            class="eye-icon"
          />
        </div>
        <div class="loginbuttons">
          <div>
            <input
              class="acceso"
              type="submit"
              value="{{ 'login.acceso' | translate }}"
              (click)="login()"
            />
          </div>
        </div>
      </div>
      <!-- <div>
        <button (click)="loginInSAMLcharterschoolit()" > Login SAML </button>
      </div> -->
    </article>
   
  </div>
</div>
