import { PasswordresetComponent } from './auth_handlers_page/passwordreset.component';
import { SoporteComponent } from './soporte/soporte.component';
import { OlvidarpassComponent } from './olvidarpass/olvidarpass.component';
import { RegistroComponent } from './registro/registro.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { LoginComponent } from './login/login.component';
import { AdminModule } from './admin/admin.module';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard } from './services/authentication.guard';
import { RegistroAcademicaComponent } from './registro-academica/registro-academica.component';
import { RedirectComponent } from './redirect/redirect.component';
import { NecesitoayudaComponent } from './necesitoayuda/necesitoayuda.component';
import { SupportComponent } from './support/support.component';
import { NopuedoconectarmeComponent } from './nopuedoconectarme/nopuedoconectarme.component';
import { PersonalizacionComponent } from './personalizacion/personalizacion.component';
import { CustomizationComponent } from './customization/customization.component';
import { Test123Component } from './test123/test123.component';
import { ColegiaComponent } from './colegia/colegia.component';
import { AcademicaredirectComponent } from './academicaredirect/academicaredirect.component';
import { MyfregisterComponent } from './myfregister/myfregister.component';
import { LleexregisterComponent } from './lleexregister/lleexregister.component';
import { HelpdeskComponent } from './helpdesk/helpdesk.component';
import { ConnectingfromschoolComponent } from './connectingfromschool/connectingfromschool.component';
import { BullyinglistComponent } from './bullyinglist/bullyinglist.component';
import { ManualComponent } from './manual/manual.component';
import { ManualColegiaComponent } from './manual-colegia/manual-colegia.component';
import { ManualColegiaProfesorComponent } from './manual-colegia-profesor/manual-colegia-profesor.component';
import { PostersComponent } from './posters/posters.component';
import { LiveComponent } from './live/live.component';


const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'colegia',
    component: ColegiaComponent,
  },
  {
    path: 'thecampus',
    component: ColegiaComponent,
  },
  {
    path: 'admin',
    loadChildren: () => AdminModule,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () => DashboardModule,
    canActivate: [AuthenticationGuard],
    data: {
      rol: 'admin',
    },
  },
  {
    path: 'registro',
    component: RegistroComponent,
  },
  {
    path: 'registroAcademica',
    component: RegistroAcademicaComponent,
  },
  {
    path: 'olvidar',
    component: OlvidarpassComponent,
  },
  // {
  //   path: 'soporte', component: SoporteComponent
  // },
  {
    path: 'necesito-ayuda',
    component: NecesitoayudaComponent,
  },
  {
    path: 'help-desk',
    component: HelpdeskComponent,
  },
  {
    path: 'support',
    component: SupportComponent,
  },
  {
    path: 'ayuda_para_conectarme_desde_el_cole',
    component: NopuedoconectarmeComponent,
  },
  {
    path: 'connecting_from_school',
    component: ConnectingfromschoolComponent,
  },
  {
    path: 'personalizacion',
    component: PersonalizacionComponent,
  },
  {
    path: 'customization',
    component: CustomizationComponent,
  },
  {
    path: 'auth',
    component: PasswordresetComponent,
  },
  {
    path: 'video/:nombre',
    component: AcademicaredirectComponent,
  },
  {
    path: 'myfregister23',
    component: MyfregisterComponent,
  },
  {
    path: 'lleexregister23',
    component: LleexregisterComponent,
  },
  {
    path: 'bullying/list/add',
    component: BullyinglistComponent,
  },
  {
    path: 'manualUsuario',
    component: ManualComponent,
  },
  {
    path: 'manualColegia',
    component: ManualColegiaComponent,
  },
  {
    path: 'manualColegia/profesor',
    component: ManualColegiaProfesorComponent,
  },
      {
    path: 'video/:nombre',
    component: AcademicaredirectComponent,
  },
  {
    path: 'posters/academica/:carpeta',
    component: PostersComponent,
  },
  {
    path: 'live/:url',
    component: LiveComponent,
  },
  // {
  //   path: 'test123',
  //   component: Test123Component,
  // },
  { path: ':sala', component: RedirectComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
