<section class="basicbox fondo">
  <header class="headerperfil padtop wsmall">
    <div class="icon">
        <div class="wrapicon">
            <svg style="padding-left: 0.781vw" xmlns="http://www.w3.org/2000/svg" width="46.03" height="16.1" viewBox="0 0 46.03 16.1">
            <g id="Grupo_9468" data-name="Grupo 9468" transform="translate(-7.94 -19.399)" opacity="0.9">
              <path id="Trazado_9254" data-name="Trazado 9254" d="M23.957,41.2h9.315v3.45a1.15,1.15,0,0,0,2.3,0V41.2h4.6v5.749a1.15,1.15,0,0,0,2.3,0v-6.9a1.149,1.149,0,0,0-1.149-1.15H23.957a8.049,8.049,0,1,0,0,2.3Zm-13.682-1.15A5.749,5.749,0,1,1,16.024,45.8,5.749,5.749,0,0,1,10.274,40.049Z" transform="translate(0 -12.599)" fill="#2D3453"/>
              <path id="Trazado_9255" data-name="Trazado 9255" d="M51.433,27Z" transform="translate(2.537 1.599)" fill="#2D3453"/>
            </g>
          </svg>

        </div>
    </div>
    <h3 translate>
      dashboard.perfil.pass.titulo

      <small translate> 
        dashboard.perfil.pass.subtitulo
      </small>
    </h3>
  </header>

  <article>

    <h4 translate >dashboard.perfil.pass.subtitulo </h4>

    <section class="basicbox changepass">

      <div class="formgroup">
        <label for="idpassc" translate >dashboard.perfil.pass.subtitulo </label>
        <input id="idpassc" class="form-control" type="password" placeholder="{{'dashboard.perfil.pass.placeholder1'|translate}}" [(ngModel)]="cupass">
      </div>

      <hr>
      <p><br></p>

      <div class="formgroup">
          <label for="idnewpass1" translate >dashboard.perfil.pass.nueva </label>
          <input id="idnewpass1" class="form-control" type="password" placeholder="{{'dashboard.perfil.pass.placeholder1'|translate}}" [(ngModel)]="pass1">
      </div>

      <div class="formgroup">
          <label for="idnewpass2" translate >dashboard.perfil.pass.repite </label>
          <input id="idnewpass2" class="form-control" type="password" placeholder="{{'dashboard.perfil.pass.placeholder1'|translate}}"  [(ngModel)]="pass2">
      </div>

      <p  >{{saveresponse1}}</p>
      <input type="submit" value="{{'dashboard.perfil.pass.subtitulo'|translate}}"  (click)="changepass()">

    </section>

    <p><br></p>

    <h4 translate>dashboard.perfil.pass.email </h4>

    <section class="basicbox changepass">

      <div class="formgroup">
        <label for="idpassc" translate>dashboard.perfil.pass.actual </label>
        <input id="idpassc" class="form-control" type="password" placeholder="{{'dashboard.perfil.pass.placeholder1'|translate}}" [(ngModel)]="cupass2">
      </div>

      <hr>
      <p><br></p>

      <div class="formgroup">
        <label for="idnewmail1" translate >dashboard.perfil.pass.nuevo </label>
        <input id="idnewmail1" class="form-control" type="email" placeholder="{{'dashboard.perfil.pass.placeholder2'|translate}}" [(ngModel)]="email1">
      </div>

      <div class="formgroup">
        <label for="idnewmail2" translate > dashboard.perfil.pass.repitemail</label>
        <input id="idnewmail2" class="form-control" type="email" placeholder="{{'dashboard.perfil.pass.placeholder2'|translate}}" [(ngModel)]="email2">
      </div>

      <p  >{{saveresponse2}}</p>
      <input type="submit" value="{{'dashboard.perfil.pass.email'| translate}}" (click)="changeemail()">

    </section>


  </article>
</section>

<ng-container *ngIf="guardadoPass">
  <div class="cambiadoPass">
    <p >{{'dashboard.perfil.pass.response.actualizada' | translate}}</p>
  </div>
</ng-container>

<ng-container *ngIf="guardadoEmail">
  <div class="cambiadoEmail">
    <p >{{'dashboard.perfil.pass.response.actualizadoEmail' | translate}}</p>
  </div>
</ng-container>

