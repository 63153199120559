<div class="removeuser" *ngIf="rol=='director'">
    <div id="title">
      <img src="assets/img/removeuserdark.svg" />
      <h1 translate>dashboard.eliminar.titulo</h1> &nbsp;
      <span class="mensaje"> <p translate>dashboard.eliminar.espere</p></span>
     
    </div>
    <div class="list-user" *ngIf="popUp == false">
      <div class="basicbox estu">
        <div class="checkbox-inline" *ngFor="let item of usuarios">
            <ng-container *ngIf="item.payload.doc.data().uid != userUid">
          <input
            type="checkbox"
            name="{{ item.payload.doc.id }}"
            [value]="item.payload.doc.id"
            (change)="getUser($event, item)"
          />
          <b>{{item.payload.doc.data().codigo}}</b>  &nbsp;
        
            {{ item.payload.doc.data().nombres | titlecase }}
            {{ item.payload.doc.data().apellidos | titlecase }}
          &nbsp; {{'dashboard.eliminar.usuario'|translate}} {{ item.payload.doc.data().usuario | titlecase }}
        </ng-container>
        </div>
      </div>
      <h1 class="titleselec"translate>dashboard.eliminar.seleccionados</h1>
      <div class="seleccionados">
          <span></span>
          <div  *ngFor="let item of seleccion">{{item.payload.doc.data().codigo}} &nbsp; {{item.payload.doc.data().usuario}}</div>
      </div>
  
      <button class="button" (click)="popBorrar()"translate>dashboard.eliminar.borrar</button>
    </div>
    <div class="pop-delete" *ngIf="popUp == true">
      <div class="basicbox confirm">
        <div>
          <h1 translate>dashboard.eliminar.mensaje</h1>
        </div>
        <div class="buttons">
          <button class="button" (click)="popBorrar()"translate>dashboard.eliminar.cancelar</button>
          <button class="button" (click)="borrarUsuarios()"translate>dashboard.eliminar.aceptar</button>
        </div>
      </div>
    </div>
  </div>
  