<ng-container *ngIf="!actividad">
<div class="status" [ngClass]="{
                                  'online': presencia === 'online',
                                  'away':  presencia === 'away',
                                  '':  presencia === 'offline'
                              }">
</div>
</ng-container>

<ng-container *ngIf="actividad" translate [translateParams]="{presencia:presencia}">
  dashboard.presencia.activo
</ng-container>
