<!-- /////USUARIO AJENO///// -->

<div class="perfil" *ngIf="noMyUser == true">
  <div class="claseheader">
    <div class="subbasicbox chat headinguser2">
      <div>
        <div class="imgContainer">
          <img
            *ngIf="avatar; else defaultavatar"
            class="imgPro2"
            src="{{ avatar }}"
            alt="{{ nombres | titlecase }} {{ apellidos | titlecase }}"
          />
        </div>
        <ng-template #defaultavatar
          ><img
            class="imgPro2"
            src="assets/img/defaultavatar.jpg"
            alt="{{ nombres | titlecase }} {{ apellidos | titlecase }}"
        /></ng-template>
      </div>
      <div class="boxtitles2">
        <div class="userName">
          <h4>{{ nombres | titlecase }} {{ apellidos | titlecase }}</h4>
        </div>
        <!-- <div class="userData">
          <h5>{{ "dashboard.perfil.telefono" | translate }} {{ telefono }}</h5>
          <h5 *ngIf="colegioUid != 'rfOgj7mwOfo8V3w3964b'">
            {{ "dashboard.perfil.email" | translate }} {{ email }}
          </h5>
         
        </div> -->
      </div>
      <div class="links">
        <div>
          <img
            routerLink="/dashboard/mensajes/chat/{{ chaturl }}"
            src="/assets/img/mensaje.svg"
          />
        </div>
        <div>
          <a
            href="https://in2.thecampus.education/webapp3/#/?conference={{
              usuario
            }}&name={{ user.nombres | titlecase }} {{
              user.apellidos | titlecase
            }}&role=guest&callType=video&join=1"
            target="_blank"
          >
            <img src="assets/img/videollamada.svg" />
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- /////USUARIO NORMAL ///// -->

<div class="perfil" *ngIf="user && noMyUser == false">
  <div class="claseheader">
    <!-- clase box -->
    <div class="subbasicbox chat headinguser">
      <div class="boximage">
        <img
          *ngIf="avatar; else defaultavatar"
          src="{{ avatar }}"
          alt="{{ nombres | titlecase }} {{ apellidos | titlecase }}"
        />
        <ng-template #defaultavatar
          ><img
            src="assets/img/defaultavatar.jpg"
            alt="{{ nombres | titlecase }} {{ apellidos | titlecase }}"
        /></ng-template>
      </div>
      <div class="boxtitles">
        <div class="userName">
          <h4>{{ nombres | titlecase }} {{ apellidos | titlecase }}</h4>
        </div>
        <div class="userData">
          <h5>{{ "dashboard.perfil.telefono" | translate }} {{ telefono }}</h5>
          <h5 *ngIf="colegioUid != 'rfOgj7mwOfo8V3w3964b'">
            {{ "dashboard.perfil.email" | translate }} {{ email }}
          </h5>
          <h5>
            {{ "dashboard.perfil.nacimiento" | translate }}
            {{ fechanac | date : "dd/MM/yyyy" }}
          </h5>
        
        </div>
        <p class="curso"></p>
      </div>
      <div class="rigthbuttons">
        <!-- <a>
          {{ "dashboard.perfil.ajustes" | translate }}
          <span>
            <img src="/assets/img/user-w.svg" alt="" />
          </span>
        </a> -->
        <div
          class="cambiaridioma"
          (click)="activeLanguage()"
          (click)="changeColor()"
        >
          <a>
            {{ "login.cambiaridioma" | translate }}
            <span>
              <img src="/assets/img/language.svg" alt="" />
            </span>
          </a>
        </div>
        <div class="idiomas" *ngIf="idioma == true">
          <div *ngIf="es == true">
            <div class="selected">
              <h2>Español (ES)</h2>
              <hr />
            </div>
            <div>
              <h2 (click)="ingles()" [ngClass]="{ grey: click }">
                English (EN)
              </h2>
            </div>
            <div class="">
              <h2 (click)="arabe()" [ngClass]="{ grey: click }">عربي</h2>
            </div>
          </div>
          <div *ngIf="en == true">
            <div>
              <h2 (click)="espanol()" [ngClass]="{ grey: click }">
                Español (ES)
              </h2>
            </div>
            <div class="selected">
              <h2>English (EN)</h2>
              <hr />
            </div>
            <div class="">
              <h2 (click)="arabe()" [ngClass]="{ grey: click }">عربي</h2>
            </div>
          </div>
          <div *ngIf="ar == true">
            <div>
              <h2 (click)="espanol()" [ngClass]="{ grey: click }">
                Español (ES)
              </h2>
            </div>
            <div>
              <h2 (click)="ingles()" [ngClass]="{ grey: click }">
                English (EN)
              </h2>
            </div>
            <div class="selected">
              <h2>عربي</h2>
              <hr />
            </div>
          </div>
        </div>
      </div>

      <div class="boxdata">
        <ul>
          <li>
            <a
              routerLink="/dashboard/profile/{{ uid }}/ajustes/"
              (click)="
                UpdateBreadcrumb(
                  'dashboard.perfil.perfil',
                  '/dashboard/profile/' + uid + '/ajustes/'
                )
              "
            >
              {{ "dashboard.perfil.ajustes" | translate }}
            </a>
          </li>
          <li>
            <a routerLink="/dashboard/profile/{{ uid }}/pass/">
              {{ "dashboard.perfil.contraseñayemail" | translate }}
            </a>
          </li>

          <li>
            <a
              routerLink="/dashboard/profile/{{ uid }}/foto/"
              (click)="
                UpdateBreadcrumb(
                  'dashboard.perfil.foto',
                  '/dashboard/profile/' + uid + '/foto/'
                )
              "
            >
              {{ "dashboard.perfil.foto" | translate }}
            </a>
          </li>

          <li>
            <a
              routerLink="/dashboard/profile/{{ uid }}/miseventos/"
              (click)="
                UpdateBreadcrumb(
                  'dashboard.perfil.miseventos',
                  '/dashboard/profile/' + uid + '/miseventos/'
                )
              "
            >
              {{ "dashboard.perfil.eventos" | translate }}
            </a>
          </li>
          <li>
            <a
              routerLink="/dashboard/profile/{{ uid }}/misala/"
              (click)="
                UpdateBreadcrumb(
                  'dashboard.perfil.ajustesmisala',
                  '/dashboard/profile/' + uid + '/misala/'
                )
              "
            >
              {{ "dashboard.perfil.misala" | translate }}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <!--/clase box -->
  </div>
  <div class="formcontent">
    <router-outlet *ngIf="currentuid == urlid"> </router-outlet>
  </div>
</div>
