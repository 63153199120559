import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { NotificacionesService } from './../../services/notificaciones.service';
import { PresenciaService } from '../../services/presencia.service';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { AlertasService } from 'src/app/services/alertas.service';

@Component({
  selector: 'app-menuadmin',
  templateUrl: './menuadmin.component.html',
  styleUrls: ['./menuadmin.component.css'],
})
export class MenuadminComponent implements OnInit {
  @Input() uid: string;
  @Input() avatar: string;
  @Input() uidb: string;
  lasnotificaciones: any;
  lasAlertas: any;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private userService: UserService,
    private presencia: PresenciaService,
    private notificacionesService: NotificacionesService,
    private breadService: BreadcrumbService,
    private alertasService: AlertasService
  ) {}

  ngOnInit(): void {
    const Userdata: any = this.userService.getUserinfodata();

    if (Userdata === undefined) {
      this.authenticationService.isAuth().subscribe((auth) => {
        if (auth) {
          this.notificacionesService
            .getNotificacionessinver(auth.uid)
            .subscribe((notificaciones) => {
              this.lasnotificaciones = notificaciones.length;
            });
          this.alertasService.getAlerts().subscribe((alertasAdmin) => {
            var contador = 0;
            alertasAdmin.forEach(alerta=>{
              var alertaDato = alerta.payload.doc.data();
              if(alertaDato["reportada"]==false){
                contador++;
              }
            })
            this.lasAlertas = contador;
          });
        }
      });
    } else {
      this.notificacionesService
        .getNotificacionessinver(Userdata.payload.doc.data().uid)
        .subscribe((notificaciones) => {
          this.lasnotificaciones = notificaciones.length;
        });
      this.alertasService.getAlerts().subscribe((alertasAdmin) => {
        var contador = 0;
            alertasAdmin.forEach(alerta=>{
              var alertaDato = alerta.payload.doc.data();
              if(alertaDato["reportada"]==false){
                contador++;
              }
            })
            this.lasAlertas = contador;
      });
    }
  }
  logout(uiddb) {
    const status = {
      status: [{ estado: 'offline', time: new Date() }],
    };
    this.presencia
      .setPresence('offline')
      .then((updated) => {
        this.authenticationService
          .logOut()
          .then(() => {
            this.router.navigate(['']);
          })
          .catch((error) => {
            console.log('error');
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  UpdateBreadcrumb(name, url) {
    this.breadService.addtobread(name, url);
  }
}
