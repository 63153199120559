<section class="basicbox grupo">
    <header>
      <div class="wrapicon">
        <img src="/assets/img/informacion.png" alt="" />
      </div>
      <h3 ><b translate> dashboard.ayuda.titulo</b></h3>
    </header>
    <div>
      <div>
        <div>
          <a
            class="manual"
            href="https://thecampus.education/necesito-ayuda"
            target="_blank"
            title=" {{ 'login.help' | translate }}"
          >
          <img class="icono" src="assets/img/help.png" alt="" />
            <h2 translate>  {{ "login.help" | translate }}</h2>
          </a>
        </div>
      </div>
      <div>
        <a
          class="manual"
          href="{{urlProblemas}}"
          target="_blank"
          download="{{'dashboard.ayuda.problemas' | translate }}.pdf"
          title="{{'dashboard.ayuda.problemas' | translate }}"
        >
          <img class="icono" src="assets/img/pdf.png" alt="" />
          <h2 translate> dashboard.ayuda.problemas</h2>
        </a>
      </div>
    </div>
    <div>
      <div>
        <a
          class="manual"
          href="{{ this.manual.url }}"
          target="_blank"
          title="{{'dashboard.ayuda.descargar' | translate }}"
        >
          <img class="icono" src="assets/img/pdf.png" alt="" />
          <h2 translate> dashboard.ayuda.descargar</h2>
        </a>
      </div>
    </div>
    <div class="videoNoDesplegado" *ngIf="!videosDesplegados">
      <h2 (click)="desplegarVideos()">🡢 <span translate> dashboard.ayuda.explicativos</span></h2>
    </div>
    <div>
      <h2
        class="h2desplegado"
        *ngIf="videosDesplegados"
        (click)="desplegarVideos()"
      >
        🡣 <span translate> dashboard.ayuda.explicativos</span>
      </h2>
    </div>
    <div class="videos videoDesplegado" *ngIf="videosDesplegados">
      <div class="video" *ngFor="let video of videosPildoraIdioma">
        <h2>
          <b>{{ video.titulo }}</b>
        </h2>
        <video width="250px" src="{{ video.src }}" controls></video>
      </div>
    </div>
  </section>
  