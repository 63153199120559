import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class NotificacionesService {

  constructor(private firestore: AngularFirestore) { }

  getNotificaciones(id) {
    return this.firestore.collection('notificaciones', ref => ref.where('user', '==' , id).orderBy('fecha', 'asc')).snapshotChanges();
  }

  getNotificacionessinver(id) {
    return this.firestore.collection('notificaciones', ref => ref.where('verlo', '==' , id).orderBy('fecha', 'asc')).snapshotChanges();
  }

  getNotificacionesMensajes(user) {
    return this.firestore.collection('notificaciones', ref => ref.where('mensaje', '==' , true).where('user', '==' , user).orderBy('fecha', 'desc')).snapshotChanges();
  }
  getNotificacionesMensajesDeEmisor(user, emisor) {
    return this.firestore.collection('notificaciones', ref => ref.where('mensaje', '==' , true).where('user', '==' , user).where('emisor', '==' , emisor).orderBy('fecha', 'desc')).snapshotChanges();
  }

  getNotificacionById(id) {
    return this.firestore.collection('notificaciones').doc(id).snapshotChanges();
  }

  createdNotificacion(evento) {
    return this.firestore.collection('notificaciones').add(evento);
  }

  editNotificacion(evento) {
    return this.firestore.collection('notificaciones').doc(evento.uid).set(evento);
  }

  removeNotificacion(id) {
    return this.firestore.collection('notificaciones').doc(id).delete();
  }

  updateNotificacion(id, update){
    return this.firestore.collection('notificaciones').doc(id).update(update);
  }

  searchNotification(eventid) {
    return this.firestore.collection('notificaciones', ref => ref.where('event_id', '==' , eventid)).snapshotChanges();
  }
}
