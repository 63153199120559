<app-header [nombres]="nombres" [apellidos]="apellidos"></app-header>

<div class="maingrid">
	<div>
		<app-menuadmin [uidb]="mainUserUidDb" [avatar]="useravatar"></app-menuadmin>
	</div>

	<div>
		<!-- content -->
		

			<!-- contenedor cambiante -->
			<section class="changecontent">
					<router-outlet>
					</router-outlet>
			</section>
			<!-- /contenedor cambiante -->
			  
		</div>
	
</div>

