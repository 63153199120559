import { Component, OnInit } from '@angular/core';
import { AIService } from './../services/ai.service';
import { ChatService } from './../services/chat.service';
import { UserService } from './../../services/user.service';
import { AuthenticationService } from './../../services/authentication.service';
import { ClasesService } from './../../services/clases.service';
import { AngularFireFunctions } from '@angular/fire/functions';

@Component({
	selector: 'app-train',
	templateUrl: './train.component.html',
	styleUrls: ['./ai.component.css']
})
export class AITrainComponent implements OnInit {
  mainuserid: string;
  chats = <any> [];
  mainuseremail: any;
  nombres: any;
  apellidos: any;
  messagePool = <any> [];

  newstring: string = null;
  newtype: string = null;
  strings = <any> [];

	constructor(private clasesService: ClasesService,
              private chatService: ChatService,
              private aiService: AIService,
              private userService: UserService,
              private functions: AngularFireFunctions,
              private authenticationService: AuthenticationService)
  {


  }

  ngOnInit(): void {
		const Userdata: any = this.userService.getUserinfodata();

    if (Userdata === undefined) {
      this.authenticationService.getStatus().subscribe((status) => {
        this.mainuserid = status.uid;
        this.mainuseremail = status.email;

        this.authenticationService.isUserAdmin(this.mainuserid).subscribe(userdata => {


          if(userdata[0]){
            const userdata2: any = userdata[0];
            this.nombres = userdata2.nombres; /* nombres del usuario logueado */
            this.apellidos = userdata2.apellidos; /*apellidos del usuario logueado */
          }
        });

        this.getStrings();
      });

    } else  {

      this.mainuserid = Userdata.payload.doc.data().uid;
      this.mainuseremail = Userdata.payload.doc.data().email;
      this.nombres = Userdata.payload.doc.data().nombres; /* nombres del usuario logueado */
      this.apellidos = Userdata.payload.doc.data().apellidos; /*apellidos del usuario logueado */

      this.getStrings();
    }
	}

  getStrings(){
    this.aiService.getStrings().subscribe((strings) => {
      this.strings = strings;
    });
  }

  addString(){
    this.aiService.saveString({'lang': 'es', 'str': this.newstring.toLowerCase(), 'type': this.newtype});
    this.getStrings();
  }

  trainData(){
    const callable = this.functions.httpsCallable('aiTrain');

    const obs = callable({});

    obs.subscribe(async res => {
      console.log(res)
    });
  }
  add(){
    this.aiService.addData({"palabra":this.newstring.toLowerCase()});
  }
}
