import { Component, Input, OnInit } from '@angular/core';
import { ColegiosService } from 'src/app/services/colegios.service';
import { ClasesService } from 'src/app/services/clases.service';

@Component({
  selector: 'app-cursobox',
  templateUrl: './cursobox.component.html',
  styleUrls: ['./cursobox.component.css'],
})
export class CursoboxComponent implements OnInit {
  @Input() userdata;
  @Input() curso;
  colegio: any;
  clases: any = [];

  constructor(private colegioService: ColegiosService,
    private clasesService:ClasesService) {}

  ngOnInit(): void {
    this.cargarNombreColegio();
    this.cargarNombreClases();
  }

  cargarNombreColegio(){
    this.colegioService.getColegioByCursoId(this.curso.id).subscribe(colegio=>{
      colegio.forEach((cole)=>{
        this.colegio = cole.data()
      })
    })

  }
  cargarNombreClases(){
    this.curso.data().clases.forEach(clase => {
        this.clasesService.getClaseByIdNow(clase).subscribe(cla=>{
          this.clases.push(cla);
        })
    });
  }
}
