import { HttpParams, HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-estadisticas',
  templateUrl: './estadisticas.component.html',
  styleUrls: ['./estadisticas.component.css']
})
export class EstadisticasComponent implements OnInit {
  pexipdata: any;
  listaEstadistica: any = new Array();
  cargadoDatos: boolean;
  userUid: string;
  user: any;
  mainUserUidDb: string;
  datosUsuario: unknown;
  spin = true;

  constructor(
    private http : HttpClient,
    private authenticationService: AuthenticationService,
    private _userService: UserService,
  ) { }

  ngOnInit(): void {
    
    const Userdata: any = this._userService.getUserinfodata();
    this.cargadoDatos = false;
    if (Userdata === undefined) {
      this.authenticationService.isAuth().subscribe((auth) => {
        if (auth) {
          this.userUid = auth.uid;
          this._userService.getUserById(this.userUid).subscribe((userdata) => {
            if (userdata[0].payload.doc.data()) {
              this.user = userdata[0];
              this.mainUserUidDb = userdata[0].payload.doc.id;
              this.datosUsuario = userdata[0].payload.doc.data();
           

              this.getroom().then(() => {
                this.cargadoDatos = true;
              });
            }
          });
        }
      });
    } else {
      this.user = Userdata;
      this.userUid = Userdata.payload.doc.data().uid;
      this.mainUserUidDb = Userdata.payload.doc.id;
      this.datosUsuario = Userdata.payload.doc.data();
      this.getroom().then(() => {
        this.cargadoDatos = true;
      });
    }

   
  }

    async getroom() {
      await this.http
        .get<any>(
          'https://us-central1-the-campus-prod.cloudfunctions.net/historial',
          { params: new HttpParams().set('salaname', this.datosUsuario['usuario'] ) }
        )
        .subscribe((data) => {
          var total = Math.min(20,data.meta.total_count);
          data.objects.forEach((element) => {
            var estadistica = {
              nombre: '',
              fechaInicio: '',
              fechaFin: '',
              duracion: 0,
              participantes: 0,
              participantesData:[],
            };
            estadistica.nombre = element.name;
            estadistica.fechaInicio = element.start_time;
            estadistica.fechaFin = element.end_time;
            estadistica.duracion = element.duration / 60;
            estadistica.participantes = element.participant_count;
            estadistica.participantesData = element.participants

            this.listaEstadistica.push(estadistica);
            if(this.listaEstadistica.length<total){
              this.spin=true
            }
            else{

              this.spin=false;
            }
        
        });
    })
  }
}



