<div class="container1">

    <div class="titulo">

      <h2>
        {{ curso.data().nombre }}
          <p class="colegio cole"> {{'dashboard.cursos.cursobox.colegio'|translate}} {{ colegio.nombre }}</p>
      </h2>
    </div>
 
  <div>
    <p> <b> {{'dashboard.cursos.cursobox.misclases'|translate}} </b></p>
    <div class="clases">
      <div *ngFor="let clase of clases">
        <p class="nombreclase" routerLink="/dashboard/clase/{{clase.id}}">{{ clase.data().nombre | titlecase}}</p>
      </div>
    </div>
  </div>
</div>
