import { NotificacionesService } from './../../../../services/notificaciones.service';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../../services/authentication.service';
import { EventosService } from '../../../../services/eventos.service';
import { UserService } from '../../../../services/user.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';


@Component({
  selector: 'app-miseventos',
  templateUrl: './miseventos.component.html',
  styleUrls: ['./miseventos.component.css']
})
export class MiseventosComponent implements OnInit {
  userUid: any;
  userdata: any;
  eventos = [];
  orderbydate: boolean;
  orderbyname: boolean;




  constructor(private eventosService: EventosService,
              private userService: UserService,
              private NotificacionesService: NotificacionesService,
              private breadService: BreadcrumbService,
              private authenticationService: AuthenticationService) { }


  ngOnInit(): void {
    const Userdata: any = this.userService.getUserinfodata();
 
    if (Userdata === undefined) {
      this.getmainuserdata();
    } else {
      this.userUid = Userdata.payload.doc.data().uid;
      this.eventosService.getEventosByUseridOrdenado(this.userUid).subscribe(eventos => {
        this.eventos = eventos;
      });
    }
  }


  /* trae informacion principal del usuario logueado */
  getmainuserdata() {
    this.authenticationService.isAuth().subscribe(auth =>{
      if(auth) {
        this.userUid = auth.uid; /* id del usuario */

        this.eventosService.getEventosByUseridOrdenado(this.userUid).subscribe(eventos => {
          this.eventos = eventos;
        });

      };
    });
  }

  
  UpdateBreadcrumb(name, url) {
    this.breadService.addtobread(name, url);
  }

  ordenarPorFecha() {
    this.orderbydate=!this.orderbydate;
    console.log(this.eventos[0].payload.doc.data())
    this.eventos.forEach(data=>{
      console.log(data.payload.doc.data().fecha_inicio)
    })

    if(this.orderbydate){
      this.ordenarDes('fecha_inicio')
    }
    else{
      this.ordenarAsc('fecha_inicio')
    }
    
  }

  ordenarDes(item){
    this.eventos.sort(function (a, b) {
      if (a.payload.doc.data()[item] < b.payload.doc.data()[item]) {
        return -1;
      } else if (a.payload.doc.data()[item] > b.payload.doc.data()[item]) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  ordenarAsc(item){
    this.eventos.sort(function (a, b) {
      if (a.payload.doc.data()[item] > b.payload.doc.data()[item]) {
        return -1;
      } else if (a.payload.doc.data()[item] < b.payload.doc.data()[item]) {
        return 1;
      } else {
        return 0;
      }
    });
  }
  
  ordenarPorNombre() {
    this.orderbyname=!this.orderbyname;
    if(this.orderbyname){
      this.ordenarDes('nombre')
    }
    else{
      this.ordenarAsc('nombre')
    }
    
  }




}
