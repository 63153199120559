import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getDate } from 'date-fns';
import { AlertasService } from 'src/app/services/alertas.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-alerta',
  templateUrl: './alerta.component.html',
  styleUrls: ['./alerta.component.css'],
})
export class AlertaComponent implements OnInit {
  alerta = {
    avatarColegio: '',
    estado: '',
    fechaAlerta: '',
    idAlerta: '',
    idColegio: '',
    idSupervisor: '',
    idUsuario: '',
    mensaje: '',
    mostrarSupervisor: true,
    avatarAlumno: '',
    reportada: true,
    nombre:''
  };
  idOriginal: any = '';
  nombreAlumno: any;

  constructor(
    private _alertService: AlertasService,
    private route: ActivatedRoute,
    private router: Router,
    private _userService: UserService
  ) {}
  ngOnInit(): void {
    this.alerta.idAlerta = this.route.snapshot.params['idAlerta'];
    this._alertService.getAlerts().subscribe((alertas) => {
      alertas.forEach((alertaComp) => {
        let alertaVar = alertaComp.payload.doc.data();
        if (alertaVar['idAlerta'] == this.alerta.idAlerta) {
          this.alerta.avatarColegio = alertaVar['avatarColegio'];
          this.alerta.estado = alertaVar['estado'];
          this.alerta.fechaAlerta = alertaVar['fechaAlerta'].toDate();
          this.alerta.idColegio = alertaVar['idColegio'];
          this.alerta.idSupervisor = alertaVar['idSupervisor'];
          this.alerta.idUsuario = alertaVar['idUsuario'];
          this.alerta.mensaje = alertaVar['mensaje'];
          this.alerta.mostrarSupervisor = alertaVar['mostrarSupervisor'];
          this.alerta.reportada = alertaVar['reportada'];
          this.alerta.nombre = alertaVar['nombre'];
          this.nombreAlumno = this.alerta.nombre;
          if (alertaVar['avatarAlumno']) {
            this.alerta.avatarAlumno = alertaVar['avatarAlumno'];
          } else {
            // this.alerta.avatarAlumno = '';
            this.alerta.avatarAlumno = 'assets/img/defaultavatar.jpg';
          }
          this.idOriginal = alertaComp.payload.doc.id;
          // this.getNameOfUsers();
        }
      });
    });
  }

  getNameOfUsers() {
    this._userService.getUsers().subscribe((users) => {
      users.forEach((user) => {
        const User = user.payload.doc.data();
        if (User['uid'] == this.alerta.idUsuario) {
          // this.nombreAlumno = User['usuario'];
          if (User['avatar']) {
            this.alerta.avatarAlumno = User['avatar'];
          } else {
            this.alerta.avatarAlumno = 'assets/img/defaultavatar.jpg';
          }
        }
      });
    });
  }
  discardAlert() {
    this.alerta.estado = 'rechazada';
    this._alertService.editAlerta(this.idOriginal, this.alerta);
    this.router.navigate(['dashboard/alertas']);
  }
  aceptAlert() {
    this.alerta.estado = 'aceptada';
    this._alertService.editAlerta(this.idOriginal, this.alerta);
    this.router.navigate(['dashboard/alertas']);
  }
  deleteAlert() {
    this.alerta.mostrarSupervisor = false;
    this._alertService.editAlerta(this.idOriginal, this.alerta);
    this.router.navigate(['dashboard/alertas']);
  }
}
