<div *ngIf="currentclass.administradores.includes(userUid) || rol=='director'">
  <section class="basicbox clase grupo fondo">
    <header>
      <div class="icon">
        <div class="wrapicon">
          <img src="/assets/img/grupos-b.svg" alt="" />
        </div>
      </div>
      <h3 translate>dashboard.clase.editarC.titulo</h3>
    </header>

    <div class="basicbox data">
      <section>
        <h4 class="marbot" translate>dashboard.clase.editarC.nombreydes</h4>

        <!-- <div class="twocolsg"> -->
          <div>
            <div class="formgroup">
              <label for="idnombg" translate>dashboard.clase.editarC.nombre</label>
              <input
                id="idnombg"
                class="form-control"
                type="text"
                placeholder="{{'dashboard.clase.editarC.escribir'|translate}}"
                [(ngModel)]="nombre"
              />
            </div>

            <div class="formgroup">
              <label for="iddesg" translate>dashboard.clase.editarC.descripcion </label>
              <textarea
                id="iddesg"
                class="form-control"
                maxlength="100"
                placeholder="{{'dashboard.clase.editarC.escribir'|translate}}"
                [(ngModel)]="descripcion"
              ></textarea>
            </div>

            <div class="formgroup">
              <label for="idcurso">Curso </label>
              <select id="idcurso" class="form-control" [(ngModel)]="curso">
                <ng-container *ngFor="let curso of cursos;" >
                  <option value="{{curso}}">{{curso}}</option>
                </ng-container>
              </select>
            </div>
            
          </div>

          <div></div>
        <!-- </div> -->
      </section>

      <section>
        <!-- <div class="twocolsg"> -->
          <div>
            <h4 class="marbot3" translate>dashboard.clase.editarC.subir </h4>

            <p translate>
              dashboard.clase.editarC.subirdes
            </p>

            <div class="basicbox pad50 uploadfile">
              <div class="containerU">
                <div class="editDelete">
                  <button (click)="file.click()">
                    {{'dashboard.clase.editarC.buscar'|translate}} <img src="assets/img/lupa.svg" />
                  </button>
                </div>

                <div class="dragfile">
                  <image-cropper
                    *ngIf="changeFoto"
                    [imageChangedEvent]="imageChangedEvent"
                    [maintainAspectRatio]="true"
                    [aspectRatio]="1 / 1"
                    [cropperMinWidth]="120"
                    [cropperMinHeight]="120"
                    format="jpg"
                    (imageCropped)="imageCropped($event)"
                    (imageLoaded)="imageLoaded()"
                    (cropperReady)="cropperReady()"
                    (loadImageFailed)="loadImageFailed()"
                  ></image-cropper>
                  <input
                    [hidden]="true"
                    type="file"
                    (change)="fileChangeEvent($event)"
                    accept="image/*"
                    #file
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- <div>
            <h4 class="marbot2">Visualización de la imagen de la clase</h4>

            <p>Visualiza la imagen de la clase en tamaño real</p>

            <div class="basicbox pad50 visualizaimg">
              <div class="subbox">
                <ng-container *ngIf="!imageChangedEvent && currentclass">
                  <img
                    *ngIf="currentclass.imagen"
                    src="{{ currentclass.imagen }}"
                    alt="{{ currentclass.nombre }}"
                  />
                </ng-container>

                <image-cropper
                  *ngIf="imageChangedEvent"
                  [imageChangedEvent]="imageChangedEvent"
                  [maintainAspectRatio]="true"
                  [aspectRatio]="1 / 1"
                  [cropperMinWidth]="150"
                  [cropperMinHeight]="150"
                  format="jpg"
                  (imageLoaded)="imageLoaded()"
                ></image-cropper>
              </div>

              <p>150 x 150 px</p>
            </div>
          </div> -->
        <!-- </div> -->
      </section>
      <section *ngIf="currentclass.administradores[0]==userUid">
        <h4 class="martop marbot4" translate>dashboard.clase.editarC.cambiarAd</h4>
        <p (click)="cambiarAdmin()" translate>dashboard.clase.editarC.selecAd</p>
        <div class="formgroup">
          <select  class="form-control"
            name ="miselect" [(ngModel)] ="miselect" >
            <option [value]="admin.uid" *ngFor="let admin of adminData">{{admin.nombres | titlecase}}</option>
           </select>

        </div>
      </section>
      <section>
        <h4 *ngIf="currentclass.administradores[0]==userUid" class="martop marbot4" translate>dashboard.clase.editarC.invitar4</h4>
        <h4 *ngIf="currentclass.administradores[0]!=userUid" class="martop marbot4" translate>dashboard.clase.editarC.invitar3</h4>
        <p translate>
          dashboard.clase.editarC.invitarDes
        </p>
        
        <div class="breadcrumb">
          <ul>
            <ng-container *ngFor="let user of userlist">
              <ng-container *ngIf="user.payload.doc.data().uid !== userUid">
                <li>
                  <span>
                    {{ user.payload.doc.data().nombres | titlecase }}
                    {{ user.payload.doc.data().apellidos | titlecase }}
                    <span
                    class="close"
                    (click)="removeuser(user.payload.doc.data().uid)"
                    >
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11.513"
                    height="8.063"
                    viewBox="0 0 11.513 8.063"
                    >
                    <g
                    id="Grupo_9481"
                    data-name="Grupo 9481"
                    transform="translate(-5.602 -5.601)"
                    >
                    <path
                    id="Trazado_9279"
                    data-name="Trazado 9279"
                    d="M17.115,9.633Z"
                    fill="#fff"
                    />
                    <path
                    id="Trazado_9280"
                    data-name="Trazado 9280"
                    d="M16.889,9.164a.575.575,0,0,0-.814,0l0,0-3.045,3.05L9.982,9.164a.578.578,0,0,0-.817.817l3.05,3.045-3.05,3.045a.578.578,0,0,0,.817.817l3.045-3.05,3.045,3.05a.578.578,0,0,0,.817-.817l-3.05-3.045,3.05-3.045a.575.575,0,0,0,0-.814Z"
                    transform="translate(-3.394 -3.393)"
                    fill="#fff"
                    />
                  </g>
                </svg>
              </span>
            </span>
          </li>
        </ng-container>
      </ng-container>
    </ul>
  </div>
  
  <ng-container *ngIf="results">
    <div class="search">
      <img src="assets/img/search.svg" />
      <input
        type="text"
        placeholder="{{'dashboard.grupos.buscarmiembros'|translate}}"
        [(ngModel)]="searchValue"
      />
    </div>
    <div class="basicbox twocolb">
            <ng-container *ngFor="let result of results | search2: searchValue">
              <ng-container *ngIf="result.payload.doc.data().uid !== userUid">
                <!--userbox-->
                <div class="userbox">
                  <div class="useravatar">
                    <img
                      *ngIf="
                        result.payload.doc.data().avatar;
                        else defaultheadavatar
                      "
                      src="{{ result.payload.doc.data().avatar }}"
                      alt="{{
                        result.payload.doc.data().nombres | titlecase
                      }} {{ result.payload.doc.data().apellidos | titlecase }}"
                    />
                    <ng-template #defaultheadavatar
                      ><img
                        src="assets/img/defaultavatar.jpg"
                        alt="{{
                          result.payload.doc.data().nombres | titlecase
                        }} {{
                          result.payload.doc.data().apellidos | titlecase
                        }}"
                    /></ng-template>
                  </div>
                  <div>
                    <h4>
                      {{ result.payload.doc.data().nombres | titlecase }}
                      {{ result.payload.doc.data().apellidos | titlecase }}
                    </h4>
                  </div>
                  <div>
                    <div>
                      <div class="plus" (click)="adduser(result)">+</div>
                    </div>
                    <div></div>
                  </div>
                </div>
                <!--/userbox-->
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </section>
    </div>

    <p>{{ saveresponse }}</p>
    <button
      class="button"
      [disabled]="clicked"
      (click)="createclass(); clicked = true"
    >
    {{'dashboard.clase.editarC.guardar'|translate}}
    </button>
  </section>
</div>
