import { Component, OnInit } from '@angular/core';
import { AIService } from '../admin/services/ai.service';

@Component({
  selector: 'app-bullyinglist',
  templateUrl: './bullyinglist.component.html',
  styleUrls: ['./bullyinglist.component.css'],
})
export class BullyinglistComponent implements OnInit {
  palabra = '';
  mensaje = '';
  listaPalabras: any;
  cambiar: boolean = false;
  constructor(private _aiBullying: AIService) {}

  ngOnInit(): void {
    this._aiBullying.getWords().subscribe((palabras)=>{
      this.listaPalabras=[]
      palabras.forEach((palabra:any)=>{
        let word = palabra.payload.doc.data().palabra
        this.listaPalabras.push(word)
      })
    })
  }

  comprobarPalabra() {
    if (this.palabra != '') {
      let comprobado = false;
      this._aiBullying.compareWord(this.palabra.toLowerCase()).subscribe((result) => {
        if (!comprobado) {
          comprobado = true;
          if (result.length > 0) {
            this.mensaje = 'La palabra/frase ya existe en la lista';
          } else {
            this.subirPalabra();
          }
        }
      });
    }
  }

  subirPalabra() {
    let data = {
      palabra: this.palabra.toLowerCase(),
    };

    this._aiBullying.addData(data).then(() => {
      this.mensaje = 'Subido con éxito';
      this.palabra = '';
      setTimeout(() => {
        this.mensaje = '';
      }, 3000);
    });
  }

  changeLayout(){
    this.cambiar =!this.cambiar
  }
}
