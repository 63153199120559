import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-soporte',
  templateUrl: './soporte.component.html',
  styleUrls: ['./soporte.component.css']
})
export class SoporteComponent implements OnInit {
  lenguaje: string = 'es';

  constructor() { }

  ngOnInit(): void {
    
    if (localStorage.getItem('idioma')) {
      this.lenguaje = localStorage.getItem('idioma');
    } else {
      this.lenguaje = 'es'
    }

  }

}
