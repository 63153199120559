import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CursosService } from 'src/app/services/cursos.service';
import { UserService } from 'src/app/services/user.service';
import { ColegiosService } from 'src/app/services/colegios.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-crearcursos',
  templateUrl: './crearcursos.component.html',
  styleUrls: ['./crearcursos.component.css'],
})
export class CrearcursosComponent implements OnInit {
  userUid: any;
  userdata: any;
  curso: any;
  nombre = '';
  colegio: any;
  colegios: any = [];
  clicked = false;
  mensaje: string = '';
  mensajeNombre: string = '';
  datosCole: any;
  mensajeSuccess=""
  id: any;

  constructor(
    private cursoService: CursosService,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private colegiosService: ColegiosService,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    const Userdata: any = this.userService.getUserinfodata();

    if (Userdata === undefined) {
      this.getmainuserdata();
    } else {
      this.id =  Userdata.payload.doc.id
      this.userUid = Userdata.payload.doc.data().uid; /* id del usuario */
      this.userdata = Userdata.payload.doc.data();
      this.getColegios();
    }
  }

  /* trae informacion principal del usuario logueado */
  getmainuserdata() {
    this.authenticationService.isAuth().subscribe((auth) => {
      if (auth) {
        this.userUid = auth.uid; /* id del usuario */
        this.userService.getUserById(this.userUid).subscribe((userdata) => {
            if (userdata[0]) {
              this.userdata = userdata[0].payload.doc.data();
              this.id =  userdata[0].payload.doc.id
              this.getColegios();
            }
          });
      }
    });
  }

  getColegios() {
    this.userdata.colegios.forEach((cole) => {
      var obs = this.colegiosService
        .getColegioByIdNow(cole)
        .subscribe((colegio) => {
          this.colegios.push(colegio);
          this.colegio = colegio.id;
          obs.unsubscribe();
        });
    });
  }
  selectCole(cole) {
    console.log('cole', cole);
    console.log('this', this.colegio);
    // this.colegio = cole
  }

  crearCurso() {
    if (this.nombre == '' || this.colegio == null) {

      this.translate.get('dashboard.cursos.crearcursos.rellene').subscribe((data) => {
        this.mensaje = data;
        this.translate.get('dashboard.cursos.crearcursos.camporequerido').subscribe((data2) => {
          this.mensajeNombre = data2;
          return;
        })
      })
    }
    this.mensaje = '';
    this.mensajeNombre = '';

    var curso = {
      nombre: this.nombre,
      colegios: [this.colegio],
      clases: [],
    };
    this.recogerColegio().then(() => {
      if (this.datosCole != null) {
      
        this.cursoService
          .createdCurso(curso)
          .then((cursillo) => {
            this.datosCole.cursos.push(cursillo.id)
            this.colegiosService
              .editColegio( this.datosCole, this.colegio).then(()=>{

                var usuariodatos = this.userdata;
                usuariodatos.cursos.push({nombre:this.nombre, uid:cursillo.id});
                this.userService.editUser(usuariodatos,this.id).then(()=>{

                  this.translate.get('dashboard.cursos.crearcursos.creado').subscribe((data2) => {
                    this.mensajeSuccess = data2;
                  })
                  this.nombre="";
                  setTimeout(() => {
                    this.router.navigate(['dashboard/cursos']);
                  }, 2000);
                }).catch(error=>{
                  console.log("error", error);
                  this.translate.get('dashboard.cursos.crearcursos.error').subscribe((data2) => {
                    this.mensaje = data2;
                  })
                })



              })
          })
          .catch((error) => {
            this.translate.get('dashboard.cursos.crearcursos.error').subscribe((data2) => {
              this.mensaje = data2;
            })
          });
      } else {
        console.log('error', this.datosCole);

        this.translate.get('dashboard.cursos.crearcursos.error').subscribe((data2) => {
          this.mensaje = data2;
        })
      }
    });
  }

  async recogerColegio() {
    await this.colegios.forEach((cole) => {
      if (cole.id == this.colegio) {
        this.datosCole = cole.data();
      }
    });
  }
}
