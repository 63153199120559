import { Component, OnInit, OnDestroy } from '@angular/core';
import { AlertasService } from 'src/app/services/alertas.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ColegiosService } from 'src/app/services/colegios.service';
import { UserService } from 'src/app/services/user.service';
import { AIService } from '../services/ai.service';
import { ChatService } from '../services/chat.service';
import { NumeroalertasService } from '../services/numeroalertas.service';

@Component({
  selector: 'app-alertas',
  templateUrl: './alertas.component.html',
  styleUrls: ['./alertas.component.css'],
})
export class AlertasComponent implements OnInit {
  alertas: Array<any>;
  alertasHistorial: Array<any>;
  menuHistorialActivo: boolean;
  // fechaCheck: any;
  // uid: string;
  admin: any;
  palabras: Array<any>;
  mensajes: Array<any>;
  checkeo: Array<any>;
  chats = <any>[];
  idAdminOriginal: string;

  // mainuserid: any;
  // mainUserUidDb: any;
  // datosUsuario: any;
  mensaje: string ="";

  constructor(
    private _alertService: AlertasService,
    private authenticationService: AuthenticationService,
    private _userService: UserService,
    private _colegiosService: ColegiosService,
    private _numeroAlertas: NumeroalertasService,
    private _aiBullying: AIService,
    private _mensajes: ChatService
  ) {}

  ngOnInit(): void {
    // const Userdata: any = this._userService.getUserinfodata();

    // if (Userdata === undefined) {
    //   this.getCurrentUser();
    // } else {
    //   // this.mainuserid = Userdata.payload.doc.data().uid;
    //   // this.mainUserUidDb = Userdata.payload.doc.id;
    //   // this.datosUsuario = Userdata.payload.doc.data();
    //   // this.fechaCheck = Userdata.payload.doc.data().fechaCheck;
    // }

    this.getAlertas();
  }

  getCurrentUser() {
    this.authenticationService.isAuth().subscribe((auth) => {
      if (auth) {
        // this.mainuserid = auth.uid;

        // this.authenticationService.isUserAdmin(this.mainuserid).subscribe(userdata => {
        //   // TODO: check and redirect to login
        //   // if( userdata.rol != 'admin' ){
        //   // }
        // });

        // this._userService.getUserById(this.mainuserid).subscribe((userdata) => {
        //   if (userdata[0].payload.doc.data()) {
        //     // this.mainUserUidDb = userdata[0].payload.doc.id;
        //     // this.datosUsuario = userdata[0].payload.doc.data();
        //     // this.fechaCheck = this.datosUsuario.fechaCheck;
        //   }
        // });
      }
    });
  }

  getAlertas() {
    this.alertas = new Array();
    this.alertasHistorial = new Array();
    this.menuHistorialActivo = false;
    this._alertService.getAlerts().subscribe((alerts) => {
      this.alertas.splice(0, this.alertas.length);
      this.alertasHistorial.splice(0, this.alertasHistorial.length);
      alerts
        .sort(
          (a, b) =>
            b.payload.doc.data()['fechaAlerta'] -
            a.payload.doc.data()['fechaAlerta']
        )
        .forEach((alertaDato) => {
          const AlertaRecogida: any = alertaDato.payload.doc.data();

          if (AlertaRecogida['reportada'] == false) {
            this.alertas.push(alertaDato);
          } else {
            this.alertasHistorial.push(AlertaRecogida);
          }
        });
    });
  }

  //recoge las palabras bullying de la base de datos, para saber que palabras son consideradas bullying
  getPalabras() {
    this.palabras = new Array();
    this.palabras.length = 0;
    this._aiBullying.getWordsNow().subscribe((palabrasBBDD) => {
      palabrasBBDD.forEach((palabraU) => {
        var word = palabraU.data();
        this.palabras.push(word['palabra']);
      });
    });
  }

  // getMensajes() {
  //   this.mensaje = "Leyendo mensajes, por favor ESPERE"
  //   this._mensajes.getChatsNow().subscribe((chat) => {
  //     this.chats = chat;
  //     var total = chat.size
  //     var contadorChat = 0;
      
  //     // console.log("cont",total)
  //     chat.forEach((chatData: any) => {
 
  //       var chatecillo = chatData.data();
  //       const messagechatid = [
  //         chatecillo.members[0].uid,
  //         chatecillo.members[1].uid,
  //       ].sort();
  //       const chatId = messagechatid.join('|');

  //       this._mensajes.getMessagesNow(chatId).subscribe((messages) => {
  //         var contadorMensajes = 0;
  //         var mensajesTotal = messages.size
          
  //         // console.log("mensajes por chat", messages.size)
  //         // if(messages.size==0){console.log("sumoo", contadorChat); contadorChat++; }
         
  //         messages.forEach((men) => {
  //           var menDatos = men.data();

  //           if (menDatos.fecha > this.fechaCheck) {
  //             var arrayDePalabras = menDatos.message.split(' ');

  //             var contieneBullying = false;
  //             for (let index = 0; index < arrayDePalabras.length; index++) {
  //               for (let indexJ = 0; indexJ < this.palabras.length; indexJ++) {
  //                 if (this.palabras[indexJ] == arrayDePalabras[index]) {
  //                   contieneBullying = true;

  //                   break;
  //                 }
  //               }
  //             }

  //             if (contieneBullying) {
  //               var user = this._userService
  //                 .getUserByIdNow(menDatos.sender)
  //                 .subscribe((users) => {
  //                   this.mensaje = "Cargando nuevas alertas..."
  //                     var usu = users.docs[0].data();
  //                     var idColegio = usu['colegios'][0];
  //                     var avaAlumno = '';
  //                     if (usu['avatar']) {
  //                       avaAlumno = usu['avatar'];
  //                     }
                     
  //                     var colegioObs = this._colegiosService
  //                       .getColegioByIdNow(idColegio)
  //                       .subscribe((colegio) => {
  //                         var cole = colegio.data();
  //                         var nombreColegio = cole['nombre'];
  //                         var avaColegio = '';
  //                         if (cole['avatar']) {
  //                           avaColegio = cole['avatar'];
  //                         }

  //                         var userObs = this._userService
  //                           .getUsersNow()
  //                           .subscribe((usuarios) => {
  //                             usuarios.forEach((usuario) => {
  //                               var usua = usuario.data();

  //                               if (
  //                                 usua['colegios'][0] == idColegio &&
  //                                 usua['rol'] == 'director'
  //                               ) {
  //                                 var idDirect = usua['uid'];

  //                                 this._numeroAlertas
  //                                   .getNumber()
  //                                   .subscribe((alerta) => {
  //                                     alerta.forEach((alertaDato) => {
  //                                       var alertita = alertaDato.data();
  //                                       var number: number = alertita.numero;
  //                                       this._numeroAlertas.editNumber();
  //                                       var alerta = {
  //                                         avatarColegio: avaColegio,
  //                                         estado: 'pendiente',
  //                                         fechaAlerta: menDatos.fecha,
  //                                         idAlerta: number,
  //                                         idColegio: nombreColegio,
  //                                         idSupervisor: idSupervisor,
  //                                         idUsuario: menDatos.sender,
  //                                         mensaje: menDatos.message,
  //                                         mostrarSupervisor: true,
  //                                         avatarAlumno: avaAlumno,
  //                                         reportada: false,
  //                                       };

  //                                       var alertaObs = this._alertService
  //                                         .getAlertaByMensajeAndName(
  //                                           alerta.mensaje,
  //                                           alerta.idUsuario,
  //                                           alerta.fechaAlerta
  //                                         )
  //                                         .subscribe((alertas) => {
  //                                           this._alertService.createdAlerta(
  //                                             alerta
  //                                           );
  //                                           alertaObs.unsubscribe()
  //                                           contadorMensajes++;
  //                                           if(contadorChat==total){
  //                                             this.mensaje=""
  //                                           }
                                            
  //                                         });
  //                                     });
  //                                   });
  //                               }
  //                               else{
  //                                 contadorMensajes++;
  //                                 if(contadorMensajes==mensajesTotal){
  //                                   // console.log("suuumamos", contadorChat)
  //                                   contadorChat++;
  //                                   if(contadorChat==total){
  //                                     this.mensaje=""
  //                                   }
  //                                 }
  //                               }
  //                             });
  //                             // userObs.unsubscribe()
  //                           });
  //                       });
  //                     // colegioObs.unsubscribe()
  //                   });
  //                   // user.unsubscribe()
                 
  //             }else{
            
  //               contadorMensajes++;
  //               if(contadorMensajes==mensajesTotal){
  //                 // console.log("suuumamos", contadorChat)
  //                 contadorChat++;
  //                 if(contadorChat==total){
  //                   this.mensaje=""
  //                 }
  //               }
  //             }
  //           }else{
            
  //             contadorMensajes++;
  //             if(contadorMensajes==mensajesTotal){
  //               // console.log("suuumamos", contadorChat)
  //               contadorChat++;
  //               if(contadorChat==total){
  //                 this.mensaje=""
  //               }
  //             }
  //           }
  //           if(contadorMensajes==mensajesTotal){
  //             // console.log("suuumamos", contadorChat)
  //             contadorChat++;
  //             if(contadorChat==total){
  //               this.mensaje=""
  //             }
  //           }
  //         });
  //       });
      
       
  //     });
  //     //para que no haga el reload antes que los mensajes.
     
  //   });
  // }

  changeMenu() {
    if (this.menuHistorialActivo) {
      this.menuHistorialActivo = false;
    } else {
      this.menuHistorialActivo = true;
    }
  }

  // reloadDateCheck() {
  //   var current = this.getFecha();
  //   if (current != this.datosUsuario.fechaCheck) {
  //     this.datosUsuario.fechaCheck = current;
  //     this._userService.editUser( this.datosUsuario, this.mainUserUidDb);
  //   }
  // }

  // getFecha() {
  //   const current = new Date();
  //   current.setHours(current.getHours());
  //   current.setMinutes(current.getMinutes());
  //   current.setSeconds(0);
  //   current.setMilliseconds(0);
  //   return current;
  // }
}
