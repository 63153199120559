<div class="main">
  <div class="logo">
    <img src="assets/img/logo.png" alt="" routerLink="/" />
  </div>
  <div class="popup" *ngIf="noexiste">
    <div class="mensaje">
      <p>
        El usuario <b> {{ nombre | titlecase }} </b> no existe
      </p>
      <p style="font-size: 15px">Introduce un nombre válido</p>
    </div>
  </div>
</div>
