import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manual',
  templateUrl: './manual.component.html',
  styleUrls: ['./manual.component.css']
})
export class ManualComponent implements OnInit {

  manual: string="https://firebasestorage.googleapis.com/v0/b/the-campus-prod.appspot.com/o/Manualuser%2FManual_Alumno_TheCampus(ESP).pdf?alt=media&token=e7f950bf-f454-4c0c-9609-8cfeea14df57";
  idioma="es"
  constructor() { }

  ngOnInit(): void {

    if (localStorage.getItem('lenguaje')) {
      this.idioma = localStorage.getItem('lenguaje');
    }
    switch (this.idioma) {
      case 'es':
        this.manual="https://firebasestorage.googleapis.com/v0/b/the-campus-prod.appspot.com/o/Manualuser%2FManual_Alumno_TheCampus(ESP).pdf?alt=media&token=e7f950bf-f454-4c0c-9609-8cfeea14df57"
        break;
    
      default:
        this.manual="https://firebasestorage.googleapis.com/v0/b/the-campus-prod.appspot.com/o/Manualuser%2FStudent%C2%B4s%20Manual(ENG).pptx?alt=media&token=7b006b00-fc7e-4e37-8ef6-ee86741c9a54"
        break;
    }
  }

}