<div class="datos">
    <p>{{ datos.fechaInicio | 	date:'short' }}</p>
    <p>{{ datos.fechaFin | date:'short'}}</p>
    <p>{{ datos.duracion | number }} mins</p>
    <p title="Descargar informe participantes">{{ datos.participantes }} </p>
    <!-- <img (click)="generatePDF()" id="pdf" src="/assets/img/pdf.svg"alt="pdf"  title="Descargar Report">  -->
</div>
  
<div *ngIf="popUp==true">
    <div class="popContainer">
      <h2>Descargar Informe</h2>
      <p>¿Desea descargar el reporte de la llamada?</p>
      <div class="botones">
        <label (click)="generatePDF()"> 
          <input class="buttonE" type="checkbox"  >
            <span >Si</span>
        </label>
        <label (click)="pop()"> 
          <input class="buttonE" type="checkbox"  >
          <span>No</span>
        </label>
      </div>
    </div>
  </div>
  

  