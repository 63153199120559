import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search2Admin'
})
export class Search2AdminPipe implements PipeTransform {

  transform(value: any, args: any): unknown {
    if(!value) {
      return;
    }

    if(!args) {
      return value;
    }

    args = args.toLowerCase();
    return value.filter( (item) => {
      return JSON.stringify(item.payload.doc.data()).toLowerCase().includes(args);
    });
  }

}
