<section class="basicbox fondo evento">
  <header class="headerperfil padtop">
    <div class="icon">
      <div class="wrapicon">
        <img src="/assets/img/calendar-b.svg" />
      </div>
    </div>
    <h3 translate>dashboard.eventos.eventos</h3>
  </header>

  <div class="submenu">
    <ul>
      <li routerLink="/dashboard/profile/{{ userUid }}/eventos/" translate>
        dashboard.eventos.creareventos
      </li>
      <li routerLink="/dashboard/profile/{{ userUid }}/miseventos/" translate>
        dashboard.eventos.miseventos
      </li>
    </ul>
  </div>

  <div *ngIf="ocultarForm == false; else elsebox" class="basicbox uploadfile">
    <div class="formgroup">
      <label for="idnombres" translate>dashboard.eventos.nombreevento </label>
      <input
        id="idnombres"
        class="form-control"
        type="text"
        placeholder="{{ 'dashboard.eventos.escribir' | translate }}"
        [(ngModel)]="nombre"
      />
    </div>

    <div class="formgroup">
      <label for="idapellidos" translate>dashboard.eventos.descripcion</label>
      <textarea
        class="form-control"
        maxlength="144"
        [(ngModel)]="descripcion"
      ></textarea>
    </div>

    <div *ngIf="clases != undefined">
      <div *ngIf="clases != undefined">
        <div class="formgroup">
          <label for="idgenero" translate>dashboard.eventos.clase</label>
          <!-- <select
            id="idgenero"
            class="form-control"
            [(ngModel)]="clase"
            (change)="selectclass($event.target.value)"
          >
            <ng-container *ngFor="let clase of clases">
              <option value="{{ clase?.payload.id }}">
                {{ clase?.payload.data().nombre | titlecase }}
              </option>
            </ng-container>
          </select> -->

          <div class="basicbox cla">
            <span *ngIf="ninguna == false">
              <div class="checkbox-inline" *ngFor="let clase of clases">
                <input
                  type="checkbox"
                  name="{{ clase?.payload.id }}"
                  [value]="clase?.payload.id"
                  (change)="selectClase($event, clase?.payload.id)"
                />
                {{ clase?.payload.data().nombre | titlecase }}
              </div>
            </span>
            <div class="checkbox-inline">
              <input
                type="checkbox"
                name="{{ 'vacio' }}"
                [value]="null"
                (change)="ningunaClase($event)"
              />
              -- Ninguna clase --
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="selectuser.length > 0">
      <div class="formgroup">
        <label for="idgenero" translate> dashboard.eventos.invitados</label>
        <select
          id="idgenero"
          class="form-control"
          [(ngModel)]="invitados"
          (change)="recogerUsuarios()"
        >
          <option value="clase" translate>
            dashboard.eventos.invitarclase
          </option>
          <option value="usuarios" translate>
            dashboard.eventos.seleccionar
          </option>
        </select>
      </div>
    </div>

    <!-- <div *ngIf="invitados === 'usuarios' && selectuser.length > 0">
      <div class="formgroup">
        <label for="idgenero" translate>dashboard.eventos.estudiantes</label>
        <div class="basicbox estu">
          <div class="checkbox-inline" *ngFor="let user of selectuser">
            <input
              type="checkbox"
              name="{{ user?.payload.doc.data().uid }}"
              [value]="user?.payload.doc.data().uid"
              (change)="getUserId($event, user?.payload.doc.data().uid)"
            />
            {{ user?.payload.doc.data().nombres | titlecase }}
            {{ user?.payload.doc.data().apellidos | titlecase }}
          </div>
        </div>
      </div>
    </div> -->


    <div *ngIf="invitados === 'usuarios' && selectuser.length > 0">
      <div class="formgroup">
        <label for="idgenero" translate>dashboard.eventos.estudiantes</label>
        <div class="basicbox estu">
          <div class="checkbox-inline" *ngFor="let user of selectuser">
            <input
              type="checkbox"
              name="{{ user?.payload.doc.data().uid }}"
              [value]="user?.payload.doc.data().uid"
              (change)="getUserId($event, user?.payload.doc.data().uid)"
            />
            {{ user?.payload.doc.data().nombres | titlecase }}
            {{ user?.payload.doc.data().apellidos | titlecase }}
          </div>
        </div>
      </div>
    </div>

    <div class="formgroup">
      <label for="idgenero" translate>dashboard.eventos.esvideo</label>
      <select id="idgenero" class="form-control" [(ngModel)]="videollamada">
        <option selected value="no" translate>dashboard.eventos.no</option>
        <option value="si" translate>dashboard.eventos.si</option>
      </select>
    </div>

    <div *ngIf="videollamada === 'si'">
      <div class="formgroup">
        <label for="iddiremail" translate> dashboard.eventos.email </label>
        <input
          id="iddiremail"
          class="form-control"
          type="email"
          placeholder="{{ 'dashboard.eventos.escribir' | translate }}"
          [(ngModel)]="email"
          (change)="addEmail()"
        />
      </div>
    </div>
    <span>{{ formatoEmailMensaje }}</span>
    <div class="emailsContainer" *ngIf="videollamada === 'si'">
      <div class="listEmails" *ngFor="let item of emails">
        <p class="emailText">{{ item }}</p>
        <img
          id="close"
          src="assets/img/close.svg"
          alt="close"
          (click)="quitarEmail(item)"
        />
      </div>
    </div>

    <div class="colw2">
      <div>
        <div class="formgroup">
          <label for="idfechanac" translate>dashboard.eventos.fechaini</label>
          <input
          id="idfechanac"
          class="form-control"
          type="date"
          min="{{ fechaHoy }}"
          placeholder="{{ 'dashboard.eventos.escribir' | translate }}"
          [(ngModel)]="fechainicio"
          />
        </div>

        <div class="formgroup">
          <label for="idpais" translate>dashboard.eventos.horaini</label>
          <input
          id="idpais1"
          class="form-control"
          type="time"
          min="{{ horaHoy }}"
          [(ngModel)]="horainicio"
          />
        </div>
      </div>
      <div>
        <div class="formgroup">
          <label for="idfechanac" translate>dashboard.eventos.fechafin</label>
          <input
            id="idfechanac"
            class="form-control"
            type="date"
            min="{{ horaHoy }}"
            placeholder="{{ 'dashboard.eventos.escribir' | translate }}"
            [(ngModel)]="fechafinal"
          />
        </div>

        <div class="formgroup">
          <label for="idpais" translate>dashboard.eventos.horafin</label>
          <input
            id="idpais"
            class="form-control"
            type="time"
            [(ngModel)]="horafinal"
          />
        </div>
      </div>
    </div>
    <p>{{ notificacion }}</p>
    <input
      type="submit"
      value="{{ 'dashboard.eventos.enviar' | translate }}"
      [disabled]="clicked"
      (click)="createevent()"
    />
  </div>
  <ng-template #elsebox>
    <div class="respuesta">
      <p>{{ notificacion }}</p>
    </div>
  </ng-template>
</section>
