import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class AppsService {

  constructor(private firestore: AngularFirestore) { }

  getApps() {
    return this.firestore.collection('apps').snapshotChanges();
  }

  getAppById(app) {
    return this.firestore.collection('apps').doc(app).snapshotChanges();
  }

  createdApp(app) {
    return this.firestore.collection('apps').add(app);
  }

  editApp(app) {
    return this.firestore.collection('apps').doc(app.uid).set(app);
  }

}
