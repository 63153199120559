
<ng-container *ngIf="user && alerta==false">

  <section class="basicbox fondo">
    <header class="headerperfil padtop">
      <div class="icon">
          <div class="wrapicon">
              <img src="/assets/img/cog.svg" alt="">
          </div>
      </div>
      <h3 translate>
         dashboard.perfil.ajustes
      </h3>
    </header>
      <div class="basicbox uploadfile">
      <div class="formgroup">
      <label for="idnombres" translate>dashboard.perfil.ajustesopt.nombres</label>
      <input  id="idnombres" class="form-control" type="text" [(ngModel)]="nombres" id=
      "disabled" disabled>
      </div>

      <div class="formgroup">
      <label for="idapellidos" translate>dashboard.perfil.ajustesopt.apellidos</label>
      <input id="idapellidos" class="form-control" type="text" [(ngModel)]="apellidos" id="disabled" disabled>
      </div>

      <div class="formgroup">
        <label for="idtelefono" translate>dashboard.perfil.ajustesopt.telefono</label>
        <input id="idtelefono" class="form-control" type="text" placeholder="{{'dashboard.perfil.ajustesopt.placeholder' | translate}}"[(ngModel)]="telefono">
      </div>

      <div class="formgroup">
        <label for="idfechanac" translate>dashboard.perfil.ajustesopt.nacimiento</label>
        <input id="idfechanac" class="form-control" type="date" placeholder="{{'dashboard.perfil.ajustesopt.placeholder' | translate}}" [(ngModel)]="fechanac">
      </div>

      <div class="formgroup">
        <label for="idpais" translate>dashboard.perfil.ajustesopt.pais</label>
        <input id="idpais" class="form-control" type="text" placeholder="{{'dashboard.perfil.ajustesopt.placeholder' | translate}}" [(ngModel)]="pais">
      </div>

      <div class="formgroup">
      <label for="idgenero" translate>dashboard.perfil.ajustesopt.genero</label>
      <input type=text id="idgenero" class="form-control" [(ngModel)]="genero" id="disabled" disabled 
      >
      </div>
      <input id="buttonAjustes" type="submit" value="{{'dashboard.perfil.ajustesopt.guardar' | translate}}" (click)="saveSettings()">
    </div>
  </section>
</ng-container>

<ng-container *ngIf="alerta==true">
  <div class="respuesta">
    <p >{{saveresponse}}</p>
  </div>
</ng-container>

<ng-container *ngIf="guardado">
  <div class="cambiado">
    <p >{{'dashboard.perfil.ajustesopt.guardado' | translate}}</p>
  </div>
</ng-container>