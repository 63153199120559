import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../../services/authentication.service';
import { EventosService } from '../../../../services/eventos.service';
import { ClasesService } from '../../../../services/clases.service';
import { UserService } from '../../../../services/user.service';
import { NotificacionesService } from '../../../../services/notificaciones.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-editarevento',
  templateUrl: './editarevento.component.html',
  styleUrls: ['./editarevento.component.css'],
})
export class EditareventoComponent implements OnInit {
  userUid: any;
  nombre: string;
  descripcion: string;
  clase: any;
  estudiantes: any;
  fechainicio: any;
  horainicio: any;
  fechafinal: any;
  horafinal: any;
  userdata: any;
  clases = [];
  selectclase: any;
  selectuser = [];
  invitados: any = 'usuarios';
  usuariosparaclase: any;
  clasetoda = [];
  notificacion: any;
  ocultarForm = false;
  eventid: string;
  eventdata: any;

  evdata: any;
  estudianteDatos: any;
  videollamada: string;
  linkvideo: string;

  emails: any = new Array();
  email: any;
  formatoEmailMensaje: string;

  clasesSeleccionadas: any = [];
  fecha_inicio: any;
  fecha_final: any;
  asistentes: any = [];
  noasistentes: any = [];
  ninguna: boolean = false;
  fechaHoy: string = '';
  horaHoy: string;
  clicked = false;

  clasesCapadas=[
    "0MYFZ2lrZKZ69JjARsVB",
    "0MYFrkjV4l2THtX6CLGZ",
    "000LlElEx00000000",
    "000LlElEx00000001",
    "000LlElEx00000002",
    "000LlElEx00000003",
    "000LlElEx00000004",
    "000LlElEx00000005",
    "000LlElEx00000006",
    "000LlElEx00000007",
    "000LlElEx00000008",
    "000LlElEx00000009",
    "000LlElEx00000010",
    "000LlElEx00000011",
    "000LlElEx00000012",
    "000LlElEx00000013",
    "000LlElEx00000014",
    "000LlElEx00000015",
    "000LlElEx00000016",
    "000LlElEx00000017",
    "000LlElEx00000018"
  ]

  constructor(
    private activatedRoute: ActivatedRoute,
    private eventosService: EventosService,
    private clasesService: ClasesService,
    private userService: UserService,
    private notificacionesService: NotificacionesService,
    private functions: AngularFireFunctions,
    private authenticationService: AuthenticationService,
    private router: Router,
    private translate: TranslateService,
    private datepipe: DatePipe
    
  ) {
    /* toma el parametro uid que viene en la url */
  }

  ngOnInit(): void {
    this.estudiantes = [];

    this.activatedRoute.params.subscribe((params) => {
      this.eventid = params['uid'];
      this.getmainuserdata();
    });
  }

  /* trae informacion principal del usuario logueado */
  getmainuserdata() {
    this.getFechaActual();
    this.fechainicio = this.fechaHoy;
    this.fechafinal = this.fechaHoy;
    this.horainicio = this.horaHoy;
    this.horafinal = this.horaHoy;

    const Userdata: any = this.userService.getUserinfodata();
    if (Userdata === undefined) {
      this.authenticationService.isAuth().subscribe((auth) => {
        if (auth) {
          this.userUid = auth.uid; /* id del usuario */

          this.authenticationService
            .isUserAdmin(this.userUid)
            .subscribe((userdata) => {
              if (userdata[0]) {
                this.userdata = userdata[0];
                this.userdata.clases.forEach((clase) => {
                  if(!this.clasesCapadas.includes(clase)){
                    this.clasesService.getClaseById(clase).subscribe((tclase) => {
                      this.clases.push(tclase);
                    });
                  }
                });
                this.geteventdata(this.eventid);
                setTimeout(() => {
                  const estudiantes = this.estudiantes
                  this.recogerUsuarios();
                  this.estudiantes=estudiantes
                  this.activarCheckboxClases();
                  this.activarCheckboxUsuarios(this.estudiantes);
                }, 1000);
              }
            });
        }
      });
    } else {
      this.userUid = Userdata.payload.doc.data().uid;
      this.userdata = Userdata.payload.doc.data();
      this.userdata.clases.forEach((clase) => {
        if(!this.clasesCapadas.includes(clase)){

          this.clasesService.getClaseById(clase).subscribe((tclase) => {
            this.clases.push(tclase);
          });
        }
      });
      this.geteventdata(this.eventid);
      setTimeout(() => {
        const estudiantes = this.estudiantes
        this.recogerUsuarios();
        this.estudiantes=estudiantes
        this.activarCheckboxClases();
        this.activarCheckboxUsuarios(this.estudiantes);
      }, 1000);
    }
  }

  geteventdata(eventid) {
    this.eventdata = '';
    this.nombre = '';
    this.descripcion = '';
    this.clase = '';
    this.clasesSeleccionadas = [];
    this.estudiantes = [];
    this.fecha_inicio = '';
    this.fechainicio = '';
    this.horainicio = '';
    this.fecha_final = '';
    this.fechafinal = '';
    this.horafinal = '';
    this.asistentes = [];
    this.noasistentes = [];

    this.emails = [];

    this.evdata = this.eventosService
      .getEventoById(eventid)
      .subscribe((tevent) => {
        this.eventdata = tevent.payload.data();
        // console.log("estudiantes",this.eventdata.estudiantes)
        this.nombre = this.eventdata.nombre;
        this.descripcion = this.eventdata.descripcion;
        this.clase = this.eventdata.clase;
        this.clasesSeleccionadas = this.eventdata.clases;
        this.estudiantes = this.eventdata.estudiantes;
        this.fecha_inicio = this.eventdata.fecha_inicio;
        this.fechainicio = this.eventdata.fechainicio;
        this.horainicio = this.eventdata.horainicio;
        this.fecha_final = this.eventdata.fecha_final;
        this.fechafinal = this.eventdata.fechafinal;
        this.horafinal = this.eventdata.horafinal;
        this.asistentes = this.eventdata.asistentes;
        this.noasistentes = this.eventdata.noasistentes;
        this.emails = this.eventdata.emails;
        if (this.eventdata.videoconferencia != null && this.eventdata.videoconferencia != '') {
          this.videollamada = 'si';

          // this.emails = this.eventdata.emails;
        } else {
          this.videollamada = 'no';
   
        }


      });
  }

  // selectclass(event) {
  //   this.clasesService.getClaseById(event).subscribe((clase) => {
  //     this.selectclase = clase.payload.data();
  //     this.selectuser = [];
  //     this.clasetoda = [];
  //     this.selectclase.usuarios.forEach((seclase) => {
  //       if (seclase != this.userUid) {
  //         this.userService.getUserById(seclase).subscribe((user) => {
  //           this.selectuser.push(user[0]);
  //           this.usuariosparaclase = user[0].payload.doc.data();
  //           this.clasetoda.push(this.usuariosparaclase.uid);
  //         });
  //       }
  //     });
  //   });
  // }

  // selectinvitados(event) {
  //   this.clasesService.getClaseById(this.clase).subscribe((clase) => {
  //     this.selectclase = clase.payload.data();
  //     this.clasetoda = [];
  //     this.selectclase.usuarios.forEach((seclase) => {
  //       if (seclase != this.userUid) {
  //         this.userService.getUserById(seclase).subscribe((user) => {
  //           this.usuariosparaclase = user[0].payload.doc.data();
  //           this.clasetoda.push(this.usuariosparaclase.uid);
  //         });
  //       }
  //     });
  //   });
  // }

  getFecha() {
    const current = new Date();
    current.setHours(current.getHours());
    current.setMinutes(current.getMinutes());
    current.setMonth(current.getMonth());
    current.setDate(current.getDate());
    return current;
  }

  getFechaActual() {
    var MyDate = this.getFecha();

    this.fechaHoy =
      MyDate.getFullYear() +
      '-' +
      ('0' + (MyDate.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + MyDate.getDate()).slice(-2);

    this.horaHoy =
      ('0' + MyDate.getHours()).slice(-2) +
      ':' +
      ('0' + MyDate.getMinutes()).slice(-2);
  }

  createevent() {
    this.ocultarForm = true;
    var error = false;
    this.evdata.unsubscribe();
    // let len = this.translate.getDefaultLang();
    let len = localStorage.getItem('idioma');

    if (this.nombre === '' || this.nombre === undefined) {
      error=true;
      this.translate
        .get('dashboard.eventos.notificacion.nombre')
        .subscribe((data) => {
          this.notificacion = data;
          setTimeout(() => {
            this.ocultarForm = false;
            setTimeout(() => {
              this.activarCheckboxClases();
              this.activarCheckboxUsuarios(this.estudiantes);
            }, 500);
          }, 1500);
          return;
        });
    }

    if (this.clasesSeleccionadas.length == 0 && this.emails.length == 0) {
      error=true;
      this.translate
        .get('dashboard.eventos.notificacion.clase')
        .subscribe((data) => {
          this.notificacion = data;
          setTimeout(() => {
            this.ocultarForm = false;
            setTimeout(() => {
              this.activarCheckboxClases();
              this.activarCheckboxUsuarios(this.estudiantes);
            }, 500);
          }, 1500);
          return;
        });
    }
    if (
      (this.invitados === '' || this.invitados === undefined) &&
      this.emails.length == 0
    ) {
      error=true;
      this.translate
        .get('dashboard.eventos.notificacion.opcioninvitados')
        .subscribe((data) => {
          this.notificacion = data;
          setTimeout(() => {
            this.ocultarForm = false;
            setTimeout(() => {
              this.activarCheckboxClases();
              this.activarCheckboxUsuarios(this.estudiantes);
            }, 500);
          }, 1500);
          return;
        });
    }
    if (this.estudiantes.length <= 0 && this.emails.length == 0) {
      error=true;
      this.translate
        .get('dashboard.eventos.notificacion.invitados')
        .subscribe((data) => {
          this.notificacion = data;
          setTimeout(() => {
            this.ocultarForm = false;
            setTimeout(() => {
              this.activarCheckboxClases();
              this.activarCheckboxUsuarios(this.estudiantes);
            }, 500);
          }, 1500);
          return;
        });
    }
    if (this.fechainicio === '' || this.fechainicio === undefined) {
      error=true;
      this.translate
        .get('dashboard.eventos.notificacion.fechaini')
        .subscribe((data) => {
          this.notificacion = data;
          setTimeout(() => {
            this.ocultarForm = false;
            setTimeout(() => {
              this.activarCheckboxClases();
              this.activarCheckboxUsuarios(this.estudiantes);
            }, 500);
          }, 1500);
          return;
        });
    }

    if (this.horainicio === '' || this.horainicio === undefined) {
      error=true;
      this.translate
        .get('dashboard.eventos.notificacion.horaini')
        .subscribe((data) => {
          this.notificacion = data;
          setTimeout(() => {
            this.ocultarForm = false;
            setTimeout(() => {
              this.activarCheckboxClases();
              this.activarCheckboxUsuarios(this.estudiantes);
            }, 500);
          }, 1500);
          return;
        });
    }

    if (this.fechafinal === '' || this.fechafinal === undefined) {
      error=true;
      this.translate
        .get('dashboard.eventos.notificacion.fechafin')
        .subscribe((data) => {
          this.notificacion = data;
          setTimeout(() => {
            this.ocultarForm = false;
            setTimeout(() => {
              this.activarCheckboxClases();
              this.activarCheckboxUsuarios(this.estudiantes);
            }, 500);
          }, 1500);
          return;
        });
    }

    if (this.horafinal === '' || this.horafinal === undefined) {
      error=true;
      this.translate
        .get('dashboard.eventos.notificacion.horafin')
        .subscribe((data) => {
          this.notificacion = data;
          setTimeout(() => {
            this.ocultarForm = false;
            setTimeout(() => {
              this.activarCheckboxClases();
              this.activarCheckboxUsuarios(this.estudiantes);
            }, 500);
          }, 1500);
          return;
        });
    }

    if (this.fechafinal < this.fechainicio) {
      error=true;
      this.translate
        .get('dashboard.eventos.notificacion.fechainimenor')
        .subscribe((data) => {
          this.notificacion = data;
          setTimeout(() => {
            this.ocultarForm = false;
            setTimeout(() => {
              this.activarCheckboxClases();
              this.activarCheckboxUsuarios(this.estudiantes);
            }, 500);
          }, 1500);
          return;
        });
    }

    if (
      this.fechainicio == this.fechafinal &&
      this.horafinal < this.horainicio
    ) {
      error=true;
      this.translate
        .get('dashboard.eventos.notificacion.fechainidia')
        .subscribe((data) => {
          this.notificacion = data;
          setTimeout(() => {
            this.ocultarForm = false;
            setTimeout(() => {
              this.activarCheckboxClases();
              this.activarCheckboxUsuarios(this.estudiantes);
            }, 500);
          }, 1500);
          return;
        });
    }

    var fechaActual = this.getFecha();
    var fechaInicialComprobación = new Date(
      this.fechainicio + ' ' + this.horainicio
    );
    if (fechaActual > fechaInicialComprobación) {
      error=true;
      this.translate
        .get('dashboard.eventos.notificacion.fechapasado')
        .subscribe((data) => {
          this.notificacion = data;
          setTimeout(() => {
            this.ocultarForm = false;
            setTimeout(() => {
              this.activarCheckboxClases();
              this.activarCheckboxUsuarios(this.estudiantes);
            }, 500);
          }, 1500);
          return;
        });
    }

    if (this.descripcion === undefined) {
      this.descripcion = '';
    }

    if (this.videollamada == 'si') {
      if (this.emails.length == 0) {
        error=true;
        return;
      }
      this.linkvideo = 'https://thecampus.education/' + this.userdata.usuario ;
    } else {
      this.emails = [];
      this.linkvideo=""
    }
    if( error){
      return
    }
    if(!this.estudiantes.includes(this.userUid)){

      this.estudiantes.push(this.userUid);
    }
    var asis = new Array();
    var noasis = new Array();
    var clase = [];
    if (this.clasesSeleccionadas.length > 0) {
      clase = this.clasesSeleccionadas[0];
    }

    const evento = {
      nombre: this.nombre.toLowerCase(),
      descripcion: this.descripcion,
      clase: clase,
      clases: this.clasesSeleccionadas,
      estudiantes: this.estudiantes,
      fecha_inicio: new Date(this.fechainicio + ' ' + this.horainicio),
      fechainicio: this.fechainicio,
      horainicio: this.horainicio,
      fecha_final: new Date(this.fechafinal + ' ' + this.horafinal),
      fechafinal: this.fechafinal,
      horafinal: this.horafinal,
      creado: this.userUid,
      asistentes: asis,
      noasistentes: noasis,
      videoconferencia: this.linkvideo,
      emails: this.emails,
    };
  // var fechainicio = this.fechainicio.split('-').reverse().join('-');
    var fecha = new Date(this.fechainicio + ' ' + this.horainicio);
    var fechainicio = this.datepipe.transform(fecha, 'MM/dd/yyyy, HH:mm');
    var fechafin = new Date(this.fechafinal + ' ' + this.horafinal);

    this.eventosService.editEvento(this.eventid, evento).then((evnt) => {
      this.notificacion = '';
      this.translate
        .get('dashboard.eventos.notificacion.creado')
        .subscribe((data) => {
          this.notificacion = data;
          setTimeout(() => {
            this.router.navigate([
              '/dashboard/profile/' + this.userUid + '/miseventos/',
            ]);
          }, 2000);
        });

      this.estudiantes.forEach((estudiante) => {
        const notification = {
          user: estudiante,
          texto: '📅 ' + evento.nombre,
          url: '/dashboard/profile/' + estudiante + '/miseventos',
          verlo: estudiante,
          fecha: new Date(),
          event_id: this.eventid,
          emisor: this.userUid,
          mensaje:false
        };
        this.notificacionesService.createdNotificacion(notification);
        if (this.userUid !== estudiante) {
          var linkEmail: string;
          var textEmail: string;
          var textboton: string;
          this.sendNotification(estudiante, this.nombre);
          
        }
      });
      var linkEmail: string;
      let api = 'sendEmail';
      if (this.linkvideo != '') {
        switch (len) {
          case 'en':
            api = 'sendEmailEn';
            break;
          case 'es':
            api = 'sendEmail';
            break;
          case 'ar':
            api = 'sendEmailEn';
            break;
          default:
            api = 'sendEmailEn';
            break;
        }
      } else {
        switch (len) {
          case 'en':
            api = 'sendEmailNoVideoEn';
            break;
          case 'es':
            api = 'sendEmailNoVideo';
            break;
          case 'ar':
            api = 'sendEmailNoVideoEn';
            break;
          default:
            api = 'sendEmailNoVideoEn';
            break;
        }
      }
      var titulofichero = quitarCaracteresEspeciales(this.nombre);
      var fechaICS = new Date(this.fechainicio + ' ' + this.horainicio);
      var fechafinalICS = new Date(this.fechafinal + ' ' + this.horafinal);

      if (this.linkvideo != '') {
        linkEmail = this.linkvideo;
        // textEmail = 'Ha sido invitado a la videoconferencia: ' + this.nombre;
        // textboton = 'Ir a la conferencia';
      } else {
        linkEmail = 'https://thecampus.education/';
        // textEmail = 'Ha sido invitado al evento: ' + this.nombre;

        // textboton = 'Iniciar sesión';
      }

      var eventParse = {
        titulo: titulofichero,
        fecha: fechaICS
          .toISOString()
          .replace('-', '')
          .replace('-', '')
          .replace(':', '')
          .replace(':', '')
          .replace('.', ''),
        fechafin: fechafinalICS
          .toISOString()
          .replace('-', '')
          .replace('-', '')
          .replace(':', '')
          .replace(':', '')
          .replace('.', ''),
        descripcion: this.descripcion,
        organizador:this.userdata.email
      };

      var fechaemail = this.datepipe.transform(fecha, 'dd/MM/yyyy');
      var fechaFinEmail = this.datepipe.transform(fechafin, 'dd/MM/yyyy');

      this.emails.forEach((element) => {
        var men = {
          email: element,
          titulo: this.nombre,
          fecha: fechaemail,
          hora: this.horainicio,
          fechafin: fechaFinEmail,
          horafin: this.horafinal,
          link: linkEmail,
          event: eventParse,
          nombre: this.userdata.nombres + ' ' + this.userdata.apellidos,
        };
        
        const callable = this.functions.httpsCallable(api);
        const obs = callable(men);
        obs.subscribe(async (res) => {});
      });

      // this.geteventdata(this.eventid);

      this.translate
        .get('dashboard.eventos.notificacion.creado')
        .subscribe((data) => {
          this.notificacion = data;
          setTimeout(() => {
            this.notificacion = '';
          }, 2000);
        });
    });
  }

  getUserId(event, uid) {
    if (event.target.checked) {
      this.estudiantes.push(uid);
    } else {
      this.estudiantes = this.estudiantes.filter((m) => m != uid);
    }
  }
  addEmail() {
    if (this.validateEmail(this.email)) {
      if (!this.emails.includes(this.email)) {
        this.emails.push(this.email);
        this.email = '';
      }
    } else {
      this.translate.get('dashboard.eventos.formatoemail').subscribe((data) => {
        this.formatoEmailMensaje = data;
        setTimeout(() => {
          this.formatoEmailMensaje = '';
        }, 2000);
      });
    }
  }
  quitarEmail(email) {
    var posicion = this.emails.indexOf(email);
    this.emails.splice(posicion, 1);
  }
  validateEmail(email) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  selectClase(event, uid) {
    if (event.target.checked) {
      this.ninguna = false;
      this.clasesSeleccionadas.push(uid);
    } else {
      this.clasesSeleccionadas = this.clasesSeleccionadas.filter(
        (m) => m != uid
      );
      // this.quitarUsuarios(uid);
    }
    this.estudiantes = [];
    setTimeout(() => {
      this.recogerUsuarios();
    }, 500);
  }

  ningunaClase(event) {
    if (event.target.checked) {
      this.ninguna = true;
      this.clasesSeleccionadas = [];
      this.selectuser = [];
      this.videollamada = 'si';
    } else {
      this.ninguna = false;
    }
  }

  usuariosPreseleccionados() {}

  recogerUsuarios() {
    this.selectuser = [];
    this.clasetoda = [];
    for (const clase of this.clasesSeleccionadas) {
      this.userService.getUsersbyClass(clase).subscribe((usuarios) => {
        for (const usuario of usuarios) {
          this.selectuser = this.selectuser.filter(
            (m) => m.payload.doc.id != usuario.payload.doc.id
          );
          this.selectuser.push(usuario);
          this.clasetoda = this.clasetoda.filter(
            (m) => m != usuario.payload.doc.data()['uid']
          );
          this.clasetoda.push(usuario.payload.doc.data()['uid']);
        }
        this.selectuser = this.selectuser.filter(
          (m) => m.payload.doc.data().uid != this.userUid
        );
        this.clasetoda = this.clasetoda.filter((m) => m != this.userUid);
        if (this.invitados == 'clase') {
          this.estudiantes = this.clasetoda;
        } 
        if(this.invitados == 'usuarios'){
          this.estudiantes=[]
        }
      });
    }
  }

  activarCheckboxClases() {
    if (this.clasesSeleccionadas.length == 0) {
      var a = document.querySelector('input[name=' + this.clase + ']');
      a['checked'] = true;
    } else {
      this.clasesSeleccionadas.forEach((clase) => {
        var a = document.querySelector('input[name=' + clase + ']');
        a['checked'] = true;
      });
    }
    // var a = document.querySelector("input[name='ab6lOfAWaSY3nQwUHCQtbKDJBWx1']")
    // a['checked']=true;
    // console.log(a)
  }

  activarCheckboxUsuarios(est) {
    setTimeout(() => {
      console.log(this.estudiantes)
      est.forEach((usuario) => {
        if(usuario!=this.userUid){
          var a = document.querySelector('input[name=' + usuario + ']');
          a['checked'] = true;
        }
      });
    }, 500);

    // var a = document.querySelector("input[name='ab6lOfAWaSY3nQwUHCQtbKDJBWx1']")
    // a['checked']=true;
    // console.log(a)
  }


  sendNotification(usuario, mensaje) {
    var dataTrans = {
      uid: usuario,
      nombre: this.userdata['nombres'] + ' ' + this.userdata['apellidos'],
      texto: '📅 ' + mensaje,
    };
    var jsonbody = JSON.stringify(dataTrans);
    const url =
      'https://us-central1-the-campus-prod.cloudfunctions.net/app/sendNotification';
    fetch(url, {
      method: 'POST',
      body: jsonbody,
    }).then(() => {});
  }

}

function quitarCaracteresEspeciales(titulo: string) {
  return titulo
    .split('.')
    .join('_')
    .split('.')
    .join('_')
    .split('/')
    .join('_')
    .split(':')
    .join('_')
    .split('*')
    .join('_')
    .split('?')
    .join('_')
    .split('"')
    .join('_')
    .split('<')
    .join('_')
    .split('>')
    .join('_')
    .split('|')
    .join('_')
    .split(' ')
    .join('_');
}
