import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { ColegiosService } from 'src/app/services/colegios.service';
import { CursosService } from 'src/app/services/cursos.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-cursos',
  templateUrl: './cursos.component.html',
  styleUrls: ['./cursos.component.css']
})
export class CursosComponent implements OnInit {
  userUid: any;
  userdata: any;
  miscursos: any[];
  constructor(
    private cursoService: CursosService,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private colegiosService:ColegiosService,
    private breadService: BreadcrumbService
  ) {}

  ngOnInit(): void {
    const Userdata: any = this.userService.getUserinfodata();

    if (Userdata === undefined) {
      this.getmainuserdata();
    } else {
      this.userUid = Userdata.payload.doc.data().uid; /* id del usuario */
      this.userdata = Userdata.payload.doc.data();
      this.getCursos()
     
    }
  }

  /* trae informacion principal del usuario logueado */
  getmainuserdata() {
    this.authenticationService.isAuth().subscribe((auth) => {
      if (auth) {
        this.userUid = auth.uid; /* id del usuario */

        this.authenticationService
          .isUserAdmin(this.userUid)
          .subscribe((userdata) => {
            if (userdata[0]) {
              this.userdata = userdata[0];
              this.getCursos()
            }
          });
      }
    });
  }

  getCursos(){
    if (this.userdata && this.userdata.colegios[0]){
      this.miscursos = [];
      this.userdata.colegios.forEach((colegio: any) => {
        this.cursoService.getCursosByColegioidNow(colegio).subscribe((cursos)=>{
          console.log(cursos)
          cursos.forEach(curso=>{
            this.miscursos.push(curso)
          })

        })
      })
    }
  }

  UpdateBreadcrumb(name, url) {
    this.breadService.addtobread(name, url);
  }

}

