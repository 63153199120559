<div class="nopuedo">
  <iframe
    src="/assets/media/Guia-Soluciones conferencia.pdf"
    name="iframe1"
    id="iframe1"
    allow="camera;microphone"
    width="100%"
    height="100%"
  >
  </iframe>
</div>
