<div class="container">
    <h1 translate>dashboard.perfil.misalaS.dispo.titulo </h1>
    <div class="columns">
      <div class="camara">
        <div id="videoContainer">
          <video id="video"></video>
        </div> 
      </div>
      <div class="data">
          <div class="formgroup">
            <label for="camaraSelect" translate>dashboard.perfil.misalaS.dispo.verC</label>  
            <select 
              name="camara" 
              id="camaraSelect" 
              class="form-control" 
              #mySelect (change)="selectCamera($event.target.value)"
              >
                <option class='option' *ngFor="let item of camaras" value="{{item.deviceId}}">{{item.label}}</option>
            </select>
          </div>

          <div class="formgroup">
              <label for="microSelect" translate>dashboard.perfil.misalaS.dispo.verM</label>
            <select
              name="micro"
              id="microSelect"
              class="form-control"
              #mySelectMicro
              (change)="selectAudio(mySelectMicro.value)"
            >
              <option class="option" *ngFor="let item of micros" value="{{ item.deviceId }}">
                {{ item.label }}
              </option>
            </select>
          </div>

        <div class="formgroup">
            <label for="audioSelect" translate>dashboard.perfil.misalaS.dispo.verA</label>
           <select
             name="audio"
             id="audioSelect"
             class="form-control"
             #mySelectAudio
             (change)="selectAudio(mySelectAudio.value)"
           >
             <option class="option" *ngFor="let item of audios" value="{{ item.deviceId }}">
               {{ item.label }}
             </option>
           </select>


        </div>

      </div>
      </div>
</div>
