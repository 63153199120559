import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterarchivo'
})
export class FilterarchivoPipe implements PipeTransform {

  transform(value: any, args: any): unknown {
    if(!value) {
      return;
    }

    if(!args) {
      return value;
    }
    return value.filter( (item) => {
      return item.payload.doc.id !=args;
    });
  }

}
