import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AIService } from './../services/ai.service';
import { ChatService } from './../services/chat.service';
import { UserService } from './../../services/user.service';
import { AuthenticationService } from './../../services/authentication.service';
import { ClasesService } from './../../services/clases.service';
import { AngularFireFunctions } from '@angular/fire/functions';




@Component({
	selector: 'app-detect',
	templateUrl: './detect.component.html',
	styleUrls: ['./ai.component.css']
})
export class AIDetectComponent implements OnInit {
  mainuserid: string;
  chats = <any> [];
  mainuseremail: any;
  nombres: any;
  apellidos: any;
  messagePool = <any> {};
  messages = <any> [];
  newMessages = <any> [];

  constructor(private clasesService: ClasesService,
              private chatService: ChatService,
              private aiService: AIService,
              private userService: UserService,
              private functions: AngularFireFunctions,
              private authenticationService: AuthenticationService,
              private cRef: ChangeDetectorRef)
  {


  }

  ngOnInit(): void {
		const Userdata: any = this.userService.getUserinfodata();

    if (Userdata === undefined) {
      this.authenticationService.getStatus().subscribe((status) => {
        this.mainuserid = status.uid;
        this.mainuseremail = status.email;

        this.authenticationService.isUserAdmin(this.mainuserid).subscribe(userdata => {


          if(userdata[0]){
            const userdata2: any = userdata[0];
            this.nombres = userdata2.nombres; /* nombres del usuario logueado */
            this.apellidos = userdata2.apellidos; /*apellidos del usuario logueado */
          }
        });

        this.getChats();
      });

    } else  {

      this.mainuserid = Userdata.payload.doc.data().uid;
      this.mainuseremail = Userdata.payload.doc.data().email;
      this.nombres = Userdata.payload.doc.data().nombres; /* nombres del usuario logueado */
      this.apellidos = Userdata.payload.doc.data().apellidos; /*apellidos del usuario logueado */

      this.getChats();
    }
	}

  getChats(){
    this.chatService.getChats().subscribe(chat => {
      this.chats = chat;

      chat.forEach((chatData: any) => {
        const messagechatid = [chatData.members[0].uid , chatData.members[1].uid ].sort();
        const chatId = messagechatid.join('|');

        this.chatService.getMessages(chatId).subscribe(messages => {
          messages.forEach((message: any) => {
            // this.messagePool.push(message.payload.doc.data().message);
            var data = {'id': message.payload.doc.id, 'msg': message.payload.doc.data().message};
            this.messagePool[data.id] = data;
            this.messages.push(data);
          });
        });
      });

    });
    // Get chats classes
    this.clasesService.getClases().subscribe(clases => {
      clases.forEach((clase: any) => {
        const claseId = 'clase-' + clase.payload.doc.id;

        this.chatService.getMessages(claseId).subscribe(messages => {
          messages.forEach((message: any) => {
            var data = {'id': message.payload.doc.id, 'msg': message.payload.doc.data().message, 'result': 'a'};
            this.messagePool[data.id] = data;
            this.messages.push(data);
           
          });
        });
      });
    });
  }

  checkBullyingAll(){
    const callable = this.functions.httpsCallable('aiDetect');

    const checkMessages = [];
    this.messages.forEach((msg) => {
      checkMessages.push({'id': msg.id, 'msg': msg.msg});
    });

    const obs = callable(checkMessages);

    obs.subscribe(async res => {
      
      this.newMessages = [];
      res.forEach((msg) => {
        this.messagePool[msg.id]['result'] = msg.result;
        this.newMessages.push({'id': msg.id, 'msg': msg.str, 'result': msg.result.intent, 'score': (parseFloat(msg.result.score) * 100).toFixed(2) });
      });
      
      this.messages = [...this.newMessages];
      this.cRef.detectChanges();
      console.log(this.messages)
    });
  }
}
