<div class="removeuser">
  <div id="title">
    <img src="assets/img/removeuser.svg" />
    <h1>Seleccionar Usuarios a Borrar</h1>
  </div>
  <div class="list-user" *ngIf="popUp == false">
    <div class="basicbox estu">
      <div class="checkbox-inline" *ngFor="let item of usuarios">
        <input
          type="checkbox"
          name="{{ item.id }}"
          [value]="item.id"
          (change)="getUserId($event, item.id)"
        />
        <b>
          {{ item.data().nombres | titlecase }}
          {{ item.data().apellidos | titlecase }}</b
        >
        &nbsp; Email: {{ item.data().email | titlecase }}
      </div>
    </div>

    <button class="button" (click)="popBorrar()">Borrar</button>
  </div>
  <div class="pop-delete" *ngIf="popUp == true">
    <div class="basicbox confirm">
      <div>
        <h1>¿Seguro que desea borrar todos los usuarios seleccionados?</h1>
      </div>
      <div class="buttons">
        <button class="button" (click)="popBorrar()">Cancelar</button>
        <button class="button" (click)="borrarUsuarios()">Aceptar</button>
      </div>
    </div>
  </div>
</div>
