import { AdminComponent } from './admin.component';
import { AdminHomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AIComponent } from './ai/ai.component';
import { AITrainComponent } from './ai/train.component';
import { AIDetectComponent } from './ai/detect.component';
import { ProfileComponent } from './profile/profile.component';
import { MensajesComponent } from './mensajes/mensajes.component';
import { ArchivosComponent } from './archivos/archivos.component';
import { AlertasComponent } from './alertas/alertas.component';
import { AppsInternasComponent } from './home/apps-internas/apps-internas.component';
import { AlertaComponent } from './alertas/alerta/alerta.component';
import { HiloComponent } from './mensajes/hilo/hilo.component';
import { ResetClasesComponent } from './reset-clases/reset-clases.component';
import { EliminarusuariosComponent } from './eliminarusuarios/eliminarusuarios.component';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      { path: '', component: AdminHomeComponent },
      { path: 'archivos/:uid', component: ArchivosComponent },
      { path: 'alertas', component: AlertasComponent },
      { path: 'resetclases', component: ResetClasesComponent },
      { path: 'eliminarusuarios', component: EliminarusuariosComponent },
      { path: 'alertas/alerta/:idAlerta', component: AlertaComponent },
      { path: 'appsint/:url', component: AppsInternasComponent },
      { path: 'ai', component: AITrainComponent},
      {
        path: 'mensajes',
        component: MensajesComponent,
        children: [{ path: 'chat/:uid', component: HiloComponent }],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
