// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyDKI4e2R1AePWU5ksIGUNgiBDhSTVe11nI",
    authDomain: "the-campus-prod.firebaseapp.com",
    databaseURL: "https://the-campus-prod-default-rtdb.firebaseio.com",
    projectId: "the-campus-prod",
    storageBucket: "the-campus-prod.appspot.com",
    messagingSenderId: "352779494628",
    appId: "1:352779494628:web:98cabc2c99349458e538f1",
    measurementId: "G-D8NEYG8VCE"
  }
};
/*export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyB7Rpw6OrVTMMyPkLIwArXx4qmR_i0R2gU",
    authDomain: "the-campus-dev.firebaseapp.com",
    databaseURL: "https://the-campus-dev.firebaseio.com",
    projectId: "the-campus-dev",
    storageBucket: "the-campus-dev.appspot.com",
    messagingSenderId: "1071833448834",
    appId: "1:1071833448834:web:cf9bb602ab9b5363a4cf9b",
    measurementId: "G-3H6MF1RC7J"
  }
};*/

/*export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyDKI4e2R1AePWU5ksIGUNgiBDhSTVe11nI",
    authDomain: "the-campus-prod.firebaseapp.com",
    databaseURL: "https://the-campus-prod-default-rtdb.firebaseio.com",
    projectId: "the-campus-prod",
    storageBucket: "the-campus-prod.appspot.com",
    messagingSenderId: "352779494628",
    appId: "1:352779494628:web:98cabc2c99349458e538f1",
    measurementId: "G-D8NEYG8VCE"
  }
};*/

/*export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBGDjQDIyAjo0QV4FYgBt4sDAb1N8GVRNU",
    authDomain: "academica-175b4.firebaseapp.com",
    databaseURL: "https://academica-175b4.firebaseio.com",
    projectId: "academica-175b4",
    storageBucket: "academica-175b4.appspot.com",
    messagingSenderId: "1079411333346",
    appId: "1:1079411333346:web:19d9ba2e18f6f747eca6bb",
    measurementId: "G-TK4M3YTWVR"
  }
};*/

/*export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyChnbIcstjII9aK1ZMILNGlucAydRAn2hw",
    authDomain: "academica-dev.firebaseapp.com",
    databaseURL: "https://academica-dev.firebaseio.com",
    projectId: "academica-dev",
    storageBucket: "academica-dev.appspot.com",
    messagingSenderId: "1023956587708",
    appId: "1:1023956587708:web:ea259a1b681e40c714acb9",
    measurementId: "G-0SQHX0HX15"
  }
};*/

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
