import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { saveAs } from 'file-saver';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-posters',
  templateUrl: './posters.component.html',
  styleUrls: ['./posters.component.css'],
})
export class PostersComponent implements OnInit {
  files = [];
  pin = '';
  mostrar = false;
  mensajeError = '';
  remember = false;
  carpeta: string = '';
  autenticado: boolean = false;
  email = '';
  password = '';

  hide = true;

  constructor(
    private storage: AngularFireStorage,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService
  ) {}
  mostrarArchivos = false;
  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.carpeta = params['carpeta'];
      console.log('carpeta:', this.carpeta);
    this.authenticationService.isAuth().subscribe((auth) => {
      if (auth) {
        console.log('si lo estoy');
        this.listFiles();
        if (localStorage.getItem('pinposter')) {
          this.pin = atob(localStorage.getItem('pinposter'));
          this.remember = true;
          this.comprobarPin();
        } else {
          this.remember = false;
        }
        this.autenticado = true;
      } else {
        console.log('no lo estoy');
        this.autenticado = false;
      }
    });

      // this.listFiles()
    });
  }

  login() {
    this.authenticationService
      .loginWithEmail(this.email, this.password)
      .then((data) => {
        this.listFiles();
        setTimeout(() => {
          this.autenticado = true;
        }, 1000);
        if (localStorage.getItem('pinposter')) {
          this.pin = atob(localStorage.getItem('pinposter'));
          this.remember = true;
          this.comprobarPin();
        } else {
          this.remember = false;
        }
      })
      .catch((error) => {
        this.autenticado = false;
        alert('Datos equivocados');
      });
  }

  comprobarPin() {
    if (this.pin == 'ACA2424') {
      this.remembercredentials(this.remember);
      this.mensajeError = '';
      this.mostrar = true;
      // this.listFiles()
    } else {
      this.mensajeError = 'Contraseña erronea';
      this.mostrar = false;
    }
  }

  remembercredentials(remember) {
    if (remember) {
      let con = btoa(this.pin);
      localStorage.setItem('pinposter', con);
    }
  }

  olvidarcredenciales() {
    localStorage.removeItem('pinposter');
    this.remember = false;
  }

  rememberme() {
    if (this.remember == false) {
      this.remember = true;
    } else {
      this.remember = false;
    }
  }

  listFiles() {
    this.files = [];
    // posterColegia4
    const ref = this.storage.ref(this.carpeta);

    ref.listAll().subscribe((result) => {
      result.items.forEach((fileRef) => {
        fileRef.getDownloadURL().then((url) => {
          this.files.push({ name: fileRef.name, url: url });
          this.files.sort((a, b) =>
            a.name.toLowerCase().localeCompare(b.name.toLowerCase())
          );
        });
      });
    });

    return this.files;
  }

  descargarTodos() {
    this.files.forEach((element) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = (event) => {
        const blob = xhr.response;
        saveAs(blob, element.name);
        // Save the blob to your computer
      };

      //https://us-central1-the-campus-prod.cloudfunctions.net/
      xhr.open('GET', element.url);
      xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
      xhr.send();
      console.log('XHR', xhr);
    });
  }

  // descargarTodos() {
  //   this.files.forEach((element) => {
  //     fetch(element.url)
  //       .then(response => response.blob())
  //       .then(blob => {
  //         const url = window.URL.createObjectURL(blob);
  //         const a = document.createElement('a');
  //         a.href = url;
  //         a.download = element.name || 'download';
  //         a.click();
  //       })
  //       .catch((error) => console.error(error));
  //   });
  // }

  // descargarTodos() {
  //   this.files.forEach((element) => {
  //     const xhr = new XMLHttpRequest();
  //     xhr.responseType = 'blob';
  //     xhr.onload = (event) => {
  //       const blob = xhr.response;
  //       saveAs(blob, element.name);
  //       // Save the blob to your computer
  //     };
  //     // Usa tu función de Firebase como proxy
  //     const proxyUrl = `https://us-central1-the-campus-prod.cloudfunctions.net/proxy?url=${encodeURIComponent(element.url)}`;
  //     xhr.open('GET', proxyUrl);
  //     xhr.send();
  //     console.log('XHR', xhr);
  //   });
  // }
}
