import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'breadcrumbactual'
})
export class BreadcrumbactualPipe implements PipeTransform {

  transform(value: any, args: any): unknown {
    if(!value) {
      return;
    }

    if(!args) {
      return value;
    }
    if(args =="root"){
      args=0;
    }
    return value.filter( (item) => {
      console.log("iten", item, "args", args)
      return item.uid !=args;
    });
  }
}
