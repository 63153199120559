import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../services/user.service';
import { ClasesService } from 'src/app/services/clases.service';
import { AuthenticationService } from '../services/authentication.service';
import * as firebase from 'firebase';
import { AngularFireFunctions } from '@angular/fire/functions';

@Component({
  selector: 'app-myfregister',
  templateUrl: './myfregister.component.html',
  styleUrls: ['./myfregister.component.css'],
})
export class MyfregisterComponent implements OnInit {
  usuarioForm: any;
  searchValue: any;
  results: any = [];
  abrirdatos: boolean = false;
  usuarioelegido: any;
  estaEnClase: boolean = false;
  idClase: any = '0MYFrkjV4l2THtX6CLGZ';
  idUsuarioElegido: any = '';
  clase: any;
  mensajeExiste: string = '';
  pinMYF: string;
  pinValido: boolean = false;
  mensajepin: string = '';
  hidePass: string = 'password';
  listo: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private clasesService: ClasesService,
    private authenticationService: AuthenticationService,
    private functions: AngularFireFunctions
  ) {}

  ngOnInit(): void {
    if (localStorage.getItem('pinMYF')) {
      this.pinMYF = localStorage.getItem('pinMYF');
      this.pinValido = true;
    }

    this.usuarioForm = this.formBuilder.group({
      nombre: ['', [Validators.required, Validators.minLength(2)]],
      apellidos: ['', [Validators.required, Validators.minLength(2)]],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,10}$'
          ),
        ],
      ],
      password: ['', [Validators.required, Validators.minLength(6)]],
    });

    this.clasesService.getClaseByIdNow(this.idClase).subscribe((clase) => {
      this.clase = clase.data();
      console.log(this.clase);
    });
  }

  get errorControlUsuario() {
    return this.usuarioForm.controls;
  }

  // onSubmitUser(){
  //   console.log(this.usuarioForm.value)
  // }
  onSubmitUser() {
    this.mensajeExiste = 'Registrando...';
    this.usuarioForm.value.nombre;
    this.usuarioForm.value.apellidos;
    this.usuarioForm.value.email;
    this.usuarioForm.value.password;

    if (this.usuarioForm.value.nombre == '') {
      this.mensajeExiste = 'El nombre no puede estar vacio';
      return;
    }
    if (this.usuarioForm.value.apellidos == '') {
      this.mensajeExiste = 'El apellido no puede estar vacio';
      return;
    }
    if (this.usuarioForm.value.password == '') {
      this.mensajeExiste = 'La contraseña no puede estar vacio';
      return;
    }
    if (this.usuarioForm.value.password.length < 6) {
      this.mensajeExiste = 'La contraseña no puede tener un tamaño menor de 6';
      return;
    }

    if (
      !this.validateEmail(this.usuarioForm.value.email) ||
      this.usuarioForm.value.email == ''
    ) {
      this.mensajeExiste = 'Email erroneo';
      return;
    }

    var registerEmail = false;
    this.authenticationService
      .registerWithEmail(
        this.usuarioForm.value.email.toLowerCase(),
        this.usuarioForm.value.password
      )
      .then((data) => {
        if (registerEmail == false) {
          registerEmail = true;
          if (data) {
            const user = {
              nombres: this.usuarioForm.value.nombre.toLowerCase(),
              apellidos: this.usuarioForm.value.apellidos.toLowerCase(),
              email: this.usuarioForm.value.email.toLowerCase(),
              direccion: '',
              licencia: 'MYFFebrero',
              fechanac: '2000-03-03',
              fechana: '2000-03-03',
              colegios: ['0MTFJvw88G9UdRdrq69q'],
              cursos: [
                { nombre: 'Meeting Your Future', uid: 'Uk2VWunNq8LIk9DsgOnv' },
              ],
              localidad: '',
              clases: [this.idClase],
              uid: data.user.uid,
              pais: 'es',
              genero: 'masculino',
              telefono: '000000000',
              avatar: '',
              codigo: '',
              rol: 'estudiante',
              usuario: this.usuarioForm.value.email.toLowerCase(),
            };
            this.userService
              .createdUser(user)
              .then((data2) => {
                this.updateclase(data.user.uid);
                // this.sendpexipdata();
              })
              .catch((error) => {
                registerEmail = false;
              });
          } else {
            registerEmail = false;
            this.mensajeExiste =
              'El usuario ya existe. Si desea añadirlo a la clase, búsquelo a la derecha y añádelo ->';
          }
        } else {
          this.mensajeExiste = 'Recarge la web, por favor';
        }
      })
      .catch(() => {
        this.mensajeExiste =
          'El usuario ya existe. Si desea añadirlo a la clase, búsquelo a la derecha y añádelo ->';
      });
  }

  updateclase(newuseruid) {
    const clasedata = {
      usuarios: firebase.firestore.FieldValue.arrayUnion(newuseruid),
    };

    this.clasesService.editClase(this.idClase, clasedata).then(() => {
      this.mensajeExiste = 'USUARIO REGISTRADO';
      setTimeout(() => {
        this.mensajeExiste = '';
      }, 3000);
    });
  }

  sendpexipdata() {
    let nombre =
      this.usuarioForm.value.nombre + ' ' + this.usuarioForm.value.apellidos;
    nombre = nombre.replace(/[^a-zA-Z ]/g, '');
    let rand = Math.floor(Math.random() * (1000 - 0)) + 0;

    const bodydata = {
      name: this.usuarioForm.value.email.toLowerCase(),
      description:
        'Meeting your future - ' +
        this.usuarioForm.value.nombre
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '') +
        ' ' +
        this.usuarioForm.value.apellidos
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, ''),
      primary_owner_email_address: this.usuarioForm.value.email,
      automatic_participants: [],
      pin: '1234',
      service_type: 'conference',
      call_type: 'video',
      max_callrate_in: '2048',
      max_callrate_out: '2048',
      allow_guests: true,
      participant_limit: 10,
      enable_overlay_text: true,
      tag: 'MYF',
      ivr_theme: '/api/admin/configuration/v1/ivr_theme/59/',
      aliases: [
        {
          alias: this.usuarioForm.value.email.toLowerCase(),
          description: 'Nombre de Usuario',
        },
        {
          alias:
            this.usuarioForm.value.nombre
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '') +
            this.usuarioForm.value.apellidos
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '') +
            'MYF',
          description: 'NOMBRE APELLIDO MYF',
        },
      ],
    };

    const callable = this.functions.httpsCallable('addRoom');
    const obs = callable({ saladata: bodydata });

    obs.subscribe(async (res) => {});
  }

  search() {
    this.listo = false;
    this.abrirdatos = false;
    this.usuarioelegido = null;
    this.idUsuarioElegido = '';
    this.results=[]
    this.userService.getUsers().subscribe((searchdata) => {
      this.results=[]
      if (this.listo == false) {
        this.results = searchdata;
        this.listo = true;
      }
    });
  }

  quitarResult(){
    this.results=[]
  }

  openUser(result, id) {
    this.searchValue = '';
    this.usuarioelegido = result;
    this.idUsuarioElegido = id;
    this.abrirdatos = true;
    this.estaEnClase = false;
    if (result.clases.includes(this.idClase)) {
      this.estaEnClase = true;
    }
    console.log(this.usuarioelegido);
  }

  addUser() {
    this.usuarioelegido.clases.push(this.idClase);
    this.clase.usuarios.push(this.usuarioelegido.uid);
    let editado = false;
    if (this.idUsuarioElegido != '') {
      this.userService
        .editUser(this.usuarioelegido, this.idUsuarioElegido)
        .then(() => {
          if (!editado) {
            editado = true;
            this.clasesService.editClase(this.idClase, this.clase).then(() => {
              this.estaEnClase = true;
            });
          }
        });
    }
  }

  validateEmail(email) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  comprobarPin() {
    console.log(this.pinMYF);
    if (this.pinMYF == '2323') {
      localStorage.setItem('pinMYF', this.pinMYF);
      this.pinValido = true;
    } else {
      this.pinValido = false;
      this.mensajepin = 'Pin erroneo, inténtelo de nuevo';
    }
  }

  cambiarVisibilidad() {
    this.hidePass == 'text'
      ? (this.hidePass = 'password')
      : (this.hidePass = 'text');
  }
}
