import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-connectingfromschool',
  templateUrl: './connectingfromschool.component.html',
  styleUrls: ['./connectingfromschool.component.css']
})
export class ConnectingfromschoolComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    var anchor = document.createElement('a');
    anchor.href = '/assets/media/Guia-Soluciones conferencia.pdf';
    anchor.target = '_self';
    anchor.download = 'HELP.pdf'; //o el nombre que sea
    anchor.click();
    window.location.href = '/';
  }

}
