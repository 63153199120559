<section class="basicbox fondo">
<header class="headerperfil padtop">
  <div class="icon">
      <div class="wrapicon">
          <img src="/assets/img/calendar-b.svg">
      </div>
  </div>
  <h3 translate>
    dashboard.eventos.eventos
  </h3>
</header>

<div class="submenu">
  <ul>
    <li id="crearEvento" routerLink="/dashboard/profile/{{userUid}}/eventos/" (click)="UpdateBreadcrumb('dashboard.eventos.creareventos', '/dashboard/profile/'+ userUid +'/eventos/')"translate>
      dashboard.eventos.creareventos
    </li>
    <li class="active"translate>
      dashboard.eventos.miseventos
    </li>
  </ul>
</div>

  <div class="basicbox uploadfile">
    <app-evento-box *ngFor="let evento of eventos;" [evento]="evento" [userUid]="userUid" ></app-evento-box>
  </div>
  
</section>

