import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  thechats: any;
  maindelsus: any;
  chatActual = new Subject();
  borrarchat = new Subject();

  constructor(private firestore: AngularFirestore) {}

  getMessages(chatid, uid) {
    return this.firestore.collection('chats').doc(chatid).collection('messages', ref => ref.where('verlo', 'array-contains' , uid).orderBy('fecha', 'asc')).snapshotChanges([ 'added' ]);
  }

  getLastMessage(chatid, uid) {
    return this.firestore.collection('chats').doc(chatid).collection('messages', ref => ref.where('verlo', 'array-contains' , uid).orderBy('fecha', 'desc').limit(1)).snapshotChanges([ 'added' ]);
  }


  getChats(tuid) {
    return this.firestore.collection('chats',  ref => ref.where('members', 'array-contains' , { uid: tuid, join: true})).valueChanges([ 'added' ,  'removed' ] );
  }

  get2Chats(tuid) {
    // tslint:disable-next-line: max-line-length
    return this.firestore.collection('chats',  ref => ref.where('members', 'array-contains' , { uid: tuid, join: true}).limit(2)).valueChanges([ 'added' ,  'removed' ] );
  }

  createdMessage(message, id) {
    return this.firestore.collection('chats').doc(id).set(message);
  }

  setchatmembers(id, members) {
    this.firestore.collection('chats').doc(id).set(members);
  }

  sendmessage(id,message, members) {
    this.setchatmembers(id, members);
    return this.firestore.collection('chats').doc(id).collection('messages').add(message);
  }

  sendmessageclase(id, message) {
    return this.firestore.collection('chats').doc(id).collection('messages').add(message);
  }

  getClaseMessages(chatid) {
    return this.firestore.collection('chats').doc(chatid).collection('messages', ref => ref.orderBy('fecha', 'asc')).snapshotChanges();
  }

  countmemberschat(id) {
    return this.firestore.collection('chats').doc(id).ref.get();
  }

  updatemessage(chatid, mesid, update){
    return this.firestore.collection('chats').doc(chatid).collection('messages').doc(mesid).update(update);
  }

  deleteconversation(id, members, receiveruid) {
    this.maindelsus = this.countmemberschat(id).then(chat => {
      this.thechats = chat.data();
      const chatfil = this.thechats.members.filter(members => members.join == true);
      if(chatfil.length == 1) {
        this.firestore.collection('chats').doc(id).collection('messages').ref.get().then(messages => {
          messages.forEach((messageData: any) => {
            return this.firestore.collection('chats').doc(id).collection('messages').doc(messageData.id).delete();
          });
        });
        this.firestore.collection('chats').doc(id).delete();
      } else {
        const messagesus = this.firestore.collection('chats').doc(id).collection('messages').ref.get().then(messages => {
          messages.forEach((messageData: any) => {
            // tslint:disable-next-line: max-line-length
            this.firestore.collection('chats').doc(id).collection('messages').doc(messageData.id).update({verlo: [receiveruid]}).then(dataup => {
            });
          });
          return this.firestore.collection('chats').doc(id).set(members);
        });
      }
    }, error => {
    });
  }

  setChatActual(chatid) {
    this.chatActual.next(chatid);
  }

  getChatActual() {
    return this.chatActual;
  }

  borrarelChat(data) {
    this.borrarchat.next(data);
    
  }

  getBorrarElChat() {
    return this.borrarchat;
  }
}
