import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './admin.component';
import { AdminHomeComponent } from './home/home.component';
import { MenuadminComponent } from './menuadmin/menuadmin.component';
import { HeaderComponent } from './header/header.component';
import { AIComponent } from './ai/ai.component';
import { AITrainComponent } from './ai/train.component';
import { AIDetectComponent } from './ai/detect.component';
import { FormsModule } from '@angular/forms';
import { AlertasComponent } from './alertas/alertas.component';
import { ArchivosComponent } from './archivos/archivos.component';
import { MensajesComponent } from './mensajes/mensajes.component';
import { ProfileComponent } from './profile/profile.component';
import { AppsInternasComponent } from './home/apps-internas/apps-internas.component';
import { UserboxComponent } from './home/userbox/userbox.component';
import { AlertaComponent } from './alertas/alerta/alerta.component';
import { HiloComponent } from './mensajes/hilo/hilo.component';
import { UserboxChatComponent } from './mensajes/userbox-chat/userbox-chat.component';
import { Search2AdminPipe } from './pipesAdmin/search2-admin.pipe';
import { AlertaboxComponent } from './alertas/alertabox/alertabox.component';
import { HistorialBoxComponent } from './alertas/historialbox/historialbox.component';
import { ResetClasesComponent } from './reset-clases/reset-clases.component';
import { EliminarusuariosComponent } from './eliminarusuarios/eliminarusuarios.component';




@NgModule({
  declarations: [
    AdminComponent,
    AdminHomeComponent,
    MenuadminComponent,
    HeaderComponent,
    AIComponent,
    AITrainComponent,
    AIDetectComponent,
    AlertasComponent,
    ArchivosComponent,
    MensajesComponent,
    ProfileComponent,
    AppsInternasComponent,
    UserboxComponent,
    AlertaComponent,
    HiloComponent,
    UserboxChatComponent,
    Search2AdminPipe,
    AlertaboxComponent,
    HistorialBoxComponent,
    ResetClasesComponent,
    EliminarusuariosComponent,
  

  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    FormsModule
  ]
})
export class AdminModule { }
