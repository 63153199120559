import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manual-colegia-profesor',
  templateUrl: './manual-colegia-profesor.component.html',
  styleUrls: ['./manual-colegia-profesor.component.css']
})
export class ManualColegiaProfesorComponent implements OnInit {


  manual: string="https://firebasestorage.googleapis.com/v0/b/the-campus-prod.appspot.com/o/Manualuser%2FManual_profesor_Colegia%20(ESP).pdf?alt=media&token=85984d5b-586b-4c6a-8f09-15029b09a786";
  idioma="es"
  constructor() { }

  ngOnInit(): void {

    // if (localStorage.getItem('lenguaje')) {
    //   this.idioma = localStorage.getItem('lenguaje');
    // }
    // switch (this.idioma) {
    //   case 'es':
    //     this.manual="https://firebasestorage.googleapis.com/v0/b/the-campus-prod.appspot.com/o/Manualuser%2FManual_profesor_Colegia%20(ESP).pdf?alt=media&token=85984d5b-586b-4c6a-8f09-15029b09a786"
    //     break;
    
    //   default:
    //     this.manual="https://firebasestorage.googleapis.com/v0/b/the-campus-prod.appspot.com/o/Manualuser%2FManual_profesor_Colegia%20(ESP).pdf?alt=media&token=85984d5b-586b-4c6a-8f09-15029b09a786"
    //     break;
    // }
  }

}