import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit {
  lenguaje: string;

  constructor() { }

  ngOnInit(): void {

    if (localStorage.getItem('idioma')) {
      this.lenguaje = localStorage.getItem('idioma');
    } else {
      this.lenguaje = 'es'
    }

  }

}
