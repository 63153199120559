import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserService } from 'src/app/services/user.service';
import { HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-pin',
  templateUrl: './pin.component.html',
  styleUrls: ['./pin.component.css'],
})
export class PinComponent implements OnInit {
  dataSala: any;

  datosPin: any;
  datosPinInvitado: any;
  datosUsuario: any;
  userUid: string;
  user: any;
  mainUserUidDb: string;
  cargadoDatos: boolean = false;
  check: boolean;
  pinsIguales: boolean;
  response: string = '';

  constructor(
    private _userService: UserService,
    private authenticationService: AuthenticationService,
    private functions: AngularFireFunctions,
    private http: HttpClient,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    const Userdata: any = this._userService.getUserinfodata();
    this.cargadoDatos = false;
    if (Userdata === undefined) {
      this.authenticationService.isAuth().subscribe((auth) => {
        if (auth) {
          this.userUid = auth.uid;
          this._userService.getUserById(this.userUid).subscribe((userdata) => {
            if (userdata[0].payload.doc.data()) {
              this.user = userdata[0];
              this.mainUserUidDb = userdata[0].payload.doc.id;
              this.datosUsuario = userdata[0].payload.doc.data();

              this.getroom().then(() => {
                this.cargadoDatos = true;
              });
            }
          });
        }
      });
    } else {
      this.user = Userdata;
      this.userUid = Userdata.payload.doc.data().uid;
      this.mainUserUidDb = Userdata.payload.doc.id;
      this.datosUsuario = Userdata.payload.doc.data();
      this.getroom().then(() => {
        this.cargadoDatos = true;
      });
    }
  }

  async getroom() {
    await this.http
      .get<any>(
        'https://us-central1-the-campus-prod.cloudfunctions.net/getRoom',
        { params: new HttpParams().set('salaname', this.datosUsuario.usuario) }
      )
      .subscribe((element) => {
        this.dataSala = element;
        this.datosPin = element.pin;
        this.datosPinInvitado = element.guest_pin;
        return this.dataSala;
      });
  }

  actualizar() {
    this.response = '';

    if (this.datosPin == this.datosPinInvitado) {

      this.translate.get('dashboard.perfil.misalaS.pi.response.condicion').subscribe((data) => {
        this.response = data
        return
      })

    }
    if (this.datosPin > 9999) {


      this.translate.get('dashboard.perfil.misalaS.pi.response.maxAdmin').subscribe((data) => {
        this.response = data
        return
      })

    }
    if (this.datosPinInvitado > 9999) {
      this.translate.get('dashboard.perfil.misalaS.pi.response.maxInvi').subscribe((data) => {
        this.response = data
        return
      })
    }

    this.parsePinAdminToString();
    if (this.datosPinInvitado == '' || this.datosPinInvitado == null) {
      this.datosPinInvitado = '';
    } else {
      this.parsePinInvitadoToString();
    }

    //modificar los pin de la sala
    this.dataSala.pin = this.datosPin;

    this.dataSala.allow_guests = true;
    this.dataSala.guest_pin = this.datosPinInvitado;
    var object = { objects: [this.dataSala] };

    const callable = this.functions.httpsCallable('changeSala');
    const obs = callable({ saladata: object, name: this.datosUsuario.usuario });

    obs.subscribe(async (res) => {
      if (res == 202) {


        this.translate.get('dashboard.perfil.misalaS.pi.response.cambiado').subscribe((data) => {
          this.response = data
          setTimeout(() => {
            this.response = '';
          }, 2000);
        })


    
      } else {

        this.translate.get('dashboard.perfil.misalaS.pi.response.fallo').subscribe((data) => {
          this.response = data
          return
        })

      }
    });
  }

  parsePinAdminToString() {
    this.datosPin = parseInt(this.datosPin);
    this.datosPin = this.datosPin.toString();

    if (this.datosPin < 1000 && this.datosPin >= 100) {
      this.datosPin = '0' + this.datosPin;
      return;
    }
    if (this.datosPin < 100 && this.datosPin >= 10) {
      this.datosPin = '00' + this.datosPin;
      return;
    }
    if (this.datosPin < 10) {
      this.datosPin = '000' + this.datosPin;
      return;
    }
  }
  parsePinInvitadoToString() {
    this.datosPinInvitado = parseInt(this.datosPinInvitado);
    this.datosPinInvitado = this.datosPinInvitado.toString();

    if (this.datosPinInvitado > 1000) {
      this.datosPinInvitado = '' + this.datosPinInvitado;
      return;
    }
    if (this.datosPinInvitado < 1000 && this.datosPinInvitado >= 100) {
      this.datosPinInvitado = '0' + this.datosPinInvitado;
      return;
    }
    if (this.datosPinInvitado < 100 && this.datosPinInvitado >= 10) {
      this.datosPinInvitado = '00' + this.datosPinInvitado;
      return;
    }
    if (this.datosPinInvitado < 10) {
      this.datosPinInvitado = '000' + this.datosPinInvitado;
      return;
    }
  }
  checktrue() {
    if (this.check === false) {
      this.check = true;
      setTimeout(() => {
        this.check = false;
      }, 1500);
    } else {
      this.check = false;
    }
  }
}
