<div id="data">
    <img (click)="deleteAlert()" src="assets/img/close.svg"/>
    <h3>{{ nombreAlumno | titlecase }}</h3>
    <h3>{{ alerta.idColegio| titlecase }}</h3>
    <h3>{{alerta.fechaAlerta.toDate() | date:"short"}}</h3>
    <h3>{{ alerta.idAlerta}}</h3>

    <div *ngIf="alerta.estado == 'aceptada'">
        <img src="assets/img/ok.svg"/>
    </div>
    <div *ngIf="alerta.estado == 'rechazada'">
        <img src="assets/img/rechazada.svg"/>
    </div>
    <div *ngIf="alerta.estado == 'pendiente'">
        <img src="assets/img/pendiente.svg"/>
    </div>
    <img
        routerLink="/admin/alertas/alerta/{{ alerta.idAlerta }}"
        src="assets/img/ver.svg"
        id= "ver"
    />  
</div >
<hr >