import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, VERSION, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'my-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  name = 'Angular ' + VERSION.major;
  langs: string[] = [];
  language: string = 'es';
  lang: string;
  config: any;

  constructor(
    private translate: TranslateService,
    private httpClient: HttpClient
  ) {
    //escoger idioma segun el usuario
    if (localStorage.getItem('idioma')) {
      this.lang = localStorage.getItem('idioma');
      this.translate.setDefaultLang(this.lang);
      this.translate.use(this.lang);
    } else {
      this.translate.setDefaultLang('es');
      this.translate.use(this.language);
    }

    this.translate.addLangs(['es', 'en', 'ar']);
    this.langs = this.translate.getLangs();
  }
  setAppLanguaje() {}
  changeLang(lang: string) {
    this.language = lang;
    this.translate.use(lang);
    localStorage.setItem('idioma', lang);
  }
  ngOnInit() {
    // console.log("v4")
    // this.config = require('/assets/config.json');
    // console.log(this.config.version);

    // const headers = new HttpHeaders()
    //   .set('Cache-Control', 'no-cache')
    //   .set('Pragma', 'no-cache');

    // this.httpClient
    //   .get<{ version: string }>('/assets/config.json', { headers })
    //   .subscribe((config) => {
    //     console.log("version: " + config);
    //     if (config.version !== this.config.version) {
    //       location.reload(); 
    //     }
    //   });
  }
}
