<div class="cabecera">
  <h2 translate>Fecha de Inicio</h2>
  <h2 translate>Fecha de Fin</h2>
  <h2 translate>Duración</h2>
  <h2 translate>Nº Participantes</h2>
</div>
<div class="datosContainer">
  <div *ngIf="spin == true" class="popContainer">
    
    <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div> 
      
  </div>
  <div *ngIf="spin == false">
    <div *ngFor="let item of listaEstadistica">
      <app-estadisticas-box [datos]="item"></app-estadisticas-box>
    </div>
  </div>
</div>
