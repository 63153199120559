<div class="containerForm" >
    <iframe
    *ngIf="idioma=='es'"
      src="https://firebasestorage.googleapis.com/v0/b/the-campus-prod.appspot.com/o/Manualuser%2FManual_Alumno_TheCampus(ESP).pdf?alt=media&token=e7f950bf-f454-4c0c-9609-8cfeea14df57"
      name="iframe1"
      id="iframe1"
      width="100%"
      height="100%"
    ></iframe>
    <iframe
    *ngIf="idioma!='es'"
      src="https://firebasestorage.googleapis.com/v0/b/the-campus-prod.appspot.com/o/Manualuser%2FStudent%C2%B4s%20Manual(ENG).pptx?alt=media&token=7b006b00-fc7e-4e37-8ef6-ee86741c9a54"
      name="iframe1"
      id="iframe1"
      width="100%"
      height="100%"
    ></iframe>
  </div>
  