import { Component, OnInit } from '@angular/core';
import { download } from '../admin/home/event-download.utils';

@Component({
  selector: 'app-nopuedoconectarme',
  templateUrl: './nopuedoconectarme.component.html',
  styleUrls: ['./nopuedoconectarme.component.css'],
})
export class NopuedoconectarmeComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    // // window.location.href = '/assets/media/Guia-Soluciones conferencia.pdf';
    // var anchor = document.createElement('a');
    // anchor.href = '/assets/media/Guia-Soluciones conferencia.pdf';
    // anchor.target = '_self';
    // anchor.download = 'HELP.pdf'; //o el nombre que sea
    // anchor.click();
    // window.location.href = '/';
  }
}
