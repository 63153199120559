<div>

    <section class="basicbox clase grupo">
      <header>
        <div class="wrapicon">

                <img src="/assets/img/grupos-b.svg" alt="">

        </div>
        <h3 translate>dashboard.grupos.titulo </h3>

        <div class="searchcol">
          <img src="assets/img/search.svg">
          <input class="searchgroup" type="text" placeholder="{{'dashboard.grupos.buscar'|translate}}" [(ngModel)]="searchValue">
        </div>

        <div>
          <ng-container *ngIf="this.userdata?.rol != 'estudiante'">
            <a routerLink="/dashboard/creargrupo" class="button">
              <div class="plusicon">+</div>
             {{'dashboard.grupos.crear'|translate}}
            </a>
          </ng-container>
        </div>
      </header>

      <div>
        <p class="groupcounter">

          <ng-container *ngIf="clasecontador === 1">
            {{"dashboard.grupos.clase" | translate:{clasecontador:this.clasecontador} }}
          </ng-container>
          <ng-container *ngIf="clasecontador > 1">
            {{"dashboard.grupos.clases" | translate:{clasecontador:this.clasecontador} }}
          </ng-container>

        </p>
      </div>

       <div class="claseheader">

         <ng-container *ngFor="let clase of misclases | search:searchValue ;">


            <div class="subbasicbox chat headinguser">
              <!-- <div class="contenedor" *ngIf="borrar==false"> -->
                <div class="boximage" *ngIf="uidBorrar!=clase.uid">
  
  
                  <img *ngIf="clase.imagen else defaultclassavatar" src="{{clase.imagen}}" alt="{{clase.nombre | titlecase }}">
                  <ng-template #defaultclassavatar><img src="assets/img/clase_default.jpg" alt="{{clase.nombre | titlecase }}"></ng-template>
  
                </div>
                <div class="boxtitles" *ngIf="uidBorrar!=clase.uid" >
                      <h4>{{clase.nombre | titlecase }}</h4>
                      <p class="admins colegio">{{clase.colegio_nombre | titlecase }}</p>
  
  
                </div>
                <div class="rigthbuttons" *ngIf="uidBorrar!=clase.uid">
  
                    <ng-container *ngIf="clase.administradores.includes(this.userdata?.uid) ">
                      <button (click)="borrarPop(clase.uid)" >
                        {{'dashboard.grupos.borrarC'|translate}}
                      </button>
                    </ng-container>
  
                    <ng-container *ngIf="this.userdata.rol != 'profesor'">
                    
                    </ng-container>

                    <a routerLink="/dashboard/clase/{{clase.uid}}">
                      {{'dashboard.grupos.ver'|translate}}
                    </a>

                </div>
  
                <div class="boxdata" *ngIf="uidBorrar!=clase.uid">
  
                    <div class="adminsgrid">
                        <div>
                          <p>{{clase.curso_nombre | titlecase }}</p>
                        </div>
  
                    </div>
  
                </div>

              <!-- </div> -->

              <div  class="popBorrar" *ngIf="uidBorrar==clase.uid ">
                <img
                id="close"
                src="assets/img/close.svg"
                alt="close"
                (click)="borrarPop(clase.uid)"
                />
                <p translate> dashboard.grupos.seguro</p>
                <button class="button" [disabled]="clicked" (click)="DeleteClass(clase.uid);  clicked = true;" >
                  {{'dashboard.grupos.borrar'|translate}}
                </button>
              </div>
            </div>
        </ng-container>

       </div>

        <div>

        </div>


    </section>


  </div>
