<!-- <p>{{messages?.payload.doc.data().message}}</p> -->
<div class="box-alerta">
  <div id="title">
    <img src="assets/img/alertIcon.svg"/>
    <h1>Alerta</h1>
  </div>
  <div id="dataTitle">
    <h2>Nombre</h2>
    <h2>Colegio</h2>
    <h2>Fecha</h2>
    <h2>Id</h2>
  </div>
  <div class="box-message">
    <div id="data">
      <h3>{{ nombreAlumno | titlecase }}</h3>
      <h3>{{ alerta.idColegio | titlecase }}</h3>
      <h3>{{ alerta.fechaAlerta | date:"short"}}</h3>
      <h3>{{ alerta.idAlerta}}</h3>
    </div >
    <div id="Message">
        <p><b>Mensaje:</b> {{alerta.mensaje}}</p>
    </div>
    <div *ngIf="alerta.reportada == false; else elseBlock">
      <div class="botones-alerta">
        <a (click)="reportAlert()"  style="background-color: rgb(104, 109, 131, 0.8)" class="button">Reportar</a>
        <a (click)="deleteAlert()" style="background-color: #FA3B32" class="button">  <img id="trash" src="assets/img/delete.svg" ></a>
      </div>
      
  </div>
  </div>
  <ng-template #elseBlock>
    <div id="stateMessage">
      <div *ngIf="alerta.estado == 'aceptada'">
        <h2><b>{{alerta.idColegio | titlecase}}</b> ha cambiado el estado del reporte a <b>{{ alerta.estado | titlecase }}</b><img src="assets/img/ok.svg" /> </h2>
      </div>
      <div *ngIf="alerta.estado == 'rechazada'">
        <h2> <b>{{alerta.idColegio | titlecase}}</b> ha cambiado el estado del reporte a <b>{{ alerta.estado | titlecase }}</b><img src="assets/img/rechazada.svg" /></h2>
      </div>
      <div *ngIf="alerta.estado == 'pendiente'">
        <h2><b>{{alerta.idColegio | titlecase }}</b> ha cambiado el estado del reporte a <b>{{ alerta.estado | titlecase }}</b><img src="assets/img/pendiente.svg" /></h2>
      </div>
      <div class="botones-alerta2">
        <a (click)="cancelAlert()"  style="background-color: rgb(104, 109, 131, 0.8)" class="button">Cancelar</a>
        <a (click)="deleteAlert()" style="background-color: #FA3B32" class="button">  <img id="trash" src="assets/img/delete.svg" ></a>
      </div>
    </div>
  </ng-template>
</div>
