import { LoginComponent } from './login/login.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthenticationGuard } from './services/authentication.guard';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { Routes, RouterModule, DefaultUrlSerializer, UrlTree, UrlSerializer } from '@angular/router';

import { UserService } from './services/user.service';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { RegistroComponent } from './registro/registro.component';
import { OlvidarpassComponent } from './olvidarpass/olvidarpass.component';
import { SoporteComponent } from './soporte/soporte.component';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { PasswordresetComponent } from './auth_handlers_page/passwordreset.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { RegistroAcademicaComponent } from './registro-academica/registro-academica.component';
import { ExcelService } from './services/excel.service';
import { DatePipe } from '@angular/common';
import { RedirectComponent } from './redirect/redirect.component';
import { NecesitoayudaComponent } from './necesitoayuda/necesitoayuda.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SupportComponent } from './support/support.component';
import { NopuedoconectarmeComponent } from './nopuedoconectarme/nopuedoconectarme.component';
import { PersonalizacionComponent } from './personalizacion/personalizacion.component';
import { CustomizationComponent } from './customization/customization.component';
import { Test123Component } from './test123/test123.component';
import { ColegiaComponent } from './colegia/colegia.component';
import { AcademicaredirectComponent } from './academicaredirect/academicaredirect.component';
import { MyfregisterComponent } from './myfregister/myfregister.component';
import { SearchallPipe } from './pipes/searchall.pipe';
import { LleexregisterComponent } from './lleexregister/lleexregister.component';
import { HelpdeskComponent } from './helpdesk/helpdesk.component';
import { ConnectingfromschoolComponent } from './connectingfromschool/connectingfromschool.component';
import { BullyinglistComponent } from './bullyinglist/bullyinglist.component';
import { ManualComponent } from './manual/manual.component';
import { ManualColegiaComponent } from './manual-colegia/manual-colegia.component';
import { ManualColegiaProfesorComponent } from './manual-colegia-profesor/manual-colegia-profesor.component';
import { PostersComponent } from './posters/posters.component';
import { LiveComponent } from './live/live.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/','.json');
}

// export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
//   parse(url: string): UrlTree {
//       return super.parse(url.toLowerCase()); 
//   }
// }


/*const appRoutes: Routes = [
  {path: '', component: DashboardComponent, canActivate: [AuthenticationGuard]},
  {path: 'dashboard', component: DashboardComponent, canActivate: [AuthenticationGuard]},
  {path: 'login', component: LoginComponent},
  {path: 'conversation/:uid', component: ConversationComponent, canActivate: [AuthenticationGuard]},
  {path: 'profile', component: ProfileComponent, canActivate: [AuthenticationGuard]}
];*/

@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    AngularFireFunctionsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage : 'es',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),

  ],
  declarations: [
    AppComponent,
    LoginComponent,
    RegistroComponent,
    OlvidarpassComponent,
    SoporteComponent,
    PasswordresetComponent,
    RegistroAcademicaComponent,
    RedirectComponent,
    NecesitoayudaComponent,
    SupportComponent,
    NopuedoconectarmeComponent,
    PersonalizacionComponent,
    CustomizationComponent,
    Test123Component,
    ColegiaComponent,
    AcademicaredirectComponent,
    MyfregisterComponent,
    SearchallPipe,
    LleexregisterComponent,
    HelpdeskComponent,
    ConnectingfromschoolComponent,
    BullyinglistComponent,
    ManualComponent,
    ManualColegiaComponent,
    ManualColegiaProfesorComponent,
    PostersComponent,
    LiveComponent,
  ],
  bootstrap: [AppComponent],
  providers: [UserService, ExcelService, DatePipe]
//   providers: [UserService, ExcelService, DatePipe, {
//     provide: UrlSerializer,
//     useClass: LowerCaseUrlSerializer
// }],
  //schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule {}
