<div class="ai">
  <section class="basicbox martop">
    <div>
      <div class="formgroup" style="width:calc(60% - 20px);padding:10px;display:inline-block;">
        <label>Frase</label>
        <input type="text" class="form-control" [(ngModel)]="newstring">
      </div>

      <div class="formgroup" style="width:calc(20% - 20px);padding:10px;display:inline-block;">
        <button class="button" (click)="add()">Añadir</button>
      </div>
    </div>


    <div class="table">
      <div class="theader">
        <div class="table_header">Frase</div>
        <div class="table_header">Bullying</div>
      </div>
      <ng-container *ngFor="let string of this.strings; let i = index">
        <div class="table_row">
          <div class="table_small">
            <div class="table_cell">Frase</div>
            <div class="table_cell">{{ string.str }}</div>
          </div>
          <div class="table_small">
            <div class="table_cell">Bullying</div>
            <div class="table_cell">
              <span *ngIf="string.type == 'bullying.yes'">Sí</span>
              <span *ngIf="string.type == 'bullying.no'">No</span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="formgroup">
      <label></label>
      <button class="button" (click)="trainData()">Entrenar Inteligencia Artificial</button>
    </div>

   
  </section>
</div>
