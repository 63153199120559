<!-- menu -->
<div class="mainmenu">
  <div class="usermenu">
    <ul class="listaprimera">
      <li class="avatar">
        <div class="ajustesC">
          <img src="/assets/img/cog2.svg" alt="ajustes" />
        </div>
        <img
          routerLink="/dashboard/profile/{{ uid }}/ajustes"
          (click)="
            UpdateBreadcrumb(
              'dashboard.menu.perfil',
              '/dashboard/profile/' + uid
            )
          "
          *ngIf="avatar; else defaultheadavatar"
          src="{{ avatar }}"
        />

        <ng-template ng-template #defaultheadavatar
          ><img
            routerLink="/dashboard/profile/{{ uid }}/ajustes"
            (click)="
              UpdateBreadcrumb(
                'dashboard.menu.perfil',
                '/dashboard/profile/' + uid
              )
            "
            src="assets/img/defaultavatar.jpg"
          />
        </ng-template>
      </li>
      <li>
        <ng-container *ngIf="rolUser == 'supervisor'">
          <a
            routerLink="/dashboard/alertas/"
            (click)="
              UpdateBreadcrumb('dashboard.menu.alertas', '/dashboard/alertas/')
            "
            title="{{ 'dashboard.menu.alertas' | translate }}"
          >
            <img src="/assets/img/alertIcon.svg" alt="" />
            <ng-container *ngIf="alertasContador > 0">
              <div class="notificationnumber">
                {{ alertasContador }}
              </div>
            </ng-container>
          </a>
        </ng-container>
      </li>
      <li>
        <ng-container *ngIf="rolUser == 'director'">
          <a
            routerLink="/dashboard/eliminar/"
            (click)="
              UpdateBreadcrumb('dashboard.menu.eliminar', '/dashboard/eliminar/')"
              title="{{ 'dashboard.menu.eliminar' | translate }}"
          >
          <img src="/assets/img/removeuserdark.svg" alt="">
          </a>
        </ng-container>
      </li>

      <!-- <li>
        <ng-container *ngIf="rolUser == 'director'">
          <a
            routerLink="/dashboard/cursos/"
            (click)="
              UpdateBreadcrumb('dashboard.menu.cursos', '/dashboard/cursos/')"
               title="{{ 'dashboard.menu.cursos' | translate }}"
          >
          <img src="/assets/img/graduation.png" alt="">
          </a>
        </ng-container>
      </li> -->

      <li>
        <a
          routerLink="/dashboard/notificaciones/"
          (click)="
            UpdateBreadcrumb(
              'dashboard.menu.notificaciones',
              '/dashboard/notificaciones/'
            )
          "
          title="{{ 'dashboard.menu.notificaciones' | translate }}"
        >
          <img src="/assets/img/bell.svg" alt="" />
          <ng-container *ngIf="lasnotificaciones > 0">
            <div class="notificationnumber">
              {{ lasnotificaciones }}
            </div>
          </ng-container>
        </a>
      </li>
      <li>
        <a
          routerLink="/dashboard"
          title="{{ 'dashboard.menu.home' | translate }}"
        >
          <img src="/assets/img/home2.svg" alt="" />
        </a>
      </li>
    </ul>
  </div>

  <div class="utilities">
    <ul class="listasegunda">
      <li>
        <a
          routerLink="/dashboard/misgrupos"
          title="{{ 'dashboard.menu.clases' | translate }}"
          (click)="
            UpdateBreadcrumb('dashboard.menu.grupos', '/dashboard/misgrupos')
          "
        >
          <img src="/assets/img/grupo.png" alt="" />
        </a>
      </li>
      <li>
        <a
          routerLink="/dashboard/mensajes"
          title="{{ 'dashboard.menu.mensajes' | translate }}"
          (click)="
            UpdateBreadcrumb('dashboard.menu.mensajes', '/dashboard/mensajes')
          "
        >
          <img src="/assets/img/chatbox.png" alt="" />
          <ng-container *ngIf="mensajesContador > 0">
            <div class="notificationmensaje">
              {{ mensajesContador }}
            </div>
          </ng-container>
        </a>
      </li>
      <li>
        <a
          href="https://in2.thecampus.education/webapp3/#/?conference={{
            usuario
          }}&name={{ nombres | titlecase }} {{ apellidos | titlecase }}&pin={{
            pexipdata?.pin
          }}&role=host&callType=video&join=1"
          target="_blank"
          title="{{ 'dashboard.menu.videocall' | translate }}"
        >
          <img src="/assets/img/videocall.png" alt="" />
        </a>
      </li>
      <li>
        <a
          routerLink="/dashboard/profile/{{ uid }}/miseventos"
          title="{{ 'dashboard.menu.eventos' | translate }}"
          (click)="
            UpdateBreadcrumb(
              'dashboard.menu.eventos',
              '/dashboard/profile/' + uid + '/miseventos'
            )
          "
        >
          <img src="/assets/img/calendar.png" alt="" />
        </a>
      </li>
      <li>
        <a
          routerLink="/dashboard/archivos/user"
          title="{{ 'dashboard.menu.archivos' | translate }}"
          (click)="
            UpdateBreadcrumb(
              'dashboard.menu.archivos',
              '/dashboard/archivos/user'
            )
          "
        >
          <img src="/assets/img/folder.png" alt="" />
        </a>
      </li>
      <li>
        <a
          (click)="logout(uidb)"
          title="{{ 'dashboard.menu.logout' | translate }}"
        >
          <img src="/assets/img/logout.svg" alt="" />
        </a>
      </li>
    </ul>
  </div>

  <div class="manual">
    <ul class="listainfo">
      <li>
        <a
          routerLink="/dashboard/ayuda"
          title="{{ 'dashboard.menu.ayuda' | translate }}"
          (click)="
            UpdateBreadcrumb(
              'dashboard.menu.ayuda',
              '/dashboard/ayuda'
            )
          "
        >
        <img src="/assets/img/informacion.png" alt="" />
        </a>
      </li>
    </ul>
  </div>
  <!-- /menu -->
</div>
