import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-manual',
  templateUrl: './manual.component.html',
  styleUrls: ['./manual.component.css'],
})
export class ManualComponent implements OnInit {
  userUid: string;
  rol: any;
  datosusuario: any;
  manuales = [
    {
      nombre: 'Manual de estudiante',
      url: 'https://firebasestorage.googleapis.com/v0/b/the-campus-prod.appspot.com/o/Manualuser%2FManual_Alumno_TheCampus(ESP).pdf?alt=media&token=e7f950bf-f454-4c0c-9609-8cfeea14df57',
    },
    {
      nombre: 'Manual de profesor',
      url: 'https://firebasestorage.googleapis.com/v0/b/the-campus-prod.appspot.com/o/Manualuser%2FManual_profesor_TheCampus%20(ESP).pdf?alt=media&token=cba0fb6c-24a6-4d1a-8ab6-c521b6044176',
    },
    {
      nombre: 'Student Manual',
      url: 'https://firebasestorage.googleapis.com/v0/b/the-campus-prod.appspot.com/o/Manualuser%2FStudent%C2%B4s%20Manual(ENG).pptx?alt=media&token=7b006b00-fc7e-4e37-8ef6-ee86741c9a54',
    },
    {
      nombre: "Teacher's manual",
      url: 'https://firebasestorage.googleapis.com/v0/b/the-campus-prod.appspot.com/o/Manualuser%2FTeacher_Manual(ENG).pptx?alt=media&token=81182af7-dfce-4c08-9a6a-ecdc2a6fd69e',
    },
    {
      nombre: 'Manual de estudiante Colegia',
      url: 'https://firebasestorage.googleapis.com/v0/b/the-campus-prod.appspot.com/o/Manualuser%2FManual_Alumno_Colegia(ESP).pdf?alt=media&token=b8d3c50c-9115-4355-a572-e9a064c3c607',
    },
    {
      nombre: 'Manual de profesor Colegia',
      url: 'https://firebasestorage.googleapis.com/v0/b/the-campus-prod.appspot.com/o/Manualuser%2FManual_profesor_Colegia%20(ESP).pdf?alt=media&token=85984d5b-586b-4c6a-8f09-15029b09a786',
    },
    {
      nombre: 'Manual de supervisor Colegia',
      url: 'https://firebasestorage.googleapis.com/v0/b/the-campus-prod.appspot.com/o/Manualuser%2FManual_Supervisor_Colegia%20(ESP).pdf?alt=media&token=01d45b1b-33f4-4d89-9278-fae03e1001cc',
    },
  ];
  manual: any = {
    nombre: 'Manual de estudiante',
    url: 'https://firebasestorage.googleapis.com/v0/b/the-campus-prod.appspot.com/o/Manualuser%2FManual_Alumno_TheCampus(ESP).pdf?alt=media&token=e7f950bf-f454-4c0c-9609-8cfeea14df57',
  };

  videosPildoraEs = [
    // {
    //   titulo: 'Descubre cómo funciona The Campus',
    //   src: 'assets/video/pildoras/Descubre cómo funciona la interfaz The Campus.mp4',
    // },
    {
      titulo: 'Cambiar idioma',
      src: 'assets/video/pildoras/Cambiar idioma_edit.mp4',
    },
    {
      titulo: 'Cambiar modo de visualización',
      src: 'assets/video/pildoras/Cambiar modo de visualización_edit.mp4',
    },
    {
      titulo: 'Cómo crear un evento',
      src: 'assets/video/pildoras/Cómo crear un evento.mp4',
    },
    {
      titulo: 'Editar evento creado por mi',
      src: 'assets/video/pildoras/Editar evento creado por mi_edit.mp4',
    },
    {
      titulo: 'Enviar un mensaje',
      src: 'assets/video/pildoras/Enviar un mensaje_edit.mp4',
    },
    {
      titulo: 'Ver archivos',
      src: 'assets/video/pildoras/ver archivos_edit.mp4',
    },
  ];

  videosPildoraEn = [
    // {
    //   titulo: 'Discover The Campus',
    //   src: 'assets/video/pildoras/Discover The Campus.mp4',
    // },
    {
      titulo: 'Change Languaje',
      src: 'assets/video/pildoras/How to change the language_temp.mp4',
    },
    {
      titulo: 'How to enter the class',
      src: 'assets/video/pildoras/How to enter the class.mp4',
    },
    {
      titulo: 'how to change the layouts',
      src: 'assets/video/pildoras/how to change the layouts_temp.mp4',
    },
    {
      titulo: 'How to create an event',
      src: 'assets/video/pildoras/How to create an event_temp.mp4',
    },
    {
      titulo: 'How to edit an event',
      src: 'assets/video/pildoras/How to edit an event.mp4',
    },
    {
      titulo: 'How to send a message',
      src: 'assets/video/pildoras/How to send a message.mp4',
    },
    {
      titulo: 'How to see a file',
      src: 'assets/video/pildoras/How to see a file_temp.mp4',
    },
  ];
  videosPildoraIdioma = [];
  videosDesplegados: boolean = true;
  idioma: string;

  urlProblemas = '/assets/media/Guia-Soluciones conferencia.pdf';
  colegio: any;

  constructor(
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    const Userdata: any = this.userService.getUserinfodata();
    if (Userdata === undefined) {
      this.authenticationService.isAuth().subscribe((auth) => {
        if (auth) {
          this.userUid = auth.uid; /* id del usuario */
          this.authenticationService
            .isUserAdmin(this.userUid)
            .subscribe((userdata) => {
              if (userdata[0]) {
                this.datosusuario = userdata[0];
                this.rol = this.datosusuario.rol;
                this.colegio = this.datosusuario.colegios[0];
                if (localStorage.getItem('idioma')) {
                  this.idioma = localStorage.getItem('idioma');
                  console.log(this.idioma);
                } else {
                  this.idioma = 'es';
                }
                this.cogerManual();
                this.cogerVideos();
              }
            });
        }
      });
    } else {
      this.userUid = Userdata.payload.doc.data().uid; /* id del usuario */
      this.datosusuario = Userdata.payload.doc.data();
      this.rol = Userdata.payload.doc.data().rol;
      this.colegio = this.datosusuario.colegios[0];
      if (localStorage.getItem('idioma')) {
        this.idioma = localStorage.getItem('idioma');
        console.log(this.idioma);
      } else {
        this.idioma = 'en';
      }

      this.cogerManual();
      this.cogerVideos();
    }
  }
  cogerManual() {
    if (this.colegio == 'qKQCtDaQ5GRP8wsJVVsi') {
      switch (this.rol) {
        case 'estudiante':
          this.manual = this.manuales[4]
          break;
        case 'profesor':
          this.manual = this.manuales[5]
          break;
        case 'supervisor':
          this.manual = this.manuales[6]
          break;

        default:
          break;
      }
    } else {
      switch (this.idioma) {
        case 'es':
          this.rol == 'estudiante'
            ? (this.manual = this.manuales[0])
            : (this.manual = this.manuales[1]);
          break;
        case 'en':
          this.rol == 'estudiante'
            ? (this.manual = this.manuales[2])
            : (this.manual = this.manuales[3]);
          break;
        default:
          this.rol == 'estudiante'
            ? (this.manual = this.manuales[2])
            : (this.manual = this.manuales[3]);
          break;
      }
    }
    if (this.idioma != 'es') {
      this.urlProblemas = '/assets/media/Guia-Soluciones conferencia.pdf';
    }
  }
  cogerVideos() {
    switch (this.idioma) {
      case 'es':
        this.videosPildoraIdioma = this.videosPildoraEs;
        break;
      case 'en':
        this.videosPildoraIdioma = this.videosPildoraEn;
        break;
      default:
        this.videosPildoraIdioma = this.videosPildoraEn;
        break;
    }
  }

  desplegarVideos() {
    this.videosDesplegados = !this.videosDesplegados;
  }
}
