import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class LicenciaService {

  constructor(private firestore: AngularFirestore) { }

  getLicencia() {
    return this.firestore.collection('licencias').snapshotChanges();
  }

  getLicenciaById(uid) {
    return this.firestore.collection('licencias').doc(uid).snapshotChanges();
  }

  createdLicencia(licencia) {
    return this.firestore.collection('licencias').add(licencia);
  }

  updateLicencia(uid, licencia) {
    return this.firestore.collection('licencias').doc(uid).update(licencia);
  }

  searchLicencia(licencia) {
    return this.firestore.collection('licencias', ref => ref.where('codigo', '==' , licencia)).snapshotChanges();
  }
  searchLicenciaNow(licencia) {
    return this.firestore.collection('licencias', ref => ref.where('codigo', '==' , licencia)).get();
  }

}
