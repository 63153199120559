<!-- <ul>
  <li *ngFor="let colegio of colegios">
    {{ colegio.data().nombre | titlecase }}
  </li>
</ul> -->

<section class="basicbox fondo evento">
  <header class="headerperfil padtop">
    <div class="icon2">
      <div class="wrapicon">
        <img src="/assets/img/graduation.png" />
      </div>
    </div>
    <h3 translate>dashboard.cursos.titulo</h3>
  </header>

  <div>
    <p class="groupcounter">

      <ng-container *ngIf="miscursos.length === 1">
        {{miscursos.length}} {{'dashboard.cursos.curso'|translate}}
      </ng-container>
      <ng-container *ngIf="miscursos.length > 1">
        {{miscursos.length}} {{'dashboard.cursos.cursos'|translate}}
      </ng-container>

    </p>
  </div>


  <div class="buttoncursocrear">
    <ng-container *ngIf="this.userdata?.rol == 'director'">
      <a routerLink="/dashboard/cursos/crearcurso" class="button"  (click)="
      UpdateBreadcrumb(
        'Crear Curso',
        '/dashboard/cursos/crearcurso'
        )
        "
        >
        <div class="plusicon">+</div>
        {{'dashboard.cursos.crear'|translate}}
      </a>
    </ng-container>
  </div>
 
  <app-cursobox
    *ngFor="let curso of miscursos"
    [curso]="curso"
    [userdata]="userdata"
  ></app-cursobox>
</section>
