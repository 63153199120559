import { Component, OnInit } from '@angular/core';
import { ClasesService } from 'src/app/services/clases.service';
import { ColegiosService } from 'src/app/services/colegios.service';
import { UserService } from 'src/app/services/user.service';


@Component({
  selector: 'app-reset-clases',
  templateUrl: './reset-clases.component.html',
  styleUrls: ['./reset-clases.component.css'],
})
export class ResetClasesComponent implements OnInit {
  popEliminarClases = 'home';
  colegio = '';
  colegios = [];
  clickResetearClase = false;
  clickResetearTodasClases = false;

  constructor(
    private _userService: UserService,
    private _clasesService: ClasesService,
    private _colegiosService: ColegiosService
  ) {}

  ngOnInit(): void {
    this._colegiosService.getColegios().subscribe((coles) => {
      this.colegios = coles;
    });
  }

  cambiarEstado(value) {
    this.popEliminarClases = value;
  }

  resetearClase() {
    this.clickResetearTodasClases = true;
    if (this.clickResetearTodasClases) {
      this.clickResetearTodasClases = false;
      this.popEliminarClases = 'home';
      this._clasesService.getClasesNow().subscribe((clases) => {
        clases.forEach((clase) => {
          var clas = clase.data();
          var claseId = clase.id;
          clas['usuarios'].splice(0, clas['usuarios'].length);
          //this._clasesService.editClase(claseId,clas);
        });
      });
      this._userService.getUsersNow().subscribe((usuarios) => {
        usuarios.forEach((user) => {
          var usu = user.data();
          var usuId = user.id;
          usu['actualizado'] = false;
          usu['clases'].splice(0, usu['clases'].length);
          //this._userService.editUser(usu,usuId);
        });
      });
    }
  }

  resetearClaseColegio(idCole) {
    this.clickResetearClase = true;
    if (this.clickResetearClase) {
      this.clickResetearClase = false;
      this.popEliminarClases = 'home';
      this._clasesService.getClaseByColegioNow(idCole).subscribe((clases) => {
        clases.forEach((cla) => {
          var claseDatos = cla.data();
          var claseId = cla.id;
          var usuarios = claseDatos['usuarios'];
          usuarios.forEach((element) => {
            this._userService.getUserByIdNow(element).subscribe((usuarios) => {
              usuarios.forEach((usuario) => {
                var user = usuario.data();
                var userId = usuario.id;
                var index = user['clases'].indexOf(claseId);
                if (index >= 0) {
                  user['clases'].splice(index, 1);
                  //this._userService.editUser(user, userId);
                }
              });
            });
          });
          claseDatos['usuarios'].splice(0,claseDatos['usuarios'].length);
          //this._clasesService.editClase(claseId,claseDatos);
        });
      });
    }
  }
}
