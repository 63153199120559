
<div class="userchat">
  <!-- clase box -->
  <div class="subbasicbox chat headinguser">


      <div class="boximage">
          <a routerLink="/dashboard/profile/{{elotrousaurio}}">

              <img *ngIf="chatusers[elotrousaurio]?.avatar else defaultheadavatar" src="{{chatusers[elotrousaurio]?.avatar}}" alt="">
              <ng-template #defaultheadavatar><img src="assets/img/defaultavatar.jpg" alt=""></ng-template>

              <!--<div class="status"></div>-->
          </a>
      </div>
      <div class="boxtitles">
            <h4>{{chatusers[elotrousaurio]?.nombres}} {{chatusers[elotrousaurio]?.apellidos}}</h4>
            <!-- <h3 >{{chatusers[elotrousaurio]?.usuario}}</h3> -->
      </div>
      <div>
          <div class="boxchatvideocall">
            <a href="https://in2.thecampus.education/webapp3/#/?conference={{chatusers[elotrousaurio]?.usuario}}&name={{chatusers[mainuserid]?.nombres | titlecase}} {{chatusers[mainuserid]?.apellidos | titlecase}}&role=guest&callType=video&join=1" target="_blank">
                <img src="assets/img/videocall.svg" alt="">
              </a>
          </div>
      </div>

      <div class="boxdata">

      </div>

      <div class="boxvideocall "></div>


  </div>
  <!--/clase box -->


<section class="basicbox chatmessages">

    <div class="messages" id="themessagesbox" [scrollTop]="scrolltobottom()">

      <ng-container *ngFor="let messages of grupodechats[urluid];">

        <!-- message -->
        <div class="message">
            <div class="avatar">

                <img *ngIf="chatusers[messages?.payload.doc.data().sender]?.avatar else defaultavatar" src="{{chatusers[messages?.payload.doc.data().sender]?.avatar}}" alt="{{chatusers[messages?.payload.doc.data().sender]?.nombres}} {{chatusers[messages?.payload.doc.data().sender]?.apellidos}}">
                <ng-template #defaultavatar><img src="assets/img/defaultavatar.jpg" alt=""></ng-template>
  
                <!--<div class="status"></div>-->
            </div>
          <div class="messagedata">
               <div class="name">
                  {{chatusers[messages?.payload.doc.data().sender]?.nombres}} {{chatusers[messages?.payload.doc.data().sender]?.apellidos}}
               </div>
               <div class="date">
                {{ messages?.payload.doc.data().fecha.toDate() | date: 'dd/MM/y - HH:mm' }}h
               </div>
               <div class="themessage">
                   <p>{{messages?.payload.doc.data().message}}</p>
               </div>
          </div>
      </div>
      <!-- /message -->

      </ng-container>

    </div>
    <div class="sendbox">
        <input class="messagebox" type="text" placeholder="{{'dashboard.mensajes.escribirmensaje'|translate}}" [(ngModel)]="chatmessage" (keyup.enter)="sendmessage()">
        <input class="sendmessage" type="submit" value="{{'dashboard.mensajes.enviarmensaje'|translate}}" (click)="sendmessage()">
    </div>

</section>
</div>
