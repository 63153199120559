<article class="colegio">
  <img
    *ngIf="alertaBuena.avatarColegio; else defaultclassavatar"
    src="{{ alertaBuena.avatarColegio }}"
    alt="{{ alertaBuena.idColegio| titlecase }}"
  />
  <ng-template #defaultclassavatar
    ><img
      src="assets/img/clase_default.jpg"
      alt="{{ alertaBuena.idColegio | titlecase }}"
  /></ng-template>
  <div class="info-alerta">
    <h3>{{alertaBuena.idColegio | titlecase}}</h3>
    <h3>{{nombreAlumno | titlecase}}</h3>
    <h3 class="fecha-alerta">{{alertaBuena.fechaAlerta.toDate() | date:"short"}}</h3>
  </div>
  <div id="actions">
    <img (click) = "deleteAlert()" id="close" src="assets/img/close.svg">
    <a
      routerLink="/admin/alertas/alerta/{{ alertaBuena.idAlerta }}"
      class="button medium center"
    >
      Ver alerta
      <img src="assets/img/verAlerta.svg"/>
    </a>
  </div>
  <!-- <p >{{estado | titlecase}}</p> iria en el detalle de la alerta-->
</article>
