import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class NumeroalertasService {
  constructor(private firestore: AngularFirestore) {}




  getNumber() {
    return this.firestore.collection('contador_alertas').get();
  }
  editNumber() {
    this.firestore.collection('contador_alertas').get().subscribe(contadores=>{
      contadores.forEach(contador=>{
        var alerta = contador.data()
        alerta["numero"] = parseInt(alerta["numero"]) + 1;
        return this.firestore.collection('contador_alertas').doc("CZlanDeUBL7TGL26PfqS").update(alerta);
      })
    });
  }
  
}
