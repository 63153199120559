import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { UserService } from 'src/app/services/user.service';
import { ChatService } from 'src/app/services/chat.service';

@Component({
  selector: 'app-hilo',
  templateUrl: './hilo.component.html',
  styleUrls: ['./hilo.component.css'],
})
export class HiloComponent implements OnInit {
  mainuserid: string;
  searchValue = '';
  results: any;
  chatmessage = '';
  receiverid: string;
  chats = [];
  chatmessages = [];
  datecu: Date;
  elotrousaurio: any;
  urluid: string;
  /*nuevo */
  grupodechats = [];
  chatorimessa = [];
  userchat: any;
  chatusers = [];
  chatload = false;
  mainuseremail: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private notificacionesService: NotificacionesService,
    private userService: UserService,
    private chatService: ChatService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.urluid = params['uid'];
      this.chatService.setChatActual(this.urluid);
      const urlsplit = this.urluid.split('|');
      if (urlsplit[0] === urlsplit[1]) {
        return;
      }
      this.authenticationService.getStatus().subscribe(
        (status) => {
          this.mainuserid = status.uid;
          this.mainuseremail = status.email;
          if (urlsplit[0] != this.mainuserid) {
            this.elotrousaurio = urlsplit[0];
          } else {
            this.elotrousaurio = urlsplit[1];
          }
          if (this.elotrousaurio) {
            this.userService
              .getUserById(this.elotrousaurio)
              .subscribe((userchat) => {
                this.userchat = userchat[0].payload.doc.data();
                if (!this.chatusers[this.userchat.uid]) {
                  this.chatusers[
                    this.userchat.uid
                  ] = userchat[0].payload.doc.data();
                }
              });
          }
          this.cargarmensajes();
        },
        (error) => {
          console.log(error);
        }
      );
    });
  }

  sendmessage() {
    this.datecu = new Date();
    const message = {
      sender: this.mainuserid,
      receiver: this.elotrousaurio,
      message: this.chatmessage,
      fecha: this.datecu,
      verlo: [this.mainuserid, this.elotrousaurio],
      nuevo: true,
    };
    const members = {
      members: [
        { uid: this.mainuserid, join: true },
        { uid: this.elotrousaurio, join: true },
      ],
    };
    const ids = [this.mainuserid, this.elotrousaurio].sort();
    const chatid = ids.join('|');
    this.chatService
      .sendmessage(chatid, message, members)
      .then((datames) => {
        this.chatmessage = '';
        this.cargarmensajes();
        const notification = {
          user: this.elotrousaurio,
          texto: 'Ha recibido un nuevo mensaje',
          url: '/admin/mensajes/chat/' + chatid,
          verlo: this.elotrousaurio,
          fecha: new Date(),
          emisor: this.mainuserid
        };
        this.notificacionesService.createdNotificacion(notification);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  cargarmensajes() {
    if (!this.grupodechats[this.urluid]) {
      this.chatService
        .getMessages(this.urluid, this.mainuserid)
        .subscribe((message) => {
          const messagechatid = [
            message[0]?.payload.doc.data().sender,
            message[0]?.payload.doc.data().receiver,
          ].sort();
          const themeschaid = messagechatid.join('|');

          this.grupodechats[themeschaid] = message;

          if (
            this.mainuserid !==
            this.grupodechats[this.urluid][0]?.payload.doc.data().receiver
          ) {
            this.elotrousaurio = this.grupodechats[
              this.urluid
            ][0]?.payload.doc.data().receiver;
          } else {
            this.elotrousaurio = this.grupodechats[
              this.urluid
            ][0]?.payload.doc.data().sender;
          }

          this.userService
            .getUserById(
              this.grupodechats[this.urluid][0]?.payload.doc.data().receiver
            )
            .subscribe((userchat) => {
              this.userchat = userchat[0]?.payload.doc.data();
              if (!this.chatusers[this.userchat.uid]) {
                this.chatusers[
                  this.userchat.uid
                ] = userchat[0]?.payload.doc.data();
              }
            });

          this.userService
            .getUserById(
              this.grupodechats[this.urluid][0]?.payload.doc.data().sender
            )
            .subscribe((userchat) => {
              this.userchat = userchat[0]?.payload.doc.data();
              if (!this.chatusers[this.userchat.uid]) {
                this.chatusers[
                  this.userchat.uid
                ] = userchat[0]?.payload.doc.data();
              }
            });

          this.chatload = true;
        });
    } else {
      if (
        this.mainuserid !==
        this.grupodechats[this.urluid][0]?.payload.doc.data().receiver
      ) {
        this.elotrousaurio = this.grupodechats[
          this.urluid
        ][0]?.payload.doc.data().receiver;
      } else {
        this.elotrousaurio = this.grupodechats[
          this.urluid
        ][0]?.payload.doc.data().sender;
      }
    }
  }

  scrolltobottom() {
    const objDiv = document.getElementById('themessagesbox');
    objDiv.scrollTop = objDiv.scrollHeight + 1000;
  }
}
