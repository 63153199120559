

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DispositivosComponent } from './dispositivos/dispositivos.component';
import { LayoutComponent } from './layout/layout.component';
import { MisalaComponent } from './misala.component';
import { PinComponent } from './pin/pin.component';
import { EstadisticasComponent } from './estadisticas/estadisticas.component';




const routes: Routes = [
  {path: '', component: MisalaComponent,
    children: [
      {path: '', component: PinComponent},
      {path: 'pin', component: PinComponent},
      {path: 'layout', component: LayoutComponent},
      {path: 'dispositivos', component: DispositivosComponent},
      // {path: 'estadisticas', component: EstadisticasComponent},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MisalaRoutingModule { }
