import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manual-colegia',
  templateUrl: './manual-colegia.component.html',
  styleUrls: ['./manual-colegia.component.css']
})
export class ManualColegiaComponent implements OnInit {

  manual: string="https://firebasestorage.googleapis.com/v0/b/the-campus-prod.appspot.com/o/Manualuser%2FManual_Alumno_Colegia(ESP).pdf?alt=media&token=b8d3c50c-9115-4355-a572-e9a064c3c607";
  idioma="es"
  constructor() { }

  ngOnInit(): void {

    // if (localStorage.getItem('lenguaje')) {
    //   this.idioma = localStorage.getItem('lenguaje');
    // }
    // switch (this.idioma) {
    //   case 'es':
    //     this.manual="https://firebasestorage.googleapis.com/v0/b/the-campus-prod.appspot.com/o/Manualuser%2FManual_Alumno_Colegia(ESP).pdf?alt=media&token=b8d3c50c-9115-4355-a572-e9a064c3c607"
    //     break;
    
    //   default:
    //     this.manual="https://firebasestorage.googleapis.com/v0/b/the-campus-prod.appspot.com/o/Manualuser%2FManual_Alumno_Colegia(ESP).pdf?alt=media&token=b8d3c50c-9115-4355-a572-e9a064c3c607"
    //     break;
    // }
  }

}