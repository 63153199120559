<h1 translate> dashboard.perfil.misalaS.lay.titulo</h1>
<h3 style="color:#9597a6;"><b translate>dashboard.perfil.misalaS.lay.utilizado</b> {{nombreLayout}}</h3>
<div class="ContainerGeneral">
    <div class="ContainerArchivos">
        <div class="listContainer">
          <div class="list" *ngFor="let item of listLayout">
            <img src="{{item.imagen}}" alt="">
              <div class="input">
                  <p>{{ item.name | translate}}</p>
                  <input
                  [ngClass]="{ checked: checktrue }"
                  (click)="check()"
                  class="checkbox"
                  type="radio"
                  name="layout"
                  [value]="item.valor"
                  (change)="changeLayout($event, item.valor)"
                />
              </div>
            <label for="{{ item}}"><span></span></label>
          </div>
        </div>
    </div>
    <button class="button medium center" (click)="actualizar()">{{'dashboard.perfil.misalaS.lay.cambiar'|translate}}</button>
</div>