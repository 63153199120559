
<div class="mainmenu">
  <div class="usermenu">
      <ul>
        <li class="avatar" >
          <div class="ajustesC">
            <img src="/assets/img/cog2.svg" alt="ajustes" />
          </div>
          <img routerLink="/admin/profile/{{uid}}/ajustes" (click)="UpdateBreadcrumb('Perfil', '/admin/profile/'+uid+'/ajustes')"
          *ngIf="avatar; else defaultheadavatar" src="{{ avatar }}"/>
  
          <ng-template ng-template #defaultheadavatar
            ><img routerLink="/admin/profile/{{uid}}/ajustes" (click)="UpdateBreadcrumb('Perfil', '/admin/profile/'+uid+'/ajustes')" src="assets/img/defaultavatar.jpg"
            />
          </ng-template>
        </li>
        <!-- <li class="avatar">
          <div class="ajustesC">
            <img src="/assets/img/cog2.svg" alt="ajustes" />
          </div>
              <img *ngIf="avatar else defaultheadavatar" src="{{avatar}}">
              <ng-template ng-template #defaultheadavatar><img src="assets/img/defaultavatar.jpg"></ng-template>
        </li>
        <li>
            <a routerLink="/admin/profile/{{uid}}/ajustes" (click)="UpdateBreadcrumb('Perfil', '/admin/profile/'+uid+'/ajustes')">
                <img src="/assets/img/cog.svg" alt="">
            </a>
        </li> -->
  
                
        <li>
          <a routerLink="/admin/alertas/" (click)="UpdateBreadcrumb('Alertas', '/admin/alertas/')">
            <img src="/assets/img/alertIcon.svg" alt="">
            <div *ngIf="lasAlertas>0 && lasAlertas <= 99" >
              <div class="alertasNumber">
                {{ lasAlertas }}
              </div>
            </div>
            <div *ngIf="lasAlertas > 99" >
              <div class="alertasNumber2">
                {{ lasAlertas }}
              </div>
            </div>
          </a>
        </li>
          
        <li>
          <a routerLink="/admin">
            <img src="/assets/img/home2.svg" alt="">
          </a>
        </li>

      </ul> 
    </div>
        
        <div class="utilities">
          <ul>
           
            <li>
              <a routerLink="/admin/mensajes" (click)="UpdateBreadcrumb('Mensajes', '/admin/mensajes')">
                <img src="/assets/img/chatbox.png" alt="">
              </a>
            </li>
         
            <li>
              <a routerLink="/admin/resetclases" (click)="UpdateBreadcrumb('Reset', '/admin/resetclases')">
                <img src="/assets/img/power-cycle_1.svg" alt="">
              </a>
              <li>
                <a routerLink="/admin/eliminarusuarios" (click)="UpdateBreadcrumb('Eliminar usuarios', '/admin/eliminarusuarios')">
                   <img src="/assets/img/removeuser.svg" alt="">
                </a>
            </li>
              <li>
                <a (click)="logout(uidb)">
                  <img src="/assets/img/logout.svg" alt="">
                </a>
              </li>
        
       </ul>
   </div>
</div>

