import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  breadcrumb = [];

  constructor() { }

  addtobread(name, url) {
    if(!this.breadcrumb.some(el => el.nombre === name)) {
      this.breadcrumb.push({nombre: name, link: url});
    }
  }

  removetobread(name) {
    let i = 0;
    this.breadcrumb.forEach((bread: any) => {
      if (bread.nombre === name) {
        this.breadcrumb.splice(i, 1);
        console.log(this.breadcrumb);
      };
      i++;
    });
  }

  getbread() {
    return this.breadcrumb;
  }

}
