import { AuthenticationService } from './authentication.service';
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  url: any;
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    var tieneAdmin = false;
    this.url = window.location.href.split('/');
    if (this.url.includes('admin')) {
      tieneAdmin = true;
    } else {
      tieneAdmin = false;
    }

    return this.authenticationService.getStatus().pipe(
      map((status) => {
        if (status) {
          this.authenticationService
            .isUserAdminBueno(status.uid)
            .subscribe((admin) => {
              if (admin.length == 0) {
                if (tieneAdmin) {
                  this.router.navigate(['/dashboard']);
                  return true;
                }
                return false;
              } else {
           
                if (!tieneAdmin) {
                  this.router.navigate(['/admin']);
                  return true;
                }
                return true;
              }
            });
          return true;
        } else {
          this.router.navigate(['login']);
          return false;
        }
      })
    );
  }
}
