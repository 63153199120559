import { Component, OnInit, Input } from '@angular/core';
import { UserService } from './../../services/user.service';

@Component({
  selector: 'app-presencia',
  templateUrl: './presencia.component.html',
  styleUrls: ['./presencia.component.css']
})
export class PresenciaComponent implements OnInit {
  @Input() mainuid;
  @Input() members;

  @Input() actividad;
  presencia: any;

  constructor(private userService: UserService) { }

  ngOnInit(): void {
    this.getuserpresence(this.mainuid, this.members, this.actividad);
  }

  getuserpresence(mainuid, members, actividad) {
    if (mainuid && members && !actividad) {
      const uid = members[1].uid === mainuid ? members[0].uid : members[1].uid;

      this.userService.getPresence(uid).subscribe(presencia => {
        if(presencia != null) {
          this.presencia = presencia;
          return this.presencia = this.presencia.status;
        }
      });
    } else if (mainuid && members && actividad) {
      const uid = members[1].uid === mainuid ? members[0].uid : members[1].uid;
      this.userService.getPresence(uid).subscribe(presencia => {
        if(presencia != null) {
          this.presencia = presencia;
          if(Date.now() > this.presencia.timestamp) {
            return this.presencia = this.timeDifference(Date.now(), this.presencia.timestamp );
          }
        }
      });
    } else if(mainuid && actividad) {
      this.userService.getPresence(mainuid).subscribe(presencia => {
        if(presencia != null) {
          this.presencia = presencia;
          return this.presencia = this.timeDifference(Date.now(), this.presencia.timestamp );
        }
      });
    } else {
      this.userService.getPresence(mainuid).subscribe(presencia => {
        if(presencia != null) {
          this.presencia = presencia;
          return this.presencia = this.presencia.status;
        }
      });
    }

  }

  timeDifference(date1,date2) {

    let difference = date1 - date2;

    let daysDifference = Math.floor(difference/1000/60/60/24);
    difference -= daysDifference*1000*60*60*24

    let hoursDifference = Math.floor(difference/1000/60/60);
    difference -= hoursDifference*1000*60*60

    let minutesDifference = Math.floor(difference/1000/60);
    difference -= minutesDifference*1000*60

    let secondsDifference = Math.floor(difference/1000);

    let content ="";
    if(daysDifference > 0){
      content+= daysDifference + ' día ';
    }

    if(hoursDifference > 0){
      content+= hoursDifference + ' hr';
    }

    if(minutesDifference > 0){
      content+= minutesDifference + ' min';
    }

    if(secondsDifference > 0){
      content+= secondsDifference + ' seg';
    }

    if(typeof content === 'string') {
      return content;
    }

}

}
