 <!-- clase box -->
 <div class="subbasicbox chat">

  <div class="boximage">
      <a routerLink="/dashboard/profile/{{alumnodata.uid}}">
          <img *ngIf="alumnodata.avatar else defaultheadavatar" src="{{alumnodata.avatar}}" alt="{{alumnodata.nombres | titlecase}}">
          <ng-template #defaultheadavatar><img src="assets/img/defaultavatar.jpg" alt=""></ng-template>

          <div class="status" [ngClass]="{
            'online': presencia === 'online',
            'away':  presencia === 'away',
            '':  presencia === 'offline'
          }">
          </div>

      </a>
  </div>
  <div class="boxtitles">
         <h4><a routerLink="/dashboard/profile/{{alumnodata.uid}}">{{alumnodata.nombres | titlecase}} {{alumnodata.apellidos | titlecase}}</a></h4>
         <ng-container *ngIf="tiempoDesconexion">
          <p translate [translateParams]="{tiempoDesconexion:tiempoDesconexion}"> dashboard.clase.editarC.activo</p>
        </ng-container>

  </div>
  <div>
    <ng-container *ngIf="alumnodata.uid != currentuid">

       <div class="boxchatvideocall">
        <a href="https://in2.thecampus.education/webapp3/#/?conference={{alumnodata.usuario}}&name={{tnombres | titlecase}} {{tapellidos | titlecase}}&role=guest&callType=video&join=1" target="_blank">
          <img src="/assets/img/videocall.svg" alt="">
         </a>
       </div>

    </ng-container>
  </div>

  <div class="boxdata">

  </div>

   <div class="boxclose">
        <ng-container *ngIf="alumnodata.uid != currentuid">
          <div class="close">
             <a routerLink="/dashboard/mensajes/chat/{{ [currentuid, alumnodata.uid].sort().join('|') }}">
              <img src="/assets/img/chatbox-b.svg" alt="">
             </a>
          </div>
        </ng-container>
   </div>
</div>
<!--/clase box -->
