import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore'

@Injectable({
  providedIn: 'root'
})
export class ArchivosService {

  constructor(private firestore: AngularFirestore) { }


  getArchivos(uid) {
    return this.firestore.collection('archivos',  ref => ref.where('propietario', '==' , uid)).snapshotChanges();
  }

  getGroupArchivos(uid) {
    return this.firestore.collection('archivos',  ref => ref.where('grupo', 'array-contains' , uid)).snapshotChanges();
  }
  getGroupCompartidosArchivos(uid) {
    return this.firestore.collection('archivos',  ref => ref.where('clasescompartidas', 'array-contains' , uid)).get();
  }

  getArchivobyId(uid) {
    return this.firestore.collection('archivos').doc(uid).snapshotChanges();
  }

  createdArchivo(archivo) {
    return this.firestore.collection('archivos').add(archivo);
  }

  editArchivo(id, archivo) {
    return this.firestore.collection('archivos').doc(id).set(archivo);
  }

  getArchivoparents(uid) {
    return this.firestore.collection('archivos',  ref => ref.where('parent', '==' , uid)).snapshotChanges();
  }

  deletefile(id) {
    return this.firestore.collection('archivos').doc(id).delete();
  }

}
