import { Component, OnInit, Input  } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Input() nombres: string;
  @Input() apellidos: string;


  background="#FA3B32"
  avatar: any = "assets/img/logo.png";
  constructor() { }

  ngOnInit(): void {

   
  }

}
